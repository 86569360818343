import { Team, WerewolfGameState } from "@dailygameslink/werewolf";

import { getCurrentCardLayout } from "./getCurrentCardLayout";
import { getRoleTeam } from "./getRoleTeam";

export function getPlayerTeam(state: WerewolfGameState, player_id: string): Team | null {
    const cardLayout = getCurrentCardLayout(state);
    if (!cardLayout) {
        return null;
    }

    const playerRole = cardLayout[player_id];

    return getRoleTeam(state, playerRole);
}
