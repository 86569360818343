import { WerewolfGameState } from "@dailygameslink/werewolf";
import React from "react";

import { PlayerName } from "../../../components/PlayerName";
import { getCongratulationsTally } from "../utils/getCongratulationsTally";

export function Congratulations({ state }: { state: WerewolfGameState }): JSX.Element | null {
    const {
        bestLieWinners,
        mostHelpfulWinners,
        maxMostHelpfulVotes,
        maxBestLieVotes,
    } = getCongratulationsTally(state);

    return (
        <>
            {mostHelpfulWinners.length > 0 && (
                <p>
                    <span role="img" aria-label="Helpful">
                        🤩
                    </span>{" "}
                    &nbsp;Most helpful:{" "}
                    {mostHelpfulWinners
                        .map<React.ReactNode>((id) => <PlayerName key={id} player_id={id} />)
                        .reduce((prev, curr) => [prev, ", ", curr])}{" "}
                    ({maxMostHelpfulVotes})
                </p>
            )}
            {bestLieWinners.length > 0 && (
                <p>
                    <span role="img" aria-label="Liar">
                        👺
                    </span>{" "}
                    &nbsp;Best lie:{" "}
                    {bestLieWinners
                        .map<React.ReactNode>((id) => <PlayerName key={id} player_id={id} />)
                        .reduce((prev, curr) => [prev, ", ", curr])}{" "}
                    ({maxBestLieVotes})
                </p>
            )}
        </>
    );
}
