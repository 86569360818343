import { Game, GameState } from "@dailygameslink/shared-types";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export function useGame<TGameState extends GameState>(game_id?: string): Game<TGameState> {
    const thisRoomRef = useFirestore()
        .collection("games")
        .where("game_id", "==", game_id || null)
        .limit(1);

    return (useFirestoreCollectionData(thisRoomRef) as Game<TGameState>[])[0];
}
