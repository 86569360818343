import * as React from "react";

import { Button } from "./Button";
import { Role } from "@dailygameslink/werewolf";
import { RoleName } from "../games/werewolf/components/RoleName";
import styled from "styled-components";
import { useState } from "react";

const ButtonWrapper = styled.div`
    padding-bottom: var(--grid);
`;

const ConfirmButtonWrapper = styled.div`
    padding-top: var(--grid);
`;

export function SelectRole({
    roles,
    rolesToSelect = 1,
    canChangeSelection = false,
    disabledRoles,
    onConfirm,
    allowNobody = false,
}: {
    roles: Role[];
    rolesToSelect?: number;
    disabledRoles: Role[];
    canChangeSelection?: boolean;
    onConfirm(selected: string[]): Promise<void>;
    allowNobody?: boolean;
}): JSX.Element | null {
    const [selected, setSelected] = useState<string[]>([]);
    const [confirmState, setConfirmState] = useState<"unconfirmed" | "confirming" | "confirmed">(
        "unconfirmed"
    );

    const isConfirmEnabled =
        confirmState === "unconfirmed" &&
        (selected.length === rolesToSelect || (allowNobody && selected.length === 0));

    return (
        <>
            {roles.sort().map((role, i) => {
                const isSelected = selected.indexOf(role) > -1;
                const isDisabled =
                    disabledRoles.indexOf(role) > -1 ||
                    (selected.length > 1 && selected.length === rolesToSelect && !isSelected);

                return (
                    <ButtonWrapper key={i}>
                        <Button
                            wide
                            selected={isSelected}
                            disabled={isDisabled}
                            onClick={() => {
                                if (rolesToSelect > 1) {
                                    if (isSelected) {
                                        setSelected(
                                            selected.filter(
                                                (selectedPlayerId) => selectedPlayerId !== role
                                            )
                                        );
                                    } else {
                                        setSelected([...selected, role]);
                                    }
                                } else {
                                    setSelected([role]);
                                }
                            }}
                        >
                            <RoleName isLightBackground={!isSelected} role={role} />
                        </Button>
                    </ButtonWrapper>
                );
            })}
            <ButtonWrapper>
                {allowNobody && (
                    <Button
                        wide
                        selected={selected.length === 0}
                        disabled={selected.length === 0}
                        onClick={() => {
                            setSelected([]);
                        }}
                    >
                        <span role="img" aria-label="Nobody">
                            🚫
                        </span>{" "}
                        None
                    </Button>
                )}
            </ButtonWrapper>
            <ConfirmButtonWrapper>
                <Button
                    wide
                    disabled={!isConfirmEnabled}
                    onClick={async () => {
                        setConfirmState("confirming");
                        try {
                            await onConfirm(selected);
                        } catch (e) {
                            alert("There was a problem confirming, please try again");
                            console.error(e);
                            setConfirmState("unconfirmed");
                        } finally {
                            setConfirmState("confirmed");
                            if (canChangeSelection) {
                                setTimeout(() => {
                                    setConfirmState("unconfirmed");
                                }, 1000);
                            }
                        }
                    }}
                >
                    {
                        {
                            unconfirmed: "Confirm",
                            confirming: "Confirming",
                            confirmed: "Confirmed",
                        }[confirmState]
                    }
                </Button>
            </ConfirmButtonWrapper>
        </>
    );
}
