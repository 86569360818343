import React, { ReactNode } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { AuthCheck } from "reactfire";

export function LoginRedirect({ children }: { children: ReactNode }): JSX.Element | null {
    const location = useLocation();

    return (
        <AuthCheck fallback={<Redirect to={`/create-player?continue=${location.pathname}`} />}>
            {children}
        </AuthCheck>
    );
}
