import { NightAction, RoleImpl } from "@dailygameslink/werewolf";

import { MysticWolfAction } from "../actions/MysticWolfAction";
import { getGameHasLoneWolfAction } from "../utils/getGameHasLoneWolfAction";

export const MysticWolf: RoleImpl = {
    role: "mystic_wolf",
    order: 220,
    name: "Mystic wolf",
    actionComponent: MysticWolfAction,
    startingTeam: "wolf",
    getCurrentTeam: () => "wolf",
    isWolf: () => true,
    isStartingWolf: true,
    isMinion: () => false,
    hasTarget: true,
    // Players who see the other wolves must wait for the doppelganger, in case the doppelganger becomes a wolf
    actionDependencies: ["doppelganger"],
    allNightActions: ["mystic_wolf_target", "lone_wolf_target"],
    areNightActionsComplete: function (state) {
        const nightActions: NightAction[] = getGameHasLoneWolfAction(state)
            ? ["mystic_wolf_target", "lone_wolf_target"]
            : ["mystic_wolf_target"];

        return !!(
            nightActions &&
            nightActions.every((action) => state.game.night_actions[action] !== undefined)
        );
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
