import { SandboxGameState } from "@dailygameslink/sandbox";

export const coup: SandboxGameState = {
    setup: {},
    game: {
        actions: [
            {
                params: {
                    position: { type: "pile", index: [4, 5] },
                    cardContentFront: "Contessa",
                    frontTexture: "contessa",
                    isFaceDown: true,
                    id: "contessa-1",
                },

                type: "set-card",
            },
            {
                params: {
                    id: "contessa-2",
                    cardContentFront: "Contessa",
                    frontTexture: "contessa",
                    isFaceDown: true,
                    position: { type: "pile", index: [4, 5] },
                },

                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [4, 5] },
                    isFaceDown: true,
                    id: "contessa-3",
                    cardContentFront: "Contessa",
                    frontTexture: "contessa",
                },
            },
            {
                type: "set-card",

                params: {
                    cardContentFront: "Ambassador",
                    frontTexture: "ambassador",
                    isFaceDown: true,
                    id: "ambassador-1",
                    position: { type: "pile", index: [4, 5] },
                },
            },
            {
                params: {
                    cardContentFront: "Ambassador",
                    frontTexture: "ambassador",
                    isFaceDown: true,
                    position: { type: "pile", index: [4, 5] },
                    id: "ambassador-2",
                },
                type: "set-card",
            },
            {
                params: {
                    isFaceDown: true,
                    cardContentFront: "Ambassador",
                    frontTexture: "ambassador",
                    position: { type: "pile", index: [4, 5] },
                    id: "ambassador-3",
                },
                type: "set-card",
            },
            {
                params: {
                    isFaceDown: true,
                    id: "captain-1",
                    position: { type: "pile", index: [4, 5] },
                    cardContentFront: "Captain",
                    frontTexture: "captain",
                },
                type: "set-card",
            },
            {
                params: {
                    id: "captain-2",
                    isFaceDown: true,
                    cardContentFront: "Captain",
                    frontTexture: "captain",
                    position: { type: "pile", index: [4, 5] },
                },

                type: "set-card",
            },
            {
                params: {
                    position: { type: "pile", index: [4, 5] },
                    id: "captain-3",
                    cardContentFront: "Captain",
                    frontTexture: "captain",
                    isFaceDown: true,
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    id: "assassin-1",
                    cardContentFront: "Assassin",
                    frontTexture: "assassin",
                    position: { type: "pile", index: [4, 5] },
                    isFaceDown: true,
                },
            },
            {
                type: "set-card",

                params: {
                    isFaceDown: true,
                    position: { type: "pile", index: [4, 5] },
                    cardContentFront: "Assassin",
                    frontTexture: "assassin",
                    id: "assassin-2",
                },
            },
            {
                params: {
                    cardContentFront: "Assassin",
                    frontTexture: "assassin",
                    id: "assassin-3",
                    position: { type: "pile", index: [4, 5] },
                    isFaceDown: true,
                },

                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [4, 5] },
                    isFaceDown: true,
                    cardContentFront: "Duke",
                    frontTexture: "duke",
                    id: "duke-1",
                },
            },
            {
                params: {
                    cardContentFront: "Duke",
                    frontTexture: "duke",
                    isFaceDown: true,
                    position: { type: "pile", index: [4, 5] },
                    id: "duke-2",
                },

                type: "set-card",
            },
            {
                type: "set-card",

                params: {
                    position: { type: "pile", index: [4, 5] },
                    isFaceDown: true,
                    cardContentFront: "Duke",
                    frontTexture: "duke",
                    id: "duke-3",
                },
            },

            // Coins
            {
                type: "set-card",
                params: {
                    id: "coin-1",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "💵 Coin",
                    id: "coin-2",
                    position: { type: "pile", index: [5, 5] },
                },
            },
            {
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-3",
                },
                type: "set-card",
            },
            {
                params: {
                    id: "coin-4",
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                },

                type: "set-card",
            },
            {
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-5",
                },

                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-6",
                },
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-7",
                },
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-8",
                },
            },
            {
                params: {
                    id: "coin-9",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },

                type: "set-card",
            },
            {
                type: "set-card",

                params: {
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-10",
                    cardContentFront: "💵 Coin",
                },
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-11",
                },
            },
            {
                type: "set-card",
                params: {
                    id: "coin-12",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-13",
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    id: "coin-14",
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                },
            },
            {
                params: {
                    cardContentFront: "💵 Coin",
                    id: "coin-15",
                    position: { type: "pile", index: [5, 5] },
                },
                type: "set-card",
            },
            {
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-16",
                },
                type: "set-card",
            },
            {
                type: "set-card",

                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-17",
                },
            },
            {
                params: {
                    id: "coin-18",
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-19",
                    cardContentFront: "💵 Coin",
                },
            },
            {
                type: "set-card",
                params: {
                    id: "coin-20",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-21",
                },
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-22",
                },
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-23",
                },
            },
            {
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-24",
                },
                type: "set-card",
            },
            {
                type: "set-card",

                params: {
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-25",
                    cardContentFront: "💵 Coin",
                },
            },
            {
                params: {
                    id: "coin-26",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
                type: "set-card",
            },
            {
                type: "set-card",

                params: {
                    id: "coin-27",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                type: "set-card",

                params: {
                    id: "coin-28",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                params: {
                    id: "coin-29",
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                },
                type: "set-card",
            },
            {
                params: {
                    id: "coin-30",
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    id: "coin-31",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-32",
                },

                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "💵 Coin",
                    id: "coin-33",
                    position: { type: "pile", index: [5, 5] },
                },
            },
            {
                params: {
                    id: "coin-34",
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                },
                type: "set-card",
            },
            {
                type: "set-card",

                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-35",
                },
            },
            {
                type: "set-card",

                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-36",
                },
            },
            {
                type: "set-card",
                params: {
                    id: "coin-37",
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                },
            },
            {
                params: {
                    position: { type: "pile", index: [5, 5] },
                    cardContentFront: "💵 Coin",
                    id: "coin-38",
                },
                type: "set-card",
            },
            {
                params: {
                    cardContentFront: "💵 Coin",
                    id: "coin-39",
                    position: { type: "pile", index: [5, 5] },
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "💵 Coin",
                    position: { type: "pile", index: [5, 5] },
                    id: "coin-40",
                },
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "Turn",
                    frontColor: "white",
                    position: { type: "pile", index: [5, 0] },
                    id: "turn-marker",
                },
            },
            // Pile restrictions
            {
                type: "edit-pile",
                params: {
                    position: [3, 5],
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [2, 5],
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [1, 5],
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [0, 5],
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [0, 4],
                    attributes: {
                        owner: "4",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [1, 4],
                    attributes: {
                        owner: "4",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [2, 4],
                    attributes: {
                        owner: "4",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [3, 4],
                    attributes: {
                        owner: "4",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [4, 4],
                    attributes: {
                        owner: "4",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [5, 4],
                    attributes: {
                        owner: "4",
                    },
                    editable: "true",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [0, 3],
                    attributes: {
                        owner: "3",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [1, 3],
                    attributes: {
                        owner: "3",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [2, 3],
                    attributes: {
                        owner: "3",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [3, 3],
                    attributes: {
                        owner: "3",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [4, 3],
                    attributes: {
                        owner: "3",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [5, 3],
                    attributes: {
                        owner: "3",
                    },
                    editable: "true",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [0, 2],
                    attributes: {
                        owner: "2",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [1, 2],
                    attributes: {
                        owner: "2",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [2, 2],
                    attributes: {
                        owner: "2",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [3, 2],
                    attributes: {
                        owner: "2",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [4, 2],
                    attributes: {
                        owner: "2",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [5, 2],
                    attributes: {
                        owner: "2",
                    },
                    editable: "true",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [0, 1],
                    attributes: {
                        owner: "1",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [1, 1],
                    attributes: {
                        owner: "1",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [2, 1],
                    attributes: {
                        owner: "1",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [3, 1],
                    attributes: {
                        owner: "1",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [4, 1],
                    attributes: {
                        owner: "1",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [5, 1],
                    attributes: {
                        owner: "1",
                    },
                    editable: "true",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [0, 0],
                    attributes: {
                        owner: "0",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [1, 0],
                    attributes: {
                        owner: "0",
                    },
                    editable: "pile.attributes.owner == player.number",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [2, 0],
                    attributes: {
                        owner: "0",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [3, 0],
                    attributes: {
                        owner: "0",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [4, 0],
                    attributes: {
                        owner: "0",
                    },
                    editable: "false",
                },
            },
            {
                type: "edit-pile",
                params: {
                    position: [5, 0],
                    attributes: {
                        owner: "0",
                    },
                    editable: "true",
                },
            },
        ],
    },
};
