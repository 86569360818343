import { RobberTarget, RoleImpl } from "@dailygameslink/werewolf";

import { RobberAction } from "../actions/RobberAction";
import { makeCardSwap } from "./utils";

export const Robber: RoleImpl = {
    role: "robber",
    order: 600,
    name: "Robber",
    actionComponent: RobberAction,
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    hasTarget: true,
    isWolf: () => false,
    isMinion: () => false,
    doAction: (player_id, target, _nightAction, cardLayout) => {
        const newCardLayout = { ...cardLayout };
        const swap = makeCardSwap(newCardLayout);

        const robberTarget = target as RobberTarget;
        if (robberTarget) {
            swap(player_id, robberTarget);
        }

        return newCardLayout;
    },
    allNightActions: ["robber_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["robber_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
