import { WerewolfGameState, NightAction, SeerTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function SeerSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: SeerTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, actionOverride || "seer_target");
    const target =
        targetOverride === undefined ? state.game.night_actions.seer_target : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no target
            </p>
        );
    }

    if (Array.isArray(target)) {
        return (
            <>
                <p>You inspected two cards from the middle.</p>
                <p>
                    The {target[0]} was a {<RoleName role={cardLayout[target[0]]} />}
                </p>
                <p>
                    The {target[1]} was a {<RoleName role={cardLayout[target[1]]} />}
                </p>
            </>
        );
    } else {
        return (
            <>
                <p>You inspected a player.</p>
                <p>
                    <PlayerName player_id={target} /> was a <RoleName role={cardLayout[target]} />
                </p>
            </>
        );
    }
}
