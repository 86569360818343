import { Game, Room } from "@dailygameslink/shared-types";
import { WerewolfGameState } from "@dailygameslink/werewolf";

export type SetupValidity = {
    valid: boolean;
    invalidReason?: string;
};

export function isSetupValid(room: Room, game: Game<WerewolfGameState>): SetupValidity {
    if (game.game_type === "werewolf") {
        const roleCount = Object.values(game.game_state.setup.roles).reduce((a, b) => a + b);
        const playerCount = room.player_ids.length;
        const targetRoles = playerCount + 3;
        return roleCount === targetRoles
            ? {
                  valid: true,
              }
            : {
                  valid: false,
                  invalidReason:
                      roleCount > targetRoles
                          ? `Remove ${roleCount - targetRoles} roles`
                          : `Add ${targetRoles - roleCount} roles`,
              };
    } else if (game.game_type === "sandbox") {
        return {
            valid: true,
        }; // TODO
    }

    return {
        valid: false,
        invalidReason: `Unrecognized game_type ${game.game_type}`,
    };
}
