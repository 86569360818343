import { WerewolfGameState, SetupConfig, Role } from "@dailygameslink/werewolf";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

import { Button } from "../../components/Button";
import { CounterList } from "../../components/CounterList";

import { RoleName } from "./components/RoleName";
import { getRole } from "./roles";

const SetupOuter = styled.div`
    display: flex;
    justify-content: center;
`;

const SetupInner = styled.div`
    width: var(--center-width);
`;

const Title = styled.h2`
    text-align: center;
`;

const RoleSetupInfo = styled.div`
    margin: calc(var(--grid) * 2) 0;
`;

const DefaultSetupWrapper = styled.div`
    margin: calc(var(--grid) * 2) 0;
`;

const defaultSetups: {
    [playerCount: number]: {
        [role in Role]?: number;
    };
} = {
    5: {
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        apprentice_seer: 1,
        minion: 1,
        mystic_wolf: 1,
        drunk: 1,
        bodyguard: 1,
    },

    6: {
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        werewolf: 1,
        drunk: 1,
        bodyguard: 1,
    },

    7: {
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        werewolf: 1,
        drunk: 1,
        bodyguard: 1,
        insomniac: 1,
    },

    8: {
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        werewolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        insomniac: 1,
    },

    9: {
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        werewolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        mason: 2,
    },

    10: {
        alpha_wolf: 1,
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        mason: 2,
        insomniac: 1,
    },

    11: {
        alpha_wolf: 1,
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        mason: 2,
        insomniac: 1,
        hunter: 1,
    },

    12: {
        alpha_wolf: 1,
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        mason: 2,
        insomniac: 1,
        hunter: 1,
        werewolf: 1,
    },

    13: {
        alpha_wolf: 1,
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        mason: 2,
        insomniac: 1,
        hunter: 1,
        werewolf: 1,
        apprentice_seer: 1,
    },

    14: {
        alpha_wolf: 1,
        robber: 1,
        troublemaker: 1,
        doppelganger: 1,
        seer: 1,
        minion: 1,
        mystic_wolf: 1,
        drunk: 1,
        bodyguard: 1,
        paranormal_investigator: 1,
        mason: 2,
        insomniac: 1,
        hunter: 1,
        werewolf: 1,
        apprentice_seer: 1,
        tanner: 1,
    },
};

export function Setup({
    state,
    isHost,
    onSetupChange,
    player_ids,
}: {
    state: WerewolfGameState;
    isHost: boolean;
    onSetupChange(setup: SetupConfig): void;
    player_ids: string[];
}): JSX.Element | null {
    const [setup, setSetup] = useState<SetupConfig>(state.setup);
    const roles = isHost ? setup.roles : state.setup.roles; // A bit weird, but this is a controlled component as the host, and an uncontrolled one as a not host
    const items = Object.entries(roles).map(([role, count]) => ({
        value: count,
        name: role,
        disabled: false,
        max: role === "werewolf" || role === "villager" ? 3 : role === "mason" ? Infinity : 1,
    }));
    const numberOfRoles = Object.values(roles).reduce((a, b) => a + b);

    const maxNumberOfWolfTeamPlayers = Object.entries(roles).reduce<number>(
        (number, [role, count]) =>
            getRole(role as Role).startingTeam === "wolf" || getRole(role as Role).changesTeams
                ? number + count + (getRole(role as Role).createsBottomCard && count > 0 ? 1 : 0)
                : number,
        0
    );

    return (
        <>
            <SetupOuter>
                <SetupInner>
                    <Title>Werewolf setup</Title>
                    <CounterList
                        showCounters={isHost}
                        items={items.sort((a, b) => (a.name > b.name ? 1 : -1))}
                        renderItemName={(name) => <RoleName role={name as Role} />}
                        onPress={(items) => {
                            const newSetup: SetupConfig = {
                                ...setup,
                                roles: Object.fromEntries(
                                    items.map((item) => [item.name, item.value])
                                ) as SetupConfig["roles"],
                                last_modified_timestamp: Date.now(),
                            };

                            // TODO: some error checking here as this cast could be wrong
                            setSetup(newSetup as SetupConfig);
                            onSetupChange(newSetup as SetupConfig);
                        }}
                    />
                    <RoleSetupInfo>
                        <p>{player_ids.length} players.</p>
                        <p>{numberOfRoles} roles total.</p>
                        <p>{maxNumberOfWolfTeamPlayers} maximum wolf team members.</p>
                    </RoleSetupInfo>

                    {isHost && (
                        <DefaultSetupWrapper>
                            <Button
                                onClick={() => {
                                    const zeroedRoles = Object.fromEntries(
                                        Object.entries(setup.roles).map(([role]) => [role, 0])
                                    );
                                    const newSetup: SetupConfig = {
                                        ...setup,
                                        roles: {
                                            ...zeroedRoles,
                                            ...defaultSetups[player_ids.length],
                                        } as SetupConfig["roles"],
                                        last_modified_timestamp: Date.now(),
                                    };

                                    // TODO: some error checking here as this cast could be wrong
                                    setSetup(newSetup as SetupConfig);
                                    onSetupChange(newSetup as SetupConfig);
                                }}
                            >
                                Use default setup
                            </Button>
                        </DefaultSetupWrapper>
                    )}

                    <div>
                        <label htmlFor="timer">Timer (minutes)</label>{" "}
                        <input
                            type="number"
                            id="timer"
                            disabled={!isHost}
                            value={state.setup.timer}
                            placeholder={`Default: ${numberOfRoles}`}
                            onChange={(e) => {
                                const newSetup: SetupConfig = {
                                    ...setup,
                                    timer: parseInt(e.target.value),
                                    last_modified_timestamp: Date.now(),
                                };
                                setSetup(newSetup);
                                onSetupChange(newSetup);
                            }}
                        ></input>
                    </div>
                </SetupInner>
            </SetupOuter>
        </>
    );
}
