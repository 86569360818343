import { WerewolfGameState, Role } from "@dailygameslink/werewolf";

import { getCurrentCardLayout } from "./getCurrentCardLayout";

export function getRoleCurrentlyWithPlayer(
    state: WerewolfGameState,
    player_id: string
): Role | null {
    const cardLayout = getCurrentCardLayout(state);
    if (!cardLayout) {
        return null;
    }
    return cardLayout[player_id];
}
