import { Game, GameState } from "@dailygameslink/shared-types";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export function usePlayedGames(player_id: string | null | undefined): Game<GameState>[] {
    const playedGames = useFirestore()
        .collection("games")
        .where("game_player_ids", "array-contains", player_id || null);
    const games = useFirestoreCollectionData(playedGames) as Game<GameState>[];

    return games;
}
