import { Popup } from "semantic-ui-react";
import React from "react";
import { Role } from "@dailygameslink/werewolf";

const roleDescription: { [role in Role]: string } = {
    doppelganger: "Copies the role of another player during the night, and performs their action",
    detective:
        "During the day, at one point, can verify whether a certain person currently has a certain role",
    werewolf: "Wins with the wolves.",
    hex_wolf: "Can cast a spell on a player to mute them during the day. Wins with the wolves.",
    alpha_wolf: "Give a player a wolf card. Wins with the wolves.",
    mystic_wolf: "Looks at a players card. Wins with the wolves",
    minion: "Knows the wolves, but the wolves don't know them. Wins with the wolves.",
    apprentice_seer: "Looks at one card from the middle",
    seer: "Looks at two cards from the middle, or one card from a player",
    eavesdropper: "Looks at one card from a player",
    paranormal_investigator:
        "Checks up to two player's cards. If it finds something other than a villager, stops and joins their team.",
    robber: "Takes another player's card",
    witch: "Looks at a card from the middle and gives it to another player",
    troublemaker: "Swaps two players cards",
    drunk: "Takes a card from the middle, without knowing what it is",
    insomniac: "Finds out the card they have at the start of the day",
    bodyguard: "Protects the player they vote for at the end",
    tanner: "Wins alone if killed",
    villager: "Wins if a wolf dies",
    hunter: "If killed, the player they vote for is also killed",
    mason: "Finds out who all the other masons are during the night",
    telepath:
        "Choose a player during the night. The telepath and that player can communicate secretly player during the day.",
};

export function RoleDescription({
    children,
    role,
}: {
    role: Role;
    children: JSX.Element;
}): JSX.Element | null {
    return <Popup content={roleDescription[role] || "Unknown ⚠️"} trigger={children} />;
}
