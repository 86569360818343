import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "../../../components/Button";
import { usePerformAction } from "../../../utils/usePerformAction";

const CongratsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: var(--grid);
`;

const ButtonWrapper = styled.div`
    padding: var(--grid);
`;

export function CongratulatePlayer({
    room_id,
    target,
    player_id,
}: {
    room_id: string;
    target: string;
    player_id: string | null;
}): JSX.Element | null {
    const [bestLie, setBestLie] = useState<boolean>(false);
    const [mostHelpful, setMostHelpful] = useState<boolean>(false);
    const performAction = usePerformAction();
    return (
        <CongratsWrapper>
            <ButtonWrapper>
                <Button
                    selected={bestLie}
                    onClick={() => {
                        if (!player_id || !target) {
                            return;
                        }
                        setBestLie(true);
                        performAction({
                            room_id,
                            action: {
                                game_type: "werewolf",
                                actionName: "congratulate",
                                target,
                                player_id,
                                award: "best_lie",
                            },
                        });
                    }}
                >
                    <span role="img" aria-label="Liar">
                        👺
                    </span>{" "}
                    Best lie
                </Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button
                    selected={mostHelpful}
                    onClick={() => {
                        if (!player_id || !target) {
                            return;
                        }
                        setMostHelpful(true);
                        performAction({
                            room_id,
                            action: {
                                game_type: "werewolf",
                                actionName: "congratulate",
                                target,
                                player_id,
                                award: "most_helpful",
                            },
                        });
                    }}
                >
                    <span role="img" aria-label="Helpful">
                        🤩
                    </span>{" "}
                    Most helpful
                </Button>
            </ButtonWrapper>
        </CongratsWrapper>
    );
}
