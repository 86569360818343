import { RoleImpl } from "@dailygameslink/werewolf";

export const Minion: RoleImpl = {
    role: "minion",
    order: 300,
    name: "Minion",
    startingTeam: "wolf",
    getCurrentTeam: () => "wolf",
    isMinion: () => true,
    isStartingMinion: true,
    isWolf: () => false,
    actionDependencies: [],
    allNightActions: [],
    areNightActionsComplete: () => true,
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
