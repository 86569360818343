import { RoleImpl } from "@dailygameslink/werewolf";

import { TelepathAction } from "../actions/TelepathAction";
import { getPlayerWithStartingRole } from "../utils/getPlayerWithStartingRole";

export const Telepath: RoleImpl = {
    role: "telepath",
    order: 901,
    name: "Telepath",
    actionComponent: TelepathAction,
    startingTeam: "wolf",
    getCurrentTeam: () => "wolf",
    hasTarget: true,
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: ["telepath_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["telepath_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: (state) => {
        const telepathTarget = state.game.night_actions.telepath_target;
        const startingTelepath = getPlayerWithStartingRole(state, "telepath");
        return telepathTarget && startingTelepath ? [[telepathTarget, startingTelepath]] : [];
    },
};
