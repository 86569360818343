import { RoleImpl, TroublemakerTarget } from "@dailygameslink/werewolf";

import { TroublemakerAction } from "../actions/TroublemakerAction";
import { makeCardSwap } from "./utils";

export const Troublemaker: RoleImpl = {
    role: "troublemaker",
    order: 700,
    name: "Troublemaker",
    actionComponent: TroublemakerAction,
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    isWolf: () => false,
    isMinion: () => false,
    hasTarget: true,
    doAction: (_player_id, target, _nightAction, cardLayout) => {
        const newCardLayout = { ...cardLayout };
        const swap = makeCardSwap(newCardLayout);

        const troublemakerTarget = target as TroublemakerTarget;
        if (troublemakerTarget && troublemakerTarget[0] && troublemakerTarget[1]) {
            swap(troublemakerTarget[0], troublemakerTarget[1]);
        }

        return newCardLayout;
    },
    allNightActions: ["troublemaker_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["troublemaker_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
