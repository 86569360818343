import { DrunkTarget, RoleImpl } from "@dailygameslink/werewolf";

import { DrunkAction } from "../actions/DrunkAction";
import { makeCardSwap } from "./utils";

export const Drunk: RoleImpl = {
    role: "drunk",
    order: 800,
    name: "Drunk",
    actionComponent: DrunkAction,
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    hasTarget: true,
    isWolf: () => false,
    isMinion: () => false,
    doAction: (player_id, target, _nightAction, cardLayout) => {
        const newCardLayout = { ...cardLayout };
        const swap = makeCardSwap(newCardLayout);

        const drunkTarget = target as DrunkTarget;
        if (drunkTarget) {
            swap(player_id, drunkTarget);
        }

        return newCardLayout;
    },
    allNightActions: ["drunk_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["drunk_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
