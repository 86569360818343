import { SandboxGameState } from "@dailygameslink/sandbox";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import React from "react";
import styled from "styled-components";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/solarized_dark";

const Background = styled.div`
    background: white;
`;

export function StateEditor({
    state,
    onEdit,
}: {
    state: SandboxGameState;
    onEdit?: (state: SandboxGameState) => void;
}) {
    return (
        <Background>
            <Editor
                ace={ace}
                theme="ace/theme/solarized_dark"
                value={state}
                onChange={onEdit}
                mode="code"
            />
        </Background>
    );
}
