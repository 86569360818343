import { WerewolfGameState, Role } from "@dailygameslink/werewolf";

type NonDoppelgangerRole = Exclude<Role, "doppelganger">;

export function getDoppelgangerRole(
    state: WerewolfGameState
): NonDoppelgangerRole | undefined | null {
    const doppelgangerTarget = state.game.night_actions.doppelganger_target;
    if (!doppelgangerTarget) {
        return doppelgangerTarget as null | undefined;
    }
    if (!state.game.starting_roles) {
        return undefined;
    }
    // TODO: some error checking to make sure doppelganger hasn't targeted themselves somehow
    return state.game.starting_roles[doppelgangerTarget] as NonDoppelgangerRole;
}
