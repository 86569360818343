import React from "react";
import { Role } from "@dailygameslink/werewolf";
import { RoleDescription } from "./RoleDescription";
import { getRole } from "../roles";
import styled from "styled-components";

const WolfSpan = styled.strong`
    color: #ff4248;
    cursor: pointer;
`;

const VillagerSpan = styled.strong<{isLightBackground: boolean}>`
    color: ${props => props.isLightBackground ? 'black' : 'white'};
    cursor: pointer;
`;

export function RoleName({ role, isLightBackground }: { role: Role | null | undefined, isLightBackground?: boolean }): JSX.Element | null {
    if (!role) {
        return <span>Unknown (⚠)</span>;
    }
    const roleImpl = getRole(role);

    if (roleImpl.startingTeam === "wolf") {
        return (
            <RoleDescription role={role}>
                <WolfSpan>{roleImpl.name || role}</WolfSpan>
            </RoleDescription>
        );
    }
    return (
        <RoleDescription role={role}>
            <VillagerSpan isLightBackground={!!isLightBackground}>{roleImpl.name || role}</VillagerSpan>
        </RoleDescription>
    );
}
