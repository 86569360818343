import { Player } from "@dailygameslink/shared-types";
import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { usePlayerId } from "../utils/usePlayerId";

export function PlayerName({ player_id }: { player_id?: string }): JSX.Element | null {
    const RenderName = ({
        children,
        title,
    }: {
        children: string;
        title: string;
    }): JSX.Element | null =>
        currentPlayerId === player_id ? (
            <strong title={title}>{children}</strong>
        ) : (
            <span title={title}>{children}</span>
        );

    const playerRef = useFirestore()
        .collection("players")
        .where("player_id", "==", player_id || null)
        .limit(1);
    const player = (useFirestoreCollectionData(playerRef) as Player[])[0];
    const currentPlayerId = usePlayerId();

    if (!player_id) {
        return <RenderName title="Unknown">⚠ player not found</RenderName>;
    }

    if (!player) {
        return <RenderName title={player_id}>{`...`}</RenderName>;
    }
    return <RenderName title={player.player_name}>{player.player_name}</RenderName>;
}
