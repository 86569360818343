import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { usePerformAction } from "../../../utils/usePerformAction";
import { LoneWolfSecretInfo } from "../components/secret-info/LoneWolfSecretInfo";
import { SelectCard } from "../components/SelectCard";

export function LoneWolfAction({
    room_id,
    hasBottomCard,
    state,
}: {
    hasBottomCard: boolean;
    room_id: string;
    state: WerewolfGameState;
}): JSX.Element | null {
    const performAction = usePerformAction();

    return (
        <>
            <h3>You are the only wolf</h3>
            <p>Choose a card from the middle to look at.</p>
            <SelectCard
                hasBottomCard={hasBottomCard}
                canSelectTwo={false}
                onConfirm={(player_ids) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: "lone_wolf_target",
                            game_type: "werewolf",
                            target: player_ids[0],
                        },
                    })
                }
                isConfirmed={!!state.game.night_actions.lone_wolf_target}
            ></SelectCard>
            <LoneWolfSecretInfo state={state} />
            <hr /> {/** Lone wolf info is often prepended to another action */}
        </>
    );
}
