import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";
import { Route, Router, Switch } from "react-router-dom";

import { Changelog } from "../screens/Changelog";
import { DndContext } from "../components/DragContext";
import { Home } from "../screens/Home";
import { LoginRedirect } from "./LoginRedirect";
import { PlayerCreation } from "../screens/PlayerCreation";
import React from "react";
import { Room } from "../screens/Room";
import { Stats } from "../screens/Stats";
import { createBrowserHistory } from "history";
import { firebaseConfig } from "../config/firebaseConfig";

export const history = createBrowserHistory();

export function Routes(): JSX.Element | null {
    return (
        <DndContext>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <SuspenseWithPerf fallback={null} traceId={"load-routes"}>
                    <Router history={history}>
                        <Switch>
                            <Route path="/" exact>
                                <LoginRedirect>
                                    <Home />
                                </LoginRedirect>
                            </Route>
                            <Route path="/room/:room_id">
                                <LoginRedirect>
                                    <Room />
                                </LoginRedirect>
                            </Route>
                            <Route path="/create-player">
                                <PlayerCreation />
                            </Route>
                            <Route path="/stats">
                                <LoginRedirect>
                                    <Stats />
                                </LoginRedirect>
                            </Route>
                            <Route path="/changelog">
                                <Changelog />
                            </Route>
                        </Switch>
                    </Router>
                </SuspenseWithPerf>
            </FirebaseAppProvider>
        </DndContext>
    );
}
