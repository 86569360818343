import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../components/PlayerName";
import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";
import { RoleName } from "../components/RoleName";
import { useActionReady } from "../hooks/useActionReady";
import { getCurrentCardLayout } from "../utils/getCurrentCardLayout";
import { getRoleTeam } from "../utils/getRoleTeam";

export function ParanormalInvestigatorAction({
    room_id,
    player_ids,
    player_id,
    actionName,
    state,
    isActionReadyOverride = false,
}: {
    player_ids: string[];
    player_id: string;
    room_id: string;
    actionName?: "doppelganger_second_target";
    state: WerewolfGameState;
    isActionReadyOverride?: boolean;
}): JSX.Element | null {
    const performAction = usePerformAction();
    const isActionReady = useActionReady("paranormal_investigator", state) || isActionReadyOverride;
    if (!isActionReady) {
        return <p>Waiting...</p>;
    }
    const cardLayout = getCurrentCardLayout(state, actionName || "paranormal_investigator_target");

    const target = state.game.night_actions[actionName || "paranormal_investigator_target"];

    const firstInspectedPlayerId = target && target[0];
    const firstInspectedRole =
        firstInspectedPlayerId && cardLayout && cardLayout[firstInspectedPlayerId];

    const doingFirstInspection = !firstInspectedRole;
    const doingSecondInspection =
        firstInspectedRole &&
        firstInspectedPlayerId &&
        getRoleTeam(state, firstInspectedRole, true) === "villager";

    return (
        <>
            <p>Choose up to two players and look at their cards.</p>
            <p>If you see a wolf or a tanner, you join their team.</p>
            <div>
                {!firstInspectedRole || !firstInspectedPlayerId ? (
                    <p>Choose your first card to inspect</p>
                ) : (
                    <p>
                        <PlayerName player_id={firstInspectedPlayerId} /> was a{" "}
                        <RoleName role={firstInspectedRole}></RoleName>.
                    </p>
                )}
            </div>
            {doingFirstInspection && (
                <SelectPlayer
                    allowNobody
                    player_ids={player_ids}
                    disabledPlayerIds={[player_id]}
                    onConfirm={(player_ids) => {
                        if (!cardLayout) {
                            return Promise.resolve();
                        }
                        if (player_ids.length === 0) {
                            return performAction({
                                room_id,
                                action: {
                                    actionName: actionName || "paranormal_investigator_target",
                                    game_type: "werewolf",
                                    target: null,
                                },
                            });
                        }
                        const selectedPlayerId = player_ids[0];
                        const selectedPlayerRole = cardLayout[selectedPlayerId];
                        const inspectedNonVillagerTeam =
                            getRoleTeam(state, selectedPlayerRole, true) !== "villager";

                        if (inspectedNonVillagerTeam) {
                            return performAction({
                                room_id,
                                action: {
                                    actionName: actionName || "paranormal_investigator_target",
                                    game_type: "werewolf",
                                    target: [selectedPlayerId, null],
                                },
                            });
                        }

                        return performAction({
                            room_id,
                            action: {
                                actionName: actionName || "paranormal_investigator_target",
                                game_type: "werewolf",
                                target: [selectedPlayerId, "undecided"],
                            },
                        });
                    }}
                />
            )}
            {doingSecondInspection && firstInspectedPlayerId && (
                <>
                    <h5>Check another player</h5>
                    <SelectPlayer
                        allowNobody
                        player_ids={player_ids}
                        disabledPlayerIds={[player_id, firstInspectedPlayerId]}
                        onConfirm={(player_ids) => {
                            if (player_ids.length === 0) {
                                return performAction({
                                    room_id,
                                    action: {
                                        actionName: actionName || "paranormal_investigator_target",
                                        game_type: "werewolf",
                                        target: [firstInspectedPlayerId, null],
                                    },
                                });
                            }
                            const secondInspectedPlayerId = player_ids[0];
                            return performAction({
                                room_id,
                                action: {
                                    actionName: actionName || "paranormal_investigator_target",
                                    game_type: "werewolf",
                                    target: [firstInspectedPlayerId, secondInspectedPlayerId],
                                },
                            });
                        }}
                    />
                </>
            )}
        </>
    );
}
