import { Preferences } from "@dailygameslink/shared-types"
import * as React from "react";
import styled from "styled-components";

const PreferenceGroupWrapper = styled.div`
    margin-top: calc(3 * var(--grid))px;
`;

export const PreferencesComponent = ({ onChange, initialPreferences }: {initialPreferences: Preferences | null, onChange: (preferences: Preferences) => void }) => {
    return (<PreferenceGroupWrapper>
        <h3>Sound</h3>
        <input id="sound-effects-disabled" type="checkbox" defaultChecked={initialPreferences?.soundEffectsDisabled ?? false} onChange={(e) => onChange({
            soundEffectsDisabled: e.target.checked
        })}></input><label htmlFor="sound-effects-disabled">&nbsp;Sound effects disabled?</label>
    </PreferenceGroupWrapper>);
}