import { WerewolfGameState, NightAction, MysticWolfTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { getStartingWolfPlayerIds } from "../../utils/getStartingWolfPlayerIds";
import { RoleName } from "../RoleName";

import { LoneWolfSecretInfo } from "./LoneWolfSecretInfo";

export function MysticWolfSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: MysticWolfTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, actionOverride || "mystic_wolf_target");
    const target =
        targetOverride === undefined ? state.game.night_actions.mystic_wolf_target : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no targetPlayerId
            </p>
        );
    }
    const startingWolves = getStartingWolfPlayerIds(state);
    if (startingWolves === null) {
        return (
            <p>
                (
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: starting wolves are unknown)
            </p>
        );
    }

    // Sort ids: doesn't need to be alphabetical, just consistent, so that doppelganger isn't always at the bottom
    startingWolves.sort((a, b) => (a > b ? -1 : 1));

    const wolfNames = (
        <>
            The wolves were:
            {startingWolves.map((player_id) => (
                <p key={player_id}>
                    <PlayerName player_id={player_id} />
                </p>
            ))}
        </>
    );
    const inspectedRole = cardLayout[target];
    const mysticWolfInfo = (
        <p>
            You inspected {<PlayerName player_id={target}></PlayerName>}, who was a{" "}
            <RoleName role={inspectedRole} />
        </p>
    );
    return (
        <>
            {wolfNames}
            {mysticWolfInfo}
            {getStartingWolfPlayerIds(state)?.length === 1 && <LoneWolfSecretInfo state={state} />}
        </>
    );
}
