import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";

export function TelepathAction({
    room_id,
    player_ids,
    player_id,
    actionName,
}: {
    player_ids: string[];
    player_id: string;
    room_id: string;
    actionName?: "doppelganger_second_target";
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <>
            <p>Choose a player to communicate with</p>
            <SelectPlayer
                allowNobody={false}
                player_ids={player_ids}
                disabledPlayerIds={[player_id]}
                onConfirm={(player_ids) => {
                    const target = player_ids[0];
                    if (!target) {
                        return Promise.reject();
                    }
                    return performAction({
                        room_id,
                        action: {
                            actionName: actionName || "telepath_target",
                            game_type: "werewolf",
                            target,
                        },
                    });
                }}
            ></SelectPlayer>
        </>
    );
}
