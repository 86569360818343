import { WerewolfGameState, NightAction, EavesdropperTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function EavesdropperSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: EavesdropperTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, actionOverride || "eavesdropper_target");
    const target =
        targetOverride === undefined
            ? state.game.night_actions.eavesdropper_target
            : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no target
            </p>
        );
    }

    return (
        <>
            <p>You inspected a player.</p>
            <p>
                <PlayerName player_id={target} /> was a <RoleName role={cardLayout[target]} />
            </p>
        </>
    );
}
