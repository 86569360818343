import { RoleImpl } from "@dailygameslink/werewolf";
import { WerewolfAction } from "../actions/WerewolfAction";
import { getGameHasLoneWolfAction } from "../utils/getGameHasLoneWolfAction";

export const Werewolf: RoleImpl = {
    role: "werewolf",
    order: 200,
    name: "Werewolf",
    actionComponent: WerewolfAction,
    startingTeam: "wolf",
    isStartingWolf: true,
    getCurrentTeam: () => "wolf",
    isWolf: () => true,
    isMinion: () => false,
    // Players who see the other wolves must wait for the doppelganger, in case the doppelganger becomes a wolf
    actionDependencies: ["doppelganger"],
    allNightActions: ["lone_wolf_target"],
    areNightActionsComplete: function (state) {
        return getGameHasLoneWolfAction(state)
            ? state.game.night_actions["lone_wolf_target"] !== undefined
            : true;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
