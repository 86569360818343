import * as React from "react";

import { AssignedRoles, Role, SetupConfig } from "@dailygameslink/werewolf";

import { Card } from "./cards/Card";
import { DraggablePlayerName } from "../../../components/DraggablePlayerName";
import { Droppable } from "react-beautiful-dnd";
import { getRole } from "../roles";
import styled from "styled-components";

const RoleCards = styled.div<{ wide?: boolean }>`
    display: flex;
    ${(props) =>
        props.wide
            ? "max-width: calc(var(--center-width) * 2)"
            : "width: calc(var(--center-width) * 1.5)"};
    justify-content: left;
    flex-wrap: wrap;
`;

const CardWrapper = styled.div`
    margin: var(--grid) calc(var(--grid));
`;

const AssignedPlayer = styled.div<{ hasBorder: boolean }>`
    min-height: calc(var(--grid) * 4);
    border: 2px dashed #577f9a;
    ${(props) => (props.hasBorder ? "" : "border-color: transparent")};
    transition: border-color 250ms;
`;

export function RoleList({
    roles,
    droppable,
    assignedRoles,
    wide,
}: {
    wide?: boolean;
    droppable?: boolean;
    assignedRoles?: AssignedRoles;
    roles: SetupConfig["roles"];
}) {
    // TODO: use role.order
    const roleWakeOrder: { [role in Role]: number } = {
        doppelganger: 0,
        werewolf: 1,
        alpha_wolf: 2,
        mystic_wolf: 3,
        hex_wolf: 3.5,
        minion: 4,
        mason: 5,
        seer: 6,
        apprentice_seer: 7,
        eavesdropper: 8,
        paranormal_investigator: 9,
        robber: 10,
        witch: 11,
        troublemaker: 12,
        drunk: 13,
        insomniac: 14,
        telepath: 15,
        bodyguard: 100,
        hunter: 101,
        villager: 102,
        detective: 102.1,
        tanner: 103,
    };
    const rolesInGame = (Object.entries(roles) as [Role, number][])
        .filter(([_role, count]) => count > 0)
        // Insert n cards depending on count, and an extra "virtual" wolf card for the alpha wolf
        .map<Role[]>(([role, count]) =>
            new Array(count).fill(role).concat(getRole(role).createsBottomCard ? ["werewolf"] : [])
        )
        .flat()
        .sort((a, b) => roleWakeOrder[a] - roleWakeOrder[b]);
    return (
        <>
            <h3>Roles in their wake up order</h3>
            <RoleCards wide={wide}>
                {rolesInGame.map((role, i) => (
                    <>
                        <CardWrapper>
                            <Card key={i} role={role} />
                            {droppable && (
                                <Droppable droppableId={`role_${i}`}>
                                    {(provided) => (
                                        <AssignedPlayer
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            hasBorder={
                                                !assignedRoles ||
                                                !assignedRoles[`role_${i}`] ||
                                                assignedRoles[`role_${i}`].length === 0
                                            }
                                        >
                                            {(
                                                (assignedRoles && assignedRoles[`role_${i}`]) ||
                                                []
                                            ).map((player_id, j) => (
                                                <DraggablePlayerName
                                                    width={116}
                                                    draggable
                                                    player_id={player_id}
                                                    index={j}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </AssignedPlayer>
                                    )}
                                </Droppable>
                            )}
                        </CardWrapper>
                    </>
                ))}
            </RoleCards>
        </>
    );
}
