import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function LoneWolfSecretInfo({ state }: { state: WerewolfGameState }): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, "lone_wolf_target");

    const target = state.game.night_actions.lone_wolf_target;
    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return null; // This is not an error state, because we render this component before the target is chosen.
    }
    const inspectedRole = cardLayout[target];
    return (
        <p>
            You were the lone wolf. You inspected the {target} card, which was a{" "}
            <RoleName role={inspectedRole} />
        </p>
    );
}
