import * as React from "react";

import { Role, WerewolfGameState } from "@dailygameslink/werewolf";

import { PlayerName } from "../../../components/PlayerName";
import { RoleName } from "../components/RoleName";
import { SelectPlayer } from "../../../components/SelectPlayer";
import { SelectRole } from "../../../components/SelectRole";
import { getCurrentCardLayout } from "../utils/getCurrentCardLayout";
import { getRolesInGame } from "../utils/roles";
import { usePerformAction } from "../../../utils/usePerformAction";
import { useState } from "react";

export function DetectiveDayAction({
    room_id,
    state,
    startingWolfPlayerIds,
    player_ids,
    player_id,
}: {
    hasBottomCard: boolean;
    room_id: string;
    state: WerewolfGameState;
    startingWolfPlayerIds: string[];
    player_id: string;
    player_ids: string[];
}): JSX.Element | null {
    const performAction = usePerformAction();
    const currentDetectiveAction = (state.game.day_actions[player_id] || {}).detective;
    const [player, setPlayer] = useState<string | null>(null);
    if (!player) {
        return (
            <div>
                <h3>Inspect a player</h3>
                <p>Choose a player to inspect</p>
                <SelectPlayer
                    player_ids={player_ids}
                    disabledPlayerIds={[]}
                    onConfirm={(player_ids) => {
                        setPlayer(player_ids[0]);

                        return Promise.resolve();
                    }}
                ></SelectPlayer>
                </div>
            );
    }

    if (currentDetectiveAction) {
        const cardLayout = getCurrentCardLayout(state);
        if (!cardLayout) {
            return <p>⚠️ No card layout</p>;
        }
        const player = currentDetectiveAction[0];
        const role = currentDetectiveAction[1];
        const wasCorrect = cardLayout[player] === role;
        return <div>
            <h3>Your detective result</h3>
            <p>You guessed <PlayerName player_id={currentDetectiveAction[0]} /> currently had the <RoleName role={currentDetectiveAction[1]} /> card </p>
            <p>That guess was <strong>{wasCorrect ? "correct" : "not correct"}.</strong></p>
        </div>
    }

    const rolesInGame = Object.entries(state.setup.roles).filter(([k,v]) => v > 0).map(e => e[0]) as Role[];

    if (!rolesInGame) {
        return <p>⚠️ No roles in game</p>
    }

    return (
        <div>
            <p>What role do you think <PlayerName player_id={player}/> currently has?</p>
            <SelectRole
                roles={rolesInGame}
                disabledRoles={[]}
                onConfirm={(roles) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: "detective",
                            game_type: "werewolf",
                            target: [player, roles[0]],
                            player_id,
                        },
                    })
                }
            ></SelectRole>
            </div>
    );
}
