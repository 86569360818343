import { useFunctions } from "reactfire";

export type RemovePlayer = (player_id: string, room_id: string) => Promise<void>;

export function useRemovePlayer(): RemovePlayer {
    const removePlayer = useFunctions().httpsCallable("removeplayer");

    return async (player_id, room_id) => {
        await removePlayer({
            player_id,
            room_id,
        });
    };
}
