import { WerewolfGameState, NightAction, DrunkTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";

export function DrunkSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: DrunkTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, actionOverride || "drunk_target");
    const target =
        targetOverride === undefined ? state.game.night_actions.drunk_target : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no target
            </p>
        );
    }

    return (
        <>
            <p>You took the {target} card from the middle, swapping it with your own.</p>
        </>
    );
}
