import * as React from "react";

import { PlayerName } from "../../../components/PlayerName";
import { RoleName } from "../components/RoleName";
import { SelectPlayer } from "../../../components/SelectPlayer";
import { WerewolfGameState } from "@dailygameslink/werewolf";
import { getDoppelgangerRole } from "../utils/getDoppelgangerRole";
import { getRole } from "../roles";
import { usePerformAction } from "../../../utils/usePerformAction";

export function DoppelgangerDayAction({
    room_id,
    player_ids,
    startingWolfPlayerIds,
    player_id,
    state,
    hasBottomCard,
}: {
    startingWolfPlayerIds: string[];
    player_ids: string[];
    player_id: string;
    room_id: string;
    hasBottomCard: boolean;
    state: WerewolfGameState;
}): JSX.Element | null {
    const doppelgangerRole = getDoppelgangerRole(state);
    const dayActionProps: {
        room_id: string;
        player_id: string;
        player_ids: string[];
    } = {
        room_id,
        player_id,
        player_ids,
    };
    const DayAction = doppelgangerRole ? getRole(doppelgangerRole).dayActionComponent : null;
    return DayAction ? (
        <DayAction
            {...dayActionProps}
            startingWolfPlayerIds={startingWolfPlayerIds}
            hasBottomCard={hasBottomCard}
            state={state}
            isActionReadyOverride
        />
    ) : null;
}
