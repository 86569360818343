import { WerewolfGameState } from "@dailygameslink/werewolf";
import { getRole } from "../roles";
import { getCurrentCardLayout } from "./getCurrentCardLayout";

export function getProtectedPlayers(state: WerewolfGameState): string[] {
    const votes = state.game.votes;
    const cardLayout = getCurrentCardLayout(state);
    if (!cardLayout) {
        return [];
    }
    const protectedPlayers: string[] = [];
    Object.keys(votes).forEach((player_id) => {
        if (getRole(cardLayout[player_id]).voteEffect(state) === "protects") {
            protectedPlayers.push(votes[player_id]);
        }
    });

    return protectedPlayers;
}
