import * as React from "react";
import styled from "styled-components";

type Items = {
    value: number;
    min?: number;
    max?: number;
    name: string;
    disabled: boolean;
}[];

const Controls = styled.div`
    margin-right: var(--grid);
`;

const Row = styled.div`
    display: flex;
`;

const ItemNameWrapper = styled.span<{ isHighlighted: boolean }>`
    /* color: ${(props) => (props.isHighlighted ? "currentcolor" : "#394852")}; */
    opacity: ${(props) => (props.isHighlighted ? "1" : "0.35")};
`;

export function CounterList({
    items,
    onPress,
    renderItemName,
    showCounters,
}: {
    items: Items;
    onPress(newItems: Items): void;
    renderItemName?(name: string): JSX.Element;
    showCounters: boolean;
}): JSX.Element | null {
    return (
        <>
            {items.map((item, i) => (
                <Row key={i}>
                    {showCounters && (
                        <Controls>
                            <button
                                disabled={
                                    item.disabled ||
                                    (item.max !== undefined && item.value + 1 > item.max)
                                }
                                onClick={() => {
                                    const newItems = [...items];
                                    newItems[i].value++;
                                    onPress(newItems);
                                }}
                            >
                                +
                            </button>
                            <button
                                disabled={
                                    item.disabled ||
                                    (item.min === undefined
                                        ? item.value <= 0
                                        : item.value - 1 < item.min)
                                }
                                onClick={() => {
                                    const newItems = [...items];
                                    newItems[i].value--;
                                    onPress(newItems);
                                }}
                            >
                                -
                            </button>
                        </Controls>
                    )}
                    <ItemNameWrapper isHighlighted={item.value > 0}>
                        {renderItemName ? renderItemName(item.name) : item.name} ({item.value})
                    </ItemNameWrapper>
                </Row>
            ))}
        </>
    );
}
