import { WerewolfGameState, Target, Role, CardLayout, NightAction } from "@dailygameslink/werewolf";
import { getRole } from "../roles";

import { getPlayerWithStartingRole } from "./getPlayerWithStartingRole";
import { getRolesInGame } from "./roles";

function doAction(
    player_id: string,
    role: Role,
    target: Target,
    nightAction: NightAction,
    cardLayout: CardLayout,
    state: WerewolfGameState
) {
    if (!target || !role) {
        return cardLayout;
    }
    const doAction = getRole(role).doAction;
    if (!doAction) {
        return cardLayout;
    }

    return doAction(player_id, target, nightAction, cardLayout, state);
}

export function getCurrentCardLayout(
    state: WerewolfGameState,
    upToAction?: NightAction
): CardLayout | null {
    if (!state.game.starting_roles) {
        return null;
    }
    let cardLayout: CardLayout = {
        ...state.game.starting_roles,
        ...state.game.middle_cards,
    };

    const rolesInGame = getRolesInGame(state);
    if (!rolesInGame) {
        return null;
    }
    const orderedRoles = rolesInGame
        .map((role) => getRole(role))
        .sort((a, b) => {
            if (a.order === undefined && b.order === undefined) {
                return 0;
            }
            if (a.order === undefined) {
                return 1;
            }
            if (b.order === undefined) {
                return -1;
            }
            return a.order > b.order ? 1 : -1;
        });

    for (let role of orderedRoles) {
        for (let nightAction of role.allNightActions) {
            if (upToAction === nightAction) {
                return cardLayout;
            }

            if (state.game.night_actions[nightAction]) {
                const player_id = getPlayerWithStartingRole(state, role.role);
                const target = state.game.night_actions[nightAction];
                if (player_id && target) {
                    cardLayout = doAction(
                        player_id,
                        role.role,
                        target,
                        nightAction,
                        cardLayout,
                        state
                    );
                }
            }
        }
    }

    return cardLayout;
}
