import React from "react";
import { useParams } from "react-router-dom";
import { SuspenseWithPerf } from "reactfire";
import styled from "styled-components";

import { AutoJoinRoom } from "../components/AutoJoinRoom";
import { Header } from "../components/Header";
import { RenderInLobbyState } from "../components/RenderInLobbyState";
import { RoomTitle } from "../components/RoomTitle";
import { Game } from "../games/Game";
import { Setup } from "../games/Setup";
import { Rules } from "../games/Rules";
import { HostControls } from "../sections/HostControls";
import { PlayerList } from "../sections/PlayerList";

const LobbyWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    @media screen and (max-width: 769px) {
        flex-direction: column;
        & > * {
            padding: calc(var(--grid) * 3) 0 0 0;
        }
    }
`;

const RulesWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const RulesWrapperInner = styled.div`
    max-width: var(--center-width);
`;

const PlayersTitle = styled.h2`
    text-align: center;
`;

export function Room(): JSX.Element | null {
    const { room_id } = useParams<{room_id: string}>();

    return (
        <>
            <SuspenseWithPerf fallback={null} traceId={"load-room-screen"}>
                <Header room_id={room_id} />
                <RenderInLobbyState room_id={room_id} isLobby>
                    <>
                        <RoomTitle room_id={room_id} />
                        <LobbyWrapper>
                            <div>
                                <RulesWrapper>
                                    <RulesWrapperInner>
                                        <Rules room_id={room_id} />
                                    </RulesWrapperInner>
                                </RulesWrapper>
                            </div>
                            <div>
                                <PlayersTitle>Players</PlayersTitle>
                                <SuspenseWithPerf fallback={null} traceId={"load-player-list"}>
                                    <PlayerList room_id={room_id}></PlayerList>
                                </SuspenseWithPerf>
                            </div>
                            <div>
                                <Setup room_id={room_id} />
                            </div>
                        </LobbyWrapper>
                        <HostControls room_id={room_id} />
                        <AutoJoinRoom room_id={room_id} />
                    </>
                </RenderInLobbyState>
                <RenderInLobbyState room_id={room_id} isLobby={false}>
                    <Game room_id={room_id} />
                </RenderInLobbyState>
            </SuspenseWithPerf>
        </>
    );
}
