import { Role, WerewolfGameState } from "@dailygameslink/werewolf";

import { getRole } from "../roles";

export function getRolesInGame(state: WerewolfGameState): Role[] | null {
    if (!state.game.starting_roles) {
        return null;
    }
    return Object.values(state.game.starting_roles);
}

export function gameHasRole(state: WerewolfGameState, role: Role): boolean {
    return !!getRolesInGame(state)?.includes(role);
}

export function gameHasBottomCard(state: WerewolfGameState): boolean {
    return getRolesInGame(state)?.some((role) => getRole(role).createsBottomCard) || false;
}

function getNumberOfWolves(state: WerewolfGameState) {
    if (!state.game.starting_roles) {
        return null;
    }
    const startingWolves = Object.values(state.game.starting_roles).filter(
        (role) => getRole(role as Role).isWolf(state)
    ).length;

    return startingWolves;
}

export function getGameHasLoneWolf(state: WerewolfGameState): boolean {
    return getNumberOfWolves(state) === 1;
}
