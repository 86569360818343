import { Votes } from "@dailygameslink/werewolf";

import { Tally } from "./Tally";

export function tallyVotes(votes: Votes): Tally {
    const tally: { [player_id: string]: number } = {};
    Object.values(votes).forEach((vote_target) => {
        tally[vote_target] = tally[vote_target] ? tally[vote_target] + 1 : 1;
    });

    return tally;
}
