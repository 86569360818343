import { WerewolfGameState } from "@dailygameslink/werewolf";

import { Tally } from "./Tally";

export type TallyData = {
    bestLieTally: Tally;
    mostHelpfulTally: Tally;
    mostHelpfulWinners: string[];
    bestLieWinners: string[];
    maxBestLieVotes: number;
    maxMostHelpfulVotes: number;
};

export function getCongratulationsTally(state: WerewolfGameState): TallyData {
    const bestLieTally: { [player_id: string]: number } = {};
    const mostHelpfulTally: { [player_id: string]: number } = {};

    Object.values(state.game.congratulations).forEach((votes) => {
        const bestLieVote = votes?.best_lie;
        const mostHelpfulVote = votes?.most_helpful;
        if (bestLieVote) {
            bestLieTally[bestLieVote] = (bestLieTally[bestLieVote] || 0) + 1;
        }
        if (mostHelpfulVote) {
            mostHelpfulTally[mostHelpfulVote] = (mostHelpfulTally[mostHelpfulVote] || 0) + 1;
        }
    });

    const bestLieWinners: string[] = [];
    const mostHelpfulWinners: string[] = [];

    const maxBestLieVotes = Math.max(...Object.values(bestLieTally));
    const maxMostHelpfulVotes = Math.max(...Object.values(mostHelpfulTally));

    Object.entries(bestLieTally).forEach(([player_id, votes]) => {
        if (votes === maxBestLieVotes) {
            bestLieWinners.push(player_id);
        }
    });

    Object.entries(mostHelpfulTally).forEach(([player_id, votes]) => {
        if (votes === maxMostHelpfulVotes) {
            mostHelpfulWinners.push(player_id);
        }
    });

    return {
        bestLieTally,
        mostHelpfulTally,
        mostHelpfulWinners,
        bestLieWinners,
        maxBestLieVotes,
        maxMostHelpfulVotes,
    };
}
