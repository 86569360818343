import React from 'react';

export function Rules() {
    return <div>
        <h2>Werewolf rules</h2>
        <p>Each player receives a card at the start of the game, which may be a wolf or a villager card. Three leftover cards are placed in the "middle of the table". When the game starts, all players find out what cards they have, and then the village goes to sleep.</p>
        <p>During the night, players "wake up" in order and perform actions – these can be things like swapping cards, stealing them, or looking at other players cards (or even the leftover ones in the middle!). The wolves find out who other wolves are, but villagers do not find out who the wolves are.</p>
        <p>Your objective is to determine what card you have, and who to vote for to kill at the end.</p>
        <h3>Winning the game</h3>
        <ul>
            <li>Villagers win if they kill a wolf</li>
            <li>Wolves win if no wolves die</li>
            <li>If there are no wolves and no minions in the game, the villagers win if they kill nobody</li>
            <li>If there are no wolves but minion(s) in the game, the villagers win only if they kill nobody, but the minion(s) must kill a villager</li>
        </ul>
    </div>
}