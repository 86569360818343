import { useRoom } from "../utils/useRoom";

export function RenderInLobbyState({
    room_id,
    isLobby,
    children,
}: {
    children: JSX.Element;
    room_id: string;
    isLobby: boolean;
}): JSX.Element | null {
    const room = useRoom(room_id);

    if (room.is_lobby === isLobby) {
        return children;
    }
    return null;
}
