import { SandboxGameState } from "@dailygameslink/sandbox";
import * as React from "react";
import { SegmentGroupProps } from "semantic-ui-react";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { StateEditor } from "./visuals/gui/StateEditor";
import { setups } from "./setups";

const SetupOuter = styled.div`
    display: flex;
    justify-content: center;
`;

const SetupInner = styled.div`
    width: var(--center-width);
`;

const Title = styled.h2`
    text-align: center;
`;

export function Setup({
    state,
    isHost,
    onSetupChange,
}: {
    state: SandboxGameState;
    isHost: boolean;
    onSetupChange(setup: SandboxGameState): void;
    player_ids: string[];
}): JSX.Element | null {
    return (
        <>
            <SetupOuter>
                <SetupInner>
                    <Title>Sandbox setup</Title>
                    {isHost && (
                        <>
                            <h3>Pre made setups</h3>
                            {Object.keys(setups).map((setup) => {
                                const setupName = setup as keyof typeof setups;
                                return (
                                    <div>
                                        <Button
                                            key={setupName}
                                            onClick={() => onSetupChange(setups[setupName])}
                                        >
                                            {setupName}
                                        </Button>
                                    </div>
                                );
                            })}

                            <h3>Edit setups</h3>
                            <StateEditor state={state} onEdit={onSetupChange}></StateEditor>
                        </>
                    )}
                </SetupInner>
            </SetupOuter>
        </>
    );
}
