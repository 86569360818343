import { SandboxGameState } from "@dailygameslink/sandbox";
import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";
import { SuspenseWithPerf } from "reactfire";

import { HostGameControls } from "../components/HostGameControls";
import { useGame } from "../utils/useGame";
import { usePlayerId } from "../utils/usePlayerId";
import { useRoom } from "../utils/useRoom";

import { Game as WerewolfGame } from "./werewolf/Game";
import { Game as SandboxGame } from "./sandbox/Game";

export function Game({ room_id }: { room_id: string }): JSX.Element | null {
    const room = useRoom(room_id);
    const game = useGame(room.game_id);
    const player_id = usePlayerId();
    if (!player_id) {
        return null;
    }
    let gameComponent = null;
    if (game.game_type === "werewolf") {
        gameComponent = (
            <>
                {player_id === room.host_player_id && (
                    <HostGameControls room_id={room_id} player_id={player_id} />
                )}
                <WerewolfGame
                    room_id={room.room_id}
                    player_id={player_id}
                    player_ids={room.player_ids}
                    state={game.game_state as WerewolfGameState}
                />
            </>
        );
    } else if (game.game_type === "sandbox") {
        gameComponent = (
            <>
                {player_id === room.host_player_id && (
                    <HostGameControls room_id={room_id} player_id={player_id} />
                )}
                <SandboxGame
                    room_id={room.room_id}
                    player_id={player_id}
                    player_ids={room.player_ids}
                    state={game.game_state as SandboxGameState}
                />
            </>
        );
    }

    return (
        <SuspenseWithPerf fallback={null} traceId={"load-game"}>
            {gameComponent}
        </SuspenseWithPerf>
    );
}
