import React from "react";
import styled from "styled-components";

import { PlayerNameList } from "../components/PlayerNameList";
import { usePlayerId } from "../utils/usePlayerId";
import { useRemovePlayer } from "../utils/useRemovePlayer";
import { useRoom } from "../utils/useRoom";

const RemovePlayerButton = styled.div`
    background: none;
    border: none;
    cursor: pointer;
`;

export function PlayerList({ room_id }: { room_id: string }): JSX.Element | null {
    const room = useRoom(room_id);
    const currentPlayerId = usePlayerId();
    const isHost = room.host_player_id === currentPlayerId;
    const removePlayer = useRemovePlayer();
    return (
        <>
            <PlayerNameList
                player_ids={room.player_ids}
                post={(player_id) => (
                    <>
                        {isHost && room.host_player_id !== player_id ? (
                            <RemovePlayerButton
                                onClick={() => {
                                    removePlayer(player_id, room_id);
                                }}
                            >
                                <span role="img" aria-label="Remove">
                                    ❌
                                </span>
                            </RemovePlayerButton>
                        ) : (
                            room.host_player_id === player_id && <em>Host</em>
                        )}
                    </>
                )}
            />
        </>
    );
}
