import { WerewolfGameState, AlphaWolfTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getStartingWolfPlayerIds } from "../../utils/getStartingWolfPlayerIds";

import { LoneWolfSecretInfo } from "./LoneWolfSecretInfo";

export function AlphaWolfSecretInfo({
    state,
    targetOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: AlphaWolfTarget;
}): JSX.Element | null {
    const target =
        targetOverride === undefined ? state.game.night_actions.alpha_wolf_target : targetOverride;
    const alphaWolfSecretInfo = (
        <p>You gave the bottom card to {<PlayerName player_id={target}></PlayerName>}</p>
    );

    const startingWolves = getStartingWolfPlayerIds(state);
    if (startingWolves === null) {
        return (
            <p>
                (
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: starting wolves are unknown)
            </p>
        );
    }

    // Sort ids: doesn't need to be alphabetical, just consistent, so that doppelganger isn't always at the bottom
    startingWolves.sort((a, b) => (a > b ? -1 : 1));

    const wolfNames = (
        <>
            The wolves were:
            {startingWolves.map((player_id) => (
                <div key={player_id}>
                    <PlayerName player_id={player_id} />
                </div>
            ))}
        </>
    );

    return (
        <>
            {wolfNames}
            {alphaWolfSecretInfo}
            {getStartingWolfPlayerIds(state)?.length === 1 && <LoneWolfSecretInfo state={state} />}
        </>
    );
}
