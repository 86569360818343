import { WerewolfGameState } from "@dailygameslink/werewolf";
import { gameHasRole } from "./roles";
import { getDoppelgangerRole } from "./getDoppelgangerRole";
import { getRole } from "../roles";
import { getStartingWolfPlayerIds } from "./getStartingWolfPlayerIds";

export function getGameHasLoneWolfAction(state: WerewolfGameState) {
    const startingWolfPlayerIds = getStartingWolfPlayerIds(state);
    const wolfCount = startingWolfPlayerIds ? startingWolfPlayerIds.length : 0;
    const doppelgangerRole = getDoppelgangerRole(state);

    if (
        (gameHasRole(state, "doppelganger") &&
            doppelgangerRole &&
            !getRole(doppelgangerRole).isStartingWolf) ||
        !gameHasRole(state, "doppelganger")
    ) {
        return wolfCount === 1;
    }

    return false;
}
