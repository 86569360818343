import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";
import { SelectCard } from "../components/SelectCard";

export function SeerAction({
    room_id,
    player_ids,
    player_id,
    actionName,
    hasBottomCard,
}: {
    player_ids: string[];
    player_id: string;
    room_id: string;
    actionName?: "doppelganger_second_target";
    hasBottomCard: boolean;
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <>
            <p>Choose two cards from the middle to look at, or one other player&apos;s card</p>
            <p>Select two cards from the middle</p>
            <SelectCard
                hasBottomCard={hasBottomCard}
                canSelectTwo
                onConfirm={(cardPositions) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: actionName || "seer_target",
                            game_type: "werewolf",
                            target: [cardPositions[0], cardPositions[1]],
                        },
                    })
                }
            ></SelectCard>
            <p>Or, select one player</p>
            <SelectPlayer
                player_ids={player_ids}
                disabledPlayerIds={[player_id]}
                onConfirm={(player_ids) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: actionName || "seer_target",
                            game_type: "werewolf",
                            target: player_ids[0],
                        },
                    })
                }
            ></SelectPlayer>
        </>
    );
}
