import React, { useEffect, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import styled from "styled-components";

const CardContainer = styled.div<{ width: number; height: number }>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    position: relative;
    margin: auto;
    border: 2px solid white;
    border-radius: 5px;
`;

const CardWrapper = styled.div<{ faceDown: boolean }>`
    text-align: center;
    align-items: center;
    flex-direction: column;
    transition: transform 1s;
    transform-style: preserve-3d;
    ${(props) => (props.faceDown ? "transform: rotateY(180deg)" : "")};
`;

const CardFace = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
    backface-visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function getCorrectTextColor(hex: string) {
    /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
    
    Color brightness is determined by the following formula: 
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

I know this could be more compact, but I think this is easier to read/explain.
    
    */

    function hexToR(h: string) {
        return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h: string) {
        return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h: string) {
        return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h: string) {
        return h.charAt(0) == "#" ? h.substring(1, 7) : h;
    }

    const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);

    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
        return "#000000";
    } else {
        return "#ffffff";
    }
}
const CardFront = styled(CardFace)<{ cardFrontImage?: string; cardFrontColor?: string }>`
    background-color: ${(props) => props.cardFrontColor || "#032941"};
    color: ${(props) => getCorrectTextColor(props.cardFrontColor || "#032941")};
    ${(props) => props.cardFrontImage && `background-image: url(${props.cardFrontImage})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none; /** Required for react-contexify, otherwise context menu doesn't show on cards */
`;

const CardBack = styled(CardFace)<{ cardBackColor?: string; cardBackImage?: string }>`
    ${(props) => {
        if (props.cardBackImage) {
            return `background-image: url(${props.cardBackImage});`;
        }
        return `background-color: ${props.cardBackColor || "black"};`;
    }}
    transform: rotateY(180deg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none; /** Required for react-contexify, otherwise context menu doesn't show on cards */
`;

const NameWrapper = styled.div`
    margin-top: var(--grid);
    text-align: center;
`;

export function Card({
    width,
    height,
    reveal = 0,
    faceDown = false,
    label = null,
    tooltipWrapper,
    cardFrontImage,
    cardFrontContent,
    cardBackColor,
    cardFrontColor,
    cardBackImage,
}: {
    width: number;
    height: number;
    reveal?: number;
    faceDown?: boolean;
    label?: JSX.Element | string | null;
    tooltipWrapper?: ({ children }: { children: JSX.Element }) => JSX.Element | null;
    cardFrontImage?: string;
    cardFrontContent?: JSX.Element | string;
    cardBackColor?: string;
    cardFrontColor?: string;
    cardBackImage?: string;
}): JSX.Element | null {
    const [revealed, setRevealed] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setRevealed(true);
        }, reveal);
    });
    const isCardFaceDown = faceDown || (reveal > 0 && !revealed);
    const Wrapper =
        isCardFaceDown || !tooltipWrapper
            ? ({ children }: { children: JSX.Element }) => children
            : tooltipWrapper;
    return (
        <>
            <CardWrapper faceDown={isCardFaceDown}>
                <CardContainer width={width} height={height}>
                    <Wrapper>
                        <CardFront cardFrontImage={cardFrontImage} cardFrontColor={cardFrontColor}>
                            {cardFrontContent}
                        </CardFront>
                    </Wrapper>
                    <CardBack
                        cardBackImage={cardBackImage}
                        cardBackColor={cardBackColor}
                    ></CardBack>
                </CardContainer>
            </CardWrapper>

            <NameWrapper>{label}</NameWrapper>
        </>
    );
}
