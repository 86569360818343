import { Game } from "@dailygameslink/shared-types";
import { WerewolfGameState, Team, Role } from "@dailygameslink/werewolf";
import React from "react";
import { SuspenseWithPerf } from "reactfire";
import { Table, Header } from "semantic-ui-react";
import styled from "styled-components";

import { Header as AppHeader } from "../components/Header";
import { PlayerName } from "../components/PlayerName";
import { RoleName } from "../games/werewolf/components/RoleName";
import { getCongratulationsTally } from "../games/werewolf/utils/getCongratulationsTally";
import { getPlayerTeam } from "../games/werewolf/utils/getPlayerTeam";
import { getWinningPlayers } from "../games/werewolf/utils/getWinningTeam";
import { usePlayedGames } from "../utils/usePlayedGames";
import { usePlayerId } from "../utils/usePlayerId";

const StatsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: var(--center-width);
`;

// I invert the table to get it to fit with the dark theme of the site.
// This means we have to un-invert images and colored text 😅
const TableWrapper = styled.div`
    filter: invert(1);
`;

const Icon = styled.span`
    filter: invert(1);
    margin-right: var(--grid);
`;

const RoleText = styled.span`
    filter: invert(1);
`;

function TableRow({
    title,
    subtitle,
    icon,
    value,
}: {
    title: string;
    icon: string;
    subtitle?: string;
    value: string | number | JSX.Element;
}) {
    return (
        <Table.Row>
            <Table.Cell>
                <Header as="h4">
                    <Header.Content>
                        <Icon>{icon}</Icon>
                        {title}
                        {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>{value}</Table.Cell>
        </Table.Row>
    );
}

function getWerewolfVictories(games: Game<WerewolfGameState>[], player_id: string | null) {
    if (!player_id) {
        return [];
    }
    return games.filter((game) => {
        const [winningPlayers] = getWinningPlayers(game.game_state, game.game_player_ids) || [];
        if (winningPlayers?.includes(player_id)) {
            return true;
        }

        return false;
    });
}

function getGamesAsTeam(games: Game<WerewolfGameState>[], player_id: string | null, team: Team) {
    if (!player_id) {
        return [];
    }
    return games.filter((game) => {
        return getPlayerTeam(game.game_state, player_id) === team;
    });
}

function getBestRole(games: Game<WerewolfGameState>[], player_id: string | null) {
    if (!player_id) {
        return null;
    }
    const victoryGames = getWerewolfVictories(games, player_id);
    const roleWins: Partial<{ [role in Role]: number }> = {};

    if (victoryGames.length === 0) {
        return null;
    }

    victoryGames.forEach((game) => {
        const startingRoles = game.game_state.game.starting_roles;
        if (!startingRoles) {
            return;
        }
        const currentRoleWins = roleWins[startingRoles[player_id]];
        roleWins[startingRoles[player_id]] = currentRoleWins ? currentRoleWins + 1 : 1;
    });
    const roleWinsInOrder = Object.entries<number | undefined>(roleWins).sort(
        (a, b) => (b[1] || 0) - (a[1] || 0)
    );

    return roleWinsInOrder[0] as [Role, number];
}

function getCongratulations(games: Game<WerewolfGameState>[], player_id: string | null) {
    if (!player_id) {
        return null;
    }

    const congrats = {
        best_lie: 0,
        most_helpful: 0,
    };

    games.forEach((game) => {
        const { mostHelpfulWinners, bestLieWinners } = getCongratulationsTally(game.game_state);
        congrats.most_helpful += mostHelpfulWinners.includes(player_id) ? 1 : 0;
        congrats.best_lie += bestLieWinners.includes(player_id) ? 1 : 0;
    });

    return congrats;
}

function getFavoriteAlphaWolfTarget(games: Game<WerewolfGameState>[], player_id: string | null) {
    if (!player_id) {
        return "N/A";
    }
    const alphaWolfTargets: { [player_id: string]: number } = {};
    games.forEach((game) => {
        if (!game.game_state.game.starting_roles) {
            return;
        }
        const playerRole = game.game_state.game.starting_roles[player_id];
        if (playerRole !== "alpha_wolf") {
            return;
        }

        const action = game.game_state.game.night_actions.alpha_wolf_target;
        if (!action) {
            return;
        }
        alphaWolfTargets[action] = alphaWolfTargets[action] ? alphaWolfTargets[action] + 1 : 1;
    });
    const favoriteTargetEntry =
        Object.entries(alphaWolfTargets).sort((a, b) => b[1] - a[1])[0] || [];

    return favoriteTargetEntry;
}

export function Stats(): JSX.Element {
    const player_id = usePlayerId();
    const playedGames = usePlayedGames(player_id);
    const werewolfPlayedGames = playedGames.filter((game) => game.game_type === "werewolf") as Game<
        WerewolfGameState
    >[];
    const completedGames = werewolfPlayedGames.filter(
        (game) => Object.values(game.game_state.game.votes).length === game.game_player_ids.length
    );
    const winningGames = getWerewolfVictories(completedGames, player_id);

    const bestRole = getBestRole(completedGames, player_id);

    return (
        <>
            <SuspenseWithPerf fallback={null} traceId={"stats"}>
                <AppHeader />
                <StatsWrapper>
                    <h1>Your statistics</h1>
                    <TableWrapper>
                        <Table basic="very" celled collapsing>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Stat</Table.HeaderCell>
                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <TableRow
                                    icon="🃏 "
                                    title="Games played"
                                    value={completedGames.length}
                                />
                                <TableRow
                                    icon="🏆 "
                                    title="Games won"
                                    value={winningGames.length}
                                />
                                <TableRow
                                    icon="🐺 "
                                    title="Wolf team wins"
                                    value={getGamesAsTeam(winningGames, player_id, "wolf").length}
                                />
                                <TableRow
                                    icon="🔪 "
                                    title="Tanner team wins"
                                    value={getGamesAsTeam(winningGames, player_id, "tanner").length}
                                />
                                <TableRow
                                    icon="🏡 "
                                    title="Villager team wins"
                                    value={
                                        getGamesAsTeam(winningGames, player_id, "villager").length
                                    }
                                />
                                <TableRow
                                    icon="🎖 "
                                    title="Best role"
                                    subtitle="Starting role"
                                    value={
                                        bestRole ? (
                                            <>
                                                <RoleText>
                                                    <RoleName role={bestRole[0]} />
                                                </RoleText>{" "}
                                                ({bestRole[1]})
                                            </>
                                        ) : (
                                            "N/A"
                                        )
                                    }
                                />
                                <TableRow
                                    icon="🎯 "
                                    title="Favorite alpha wolf target"
                                    value={
                                        getFavoriteAlphaWolfTarget(completedGames, player_id)[0] ? (
                                            <>
                                                <PlayerName
                                                    player_id={
                                                        getFavoriteAlphaWolfTarget(
                                                            completedGames,
                                                            player_id
                                                        )[0]
                                                    }
                                                />
                                                &nbsp;(
                                                {
                                                    getFavoriteAlphaWolfTarget(
                                                        completedGames,
                                                        player_id
                                                    )[1]
                                                }
                                                )
                                            </>
                                        ) : (
                                            "N/A"
                                        )
                                    }
                                />
                                <TableRow
                                    icon="👺 "
                                    title="Voted best lie"
                                    value={
                                        getCongratulations(completedGames, player_id)?.best_lie ||
                                        "N/A"
                                    }
                                />
                                <TableRow
                                    icon="🤩 "
                                    title="Voted most helpful"
                                    value={
                                        getCongratulations(completedGames, player_id)
                                            ?.most_helpful || "N/A"
                                    }
                                />
                            </Table.Body>
                        </Table>
                    </TableWrapper>
                </StatsWrapper>
            </SuspenseWithPerf>
        </>
    );
}
