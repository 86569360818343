import { useFunctions } from "reactfire";

export type ChangePlayerName = (player_id: string, player_name: string) => Promise<void>;

export function useChangePlayerName(): ChangePlayerName {
    const changePlayerName = useFunctions().httpsCallable("changeplayername");

    return async (player_id, player_name) => {
        await changePlayerName({
            player_id,
            player_name,
        });
    };
}
