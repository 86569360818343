import { RoleImpl } from "@dailygameslink/werewolf";
import { SeerAction } from "../actions/SeerAction";

export const Seer: RoleImpl = {
    role: "seer",
    order: 500,
    name: "Seer",
    actionComponent: SeerAction,
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    hasTarget: true,
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: ["seer_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["seer_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
