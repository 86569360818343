import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";
import Countdown from "react-countdown";
import styled from "styled-components";

import { usePerformAction } from "../../../utils/usePerformAction";
import { getGameEndTimestamp } from "../utils/getGameEndTimestamp";

const Time = styled.span`
    font-size: 2rem;
`;

const renderer = ({ minutes, seconds }: { minutes: number; seconds: number }) => {
    return (
        <Time>
            {minutes}:{String(seconds).padStart(2, "0")}
        </Time>
    );
};

export function DayPhaseTimer({
    state,
    room_id,
    player_id,
}: {
    state: WerewolfGameState;
    player_id: string;
    room_id: string;
}): JSX.Element | null {
    const endTimestamp = getGameEndTimestamp(state);
    const startTimestamp = state.game.start_timestamp;
    const performAction = usePerformAction();

    if (!startTimestamp) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                No startTimestamp
            </p>
        );
    }
    if (!endTimestamp) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                No endTimestamp
            </p>
        );
    }

    const timeRemaining = (endTimestamp - Date.now()) / 1000;

    if (timeRemaining < 0) {
        performAction({
            room_id,
            action: {
                game_type: "werewolf",
                actionName: "skip_to_vote",
                player_id,
                skip_to_vote: true,
            },
        });
        return null;
    }

    return (
        <Time>
            <Countdown
                onComplete={() => {
                    performAction({
                        room_id,
                        action: {
                            game_type: "werewolf",
                            actionName: "skip_to_vote",
                            player_id,
                            skip_to_vote: true,
                        },
                    });
                }}
                date={endTimestamp}
                renderer={renderer}
            />
        </Time>
    );
}
