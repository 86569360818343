import { useHistory } from "react-router-dom";
import { useFunctions } from "reactfire";

import { usePlayerId } from "./usePlayerId";

export type CreateRoom = () => Promise<void>;

export function useCreateRoom({ game_type }: { game_type: string }): CreateRoom {
    const createRoom = useFunctions().httpsCallable("createroom");
    const history = useHistory();
    const player_id = usePlayerId();

    return async () => {
        const result = window.confirm(`Are you sure you want to create a new ${game_type} room?`);
        if (result === true) {
            const { room_id } = (
                await createRoom({
                    game_type,
                    host_player_id: player_id,
                })
            ).data;

            history.push(`/room/${room_id}`);
        }
    };
}
