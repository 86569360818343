import { Role, WerewolfGameState } from "@dailygameslink/werewolf";

import { gameHasRole } from "./roles";
import { getRole } from "../roles";

export function isActionComplete(role: Role, state: WerewolfGameState): boolean {
    if (!gameHasRole(state, role)) {
        return true;
    }

    const dependencyRoles: Role[] = getRole(role).actionDependencies || [];

    return getRole(role).areNightActionsComplete(state) && dependencyRoles.every(role => isActionComplete(role, state));
}
