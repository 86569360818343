import * as React from "react";

import { Role, WerewolfGameState } from "@dailygameslink/werewolf";

import { AlphaWolfSecretInfo } from "./secret-info/AlphaWolfSecretInfo";
import { ApprenticeSeerSecretInfo } from "./secret-info/ApprenticeSeerSecretInfo";
import { DoppelgangerSecretInfo } from "./secret-info/DoppelgangerSecretInfo";
import { DrunkSecretInfo } from "./secret-info/DrunkSecretInfo";
import { EavesdropperSecretInfo } from "./secret-info/EavesdropperSecretInfo";
import { HexWolfSecretInfo } from "./secret-info/HexWolfSecretInfo";
import { InsomniacSecretInfo } from "./secret-info/InsomniacSecretInfo";
import { MasonSecretInfo } from "./secret-info/MasonSecretInfo";
import { MinionSecretInfo } from "./secret-info/MinionSecretInfo";
import { MysticWolfSecretInfo } from "./secret-info/MysticWolfSecretInfo";
import { ParanormalInvestigatorSecretInfo } from "./secret-info/ParanormalInvestigatorSecretInfo";
import { RobberSecretInfo } from "./secret-info/RobberSecretInfo";
import { SeerSecretInfo } from "./secret-info/SeerSecretInfo";
import { TroublemakerSecretInfo } from "./secret-info/TroublemakerSecretInfo";
import { WerewolfSecretInfo } from "./secret-info/WerewolfSecretInfo";
import { WitchSecretInfo } from "./secret-info/WitchSecretInfo";
import styled from "styled-components";
import { TelepathSecretInfo } from "./secret-info/TelepathSecretInfo";

const SecretInfoWrapper = styled.div`
    text-align: center;
    margin-top: calc(var(--grid) * 4);
    border: 1px solid #577f9a;
    padding: var(--grid);
    border-radius: var(--border-radius);
    min-width: var(--center-width);
    background: #01253c;
`;

export function SecretInfo({
    state,
    player_id,
}: {
    state: WerewolfGameState;
    player_id: string;
}): JSX.Element | null {
    const role = state.game.starting_roles && state.game.starting_roles[player_id];
    if (!role) {
        return <span>Unknown (⚠)</span>;
    }

    const roleSecretInfo: { [role in Role]: JSX.Element | null } = {
        alpha_wolf: <AlphaWolfSecretInfo state={state} />,
        hex_wolf: <HexWolfSecretInfo state={state} />,
        eavesdropper: <EavesdropperSecretInfo state={state} />,
        doppelganger: <DoppelgangerSecretInfo state={state} player_id={player_id} />,
        drunk: <DrunkSecretInfo state={state} />,
        insomniac: <InsomniacSecretInfo state={state} player_id={player_id} />,
        minion: <MinionSecretInfo state={state} />,
        mystic_wolf: <MysticWolfSecretInfo state={state} />,
        paranormal_investigator: <ParanormalInvestigatorSecretInfo state={state} />,
        robber: <RobberSecretInfo state={state} />,
        telepath: <TelepathSecretInfo state={state} />,
        apprentice_seer: <ApprenticeSeerSecretInfo state={state} />,
        seer: <SeerSecretInfo state={state} />,
        troublemaker: <TroublemakerSecretInfo state={state} />,
        villager: null,
        detective: null,
        werewolf: <WerewolfSecretInfo state={state} />,
        witch: <WitchSecretInfo state={state} />,
        mason: <MasonSecretInfo state={state} />,
        bodyguard: null,
        tanner: null,
        hunter: null,
    };

    return (
        <SecretInfoWrapper>
            <h3>During the night</h3>
            {roleSecretInfo[role] ? (
                roleSecretInfo[role]
            ) : (
                <p>You rested peacefully and didn&apos;t learn anything</p>
            )}
        </SecretInfoWrapper>
    );
}
