import { AlphaWolfAction } from "../actions/AlphaWolfAction";
import { HexWolfAction } from "../actions/HexWolfAction";
import { HexWolfDayAction } from "../actions/HexWolfDayAction";
import { RoleImpl } from "@dailygameslink/werewolf";
import { getGameHasLoneWolfAction } from "../utils/getGameHasLoneWolfAction";

export const HexWolf: RoleImpl = {
    role: "hex_wolf",
    order: 230,
    name: "Hex wolf",
    actionComponent: HexWolfAction,
    dayActionComponent: HexWolfDayAction,
    startingTeam: "wolf",
    isStartingWolf: true,
    getCurrentTeam: () => "wolf",
    isWolf: () => true,
    isMinion: () => false,
    // Players who see the other wolves must wait for the doppelganger, in case the doppelganger becomes a wolf
    actionDependencies: ["doppelganger"],
    allNightActions: ["lone_wolf_target"],
    allDayActions: ["silence_spell"],
    areNightActionsComplete: function (state) {
        console.log("getGameHasLoneWolfAction(state)", getGameHasLoneWolfAction(state));
        return getGameHasLoneWolfAction(state)
            ? state.game.night_actions["lone_wolf_target"] !== undefined
            : true;
    },
    voteEffect: () => "default",
    hasDayActions: () => true,
    opensChats: () => [],
};
