import React from "react";
import { Canvas, useThree } from "react-three-fiber";
import { SandboxGameState, Action } from "@dailygameslink/sandbox";

import { Camera } from "./Camera";
import { PlaymatPieces } from "./PlaymatPieces";

export function PlaymatCanvas({
    room_id,
    player_id,
    player_ids,
    playerName,
    state,
    appendAction,
}: {
    room_id: string;
    player_id: string;
    player_ids: string[];
    playerName: string;
    state: SandboxGameState;
    appendAction: (action: Action) => void;
}) {
    return (
        <Canvas colorManagement shadowMap style={{ height: "1200px" }}>
            <Camera />
            <ambientLight intensity={0.5} />
            <pointLight
                position={[50, 25, 100]}
                castShadow
                shadow-mapSize-height={512}
                shadow-mapSize-width={512}
            />
            <PlaymatPieces
                state={state}
                room_id={room_id}
                player_id={player_id}
                player_ids={player_ids}
                appendAction={appendAction}
            />
        </Canvas>
    );
}
