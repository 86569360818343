import * as React from "react";

import {
    AlphaWolfTarget,
    DrunkTarget,
    EavesdropperTarget,
    MysticWolfTarget,
    ParanormalInvestigatorTarget,
    RobberTarget,
    Role,
    SeerTarget,
    TelepathTarget,
    TroublemakerTarget,
    WerewolfGameState,
    WitchTarget,
} from "@dailygameslink/werewolf";

import { AlphaWolfSecretInfo } from "./AlphaWolfSecretInfo";
import { ApprenticeSeerSecretInfo } from "./ApprenticeSeerSecretInfo";
import { DrunkSecretInfo } from "./DrunkSecretInfo";
import { EavesdropperSecretInfo } from "./EavesdropperSecretInfo";
import { HexWolfSecretInfo } from "./HexWolfSecretInfo";
import { InsomniacSecretInfo } from "./InsomniacSecretInfo";
import { MasonSecretInfo } from "./MasonSecretInfo";
import { MinionSecretInfo } from "./MinionSecretInfo";
import { MysticWolfSecretInfo } from "./MysticWolfSecretInfo";
import { ParanormalInvestigatorSecretInfo } from "./ParanormalInvestigatorSecretInfo";
import { PlayerName } from "../../../../components/PlayerName";
import { RobberSecretInfo } from "./RobberSecretInfo";
import { RoleName } from "../RoleName";
import { SeerSecretInfo } from "./SeerSecretInfo";
import { TroublemakerSecretInfo } from "./TroublemakerSecretInfo";
import { WerewolfSecretInfo } from "./WerewolfSecretInfo";
import { WitchSecretInfo } from "./WitchSecretInfo";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { TelepathSecretInfo } from "./TelepathSecretInfo";

export function DoppelgangerSecretInfo({
    state,
    player_id,
}: {
    state: WerewolfGameState;
    player_id: string;
}): JSX.Element | null {
    const firstCardLayout = getCurrentCardLayout(state, "doppelganger_target");
    const secondCardLayout = getCurrentCardLayout(state, "doppelganger_second_target");
    const firstTarget = state.game.night_actions.doppelganger_target;
    const secondTarget = state.game.night_actions.doppelganger_second_target;

    if (!firstCardLayout || !secondCardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!firstTarget) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no target
            </p>
        );
    }

    const secondSecretInfo: {
        [role in Exclude<Role, "doppelganger">]: JSX.Element | null;
    } = {
        alpha_wolf: (
            <AlphaWolfSecretInfo state={state} targetOverride={secondTarget as AlphaWolfTarget} />
        ),
        detective: null,
        apprentice_seer: (
            <ApprenticeSeerSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as EavesdropperTarget}
            />
        ),
        eavesdropper: (
            <EavesdropperSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as EavesdropperTarget}
            />
        ),
        drunk: (
            <DrunkSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as DrunkTarget}
            />
        ),
        insomniac: <InsomniacSecretInfo state={state} player_id={player_id} />,
        minion: <MinionSecretInfo state={state} />,
        mystic_wolf: (
            <MysticWolfSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as MysticWolfTarget}
            />
        ),
        paranormal_investigator: (
            <ParanormalInvestigatorSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as ParanormalInvestigatorTarget}
            />
        ),
        robber: (
            <RobberSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as RobberTarget}
            />
        ),
        telepath: (
            <TelepathSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as TelepathTarget}
            />
        ),
        seer: (
            <SeerSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as SeerTarget}
            />
        ),
        troublemaker: (
            <TroublemakerSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as TroublemakerTarget}
            />
        ),
        mason: <MasonSecretInfo state={state} />,
        villager: null,
        werewolf: <WerewolfSecretInfo state={state} />,
        hex_wolf: <HexWolfSecretInfo state={state} />,
        witch: (
            <WitchSecretInfo
                state={state}
                actionOverride="doppelganger_second_target"
                targetOverride={secondTarget as WitchTarget}
            />
        ),
        bodyguard: null,
        tanner: null,
        hunter: null,
    };

    const imitatedRole = firstCardLayout[firstTarget];

    if (imitatedRole === "doppelganger") {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Cannot doppelganger a doppelganger
            </p>
        );
    }

    return (
        <>
            <p>
                You looked at the card of <PlayerName player_id={firstTarget} />, which was a{" "}
                <RoleName role={imitatedRole} />.{" "}
            </p>
            <p>You then copied their role and joined their team.</p>
            {secondSecretInfo[imitatedRole]}
        </>
    );
}
