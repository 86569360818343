import * as React from "react";

import { LoneWolfAction } from "./LoneWolfAction";
import { WerewolfGameState } from "@dailygameslink/werewolf";
import { gameHasBottomCard } from "../utils/roles";
import { useActionReady } from "../hooks/useActionReady";

export function HexWolfAction({
    room_id,
    state,
    startingWolfPlayerIds,
}: {
    hasBottomCard: boolean;
    room_id: string;
    state: WerewolfGameState;
    startingWolfPlayerIds: string[];
}): JSX.Element | null {
    const isActionReady = useActionReady("hex_wolf", state);

    if (!isActionReady) {
        return <p>Waiting...</p>;
    }
    return (
        <>
            {startingWolfPlayerIds.length === 1 && (
                <LoneWolfAction
                    state={state}
                    hasBottomCard={gameHasBottomCard(state)}
                    room_id={room_id}
                />
            )}
        </>
    );
}
