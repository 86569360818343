import { ApprenticeSeerAction } from "../actions/ApprenticeSeerAction";
import { RoleImpl } from "@dailygameslink/werewolf";

export const ApprenticeSeer: RoleImpl = {
    role: "apprentice_seer",
    order: 510,
    actionComponent: ApprenticeSeerAction,
    name: "Apprentice seer",
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    hasTarget: true,
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: ["apprentice_seer_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["apprentice_seer_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
