import { RoleImpl } from "@dailygameslink/werewolf";

export const Villager: RoleImpl = {
    role: "villager",
    name: "Villager",
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: [],
    areNightActionsComplete: () => true,
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
