import React from "react";
import { SuspenseWithPerf } from "reactfire";
import styled from "styled-components";

import { Header } from "../components/Header";

const ChangelogWrapper = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    width: var(--center-width);
    margin: auto;
`;

export function Changelog(): JSX.Element | null {
    return (
        <>
            <SuspenseWithPerf fallback={null} traceId={"load-changelog"}>
                <Header />
                <ChangelogWrapper>
                    <h2>2020-09-09</h2>
                    <p>
                        <span role="img" aria-label="feature sparkle">
                            ✨
                        </span>{" "}
                        Feature: added a changelog!
                    </p>
                    <p>
                        <span role="img" aria-label="feature sparkle">
                            ✨
                        </span>{" "}
                        Feature: added &quot;Skip to results&quot; action for hosts in werewolf
                    </p>
                    <p>
                        <span role="img" aria-label="feature sparkle">
                            ✨
                        </span>{" "}
                        Feature: added &quot;Show waiting for players&quot; action for hosts in
                        werewolf during the night phase
                    </p>
                    <p>
                        <span role="img" aria-label="feature sparkle">
                            ✨
                        </span>{" "}
                        Feature: added border colors for different teams in the results screen
                    </p>
                    <p>
                        <span role="img" aria-label="feature sparkle">
                            🛠
                        </span>{" "}
                        Fix: fixed the bug where spectators always lost: now you see which team won
                        on the results screen.
                    </p>
                </ChangelogWrapper>
            </SuspenseWithPerf>
        </>
    );
}
