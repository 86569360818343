import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../components/PlayerName";
import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";
import { RoleName } from "../components/RoleName";
import { getRole } from "../roles";
import { getDoppelgangerRole } from "../utils/getDoppelgangerRole";

export function DoppelgangerAction({
    room_id,
    player_ids,
    startingWolfPlayerIds,
    player_id,
    state,
    hasBottomCard,
}: {
    startingWolfPlayerIds: string[];
    player_ids: string[];
    player_id: string;
    room_id: string;
    hasBottomCard: boolean;
    state: WerewolfGameState;
}): JSX.Element | null {
    const performAction = usePerformAction();
    const doppelgangerRole = getDoppelgangerRole(state);

    const secondActionProps: {
        room_id: string;
        player_id: string;
        player_ids: string[];
        actionName: "doppelganger_second_target";
    } = {
        room_id,
        player_id,
        player_ids,
        actionName: "doppelganger_second_target",
    };

    const firstAction = (
        <SelectPlayer
            player_ids={player_ids}
            disabledPlayerIds={[player_id]}
            onConfirm={(player_ids) =>
                performAction({
                    room_id,
                    action: {
                        actionName: "doppelganger_target",
                        game_type: "werewolf",
                        target: player_ids[0],
                    },
                })
            }
        ></SelectPlayer>
    );

    const SecondAction = doppelgangerRole ? getRole(doppelgangerRole).actionComponent : null;
    const secondAction = SecondAction ? (
        <SecondAction
            {...secondActionProps}
            startingWolfPlayerIds={startingWolfPlayerIds}
            hasBottomCard={hasBottomCard}
            state={state}
            isActionReadyOverride
        />
    ) : null;

    return (
        <>
            <strong>
                <p>Choose a player to imitate</p>
                {!doppelgangerRole && firstAction}
                {doppelgangerRole && (
                    <p>
                        You saw{" "}
                        <PlayerName player_id={state.game.night_actions.doppelganger_target} /> had
                        a {<RoleName role={doppelgangerRole} />} card.
                    </p>
                )}
                {secondAction && (
                    <>
                        <hr></hr>
                        <h3>You can now perform their action:</h3>
                    </>
                )}
                {secondAction}
            </strong>
        </>
    );
}
