import { PlaymatCanvas } from "./visuals/PlaymatCanvas";
import { Actions } from "./visuals/gui/Actions";
import { Action, SandboxGameState } from "@dailygameslink/sandbox";
import { useRoom } from "../../utils/useRoom";
import { useFirestore } from "reactfire";
import firebase from "firebase";
import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "semantic-ui-react";
import styled from "styled-components";
import { StateEditor } from "./visuals/gui/StateEditor";
import { usePlayerName } from "../../utils/usePlayerName";

const AccordionTitle = styled.span`
    color: white;
`;

export function Game({
    player_id,
    player_ids,
    room_id,
    state,
}: {
    player_id: string;
    player_ids: string[];
    room_id: string;
    state: SandboxGameState;
}) {
    const room = useRoom(room_id);
    const firestore = useFirestore();
    const playerName = usePlayerName(player_id);

    const [gameStateOpen, setGameStateOpen] = useState(false);

    const [pendingActions, setPendingActions] = useState<Action[]>([]);

    async function appendActionRemotely(action: Action) {
        const game = await firestore
            .collection("games")
            .where("game_id", "==", room.game_id)
            .limit(1)
            .get();
        await game.docs[0].ref.update({
            "game_state.game.actions": firebase.firestore.FieldValue.arrayUnion({
                timestamp: Date.now(),
                ...action,
            }),
        });
        setPendingActions((prevPendingActions) =>
            prevPendingActions.filter((prevPendingAction) => prevPendingAction !== action)
        );
    }

    function appendAction(action: Action) {
        setPendingActions((prevPendingActions) => prevPendingActions.concat(action));

        appendActionRemotely(action);
    }

    const localState = {
        ...state,
        game: {
            ...state.game,
            actions: [...state.game.actions, ...pendingActions],
        },
    };

    return (
        <>
            <Accordion>
                <Accordion.Title
                    active={gameStateOpen}
                    index={1}
                    onClick={() => {
                        setGameStateOpen((prevState) => !prevState);
                    }}
                >
                    <AccordionTitle>&gt; Game state</AccordionTitle>
                </Accordion.Title>
                <Accordion.Content active={gameStateOpen}>
                    <StateEditor state={localState}></StateEditor>
                </Accordion.Content>
            </Accordion>
            <PlaymatCanvas
                player_id={player_id}
                player_ids={player_ids}
                playerName={playerName || "⚠️ Unknown"}
                room_id={room_id}
                state={localState}
                appendAction={appendAction}
            />
            <Actions
                player_id={player_id}
                room_id={room_id}
                state={localState}
                appendAction={appendAction}
            />
        </>
    );
}
