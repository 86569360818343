import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerNameList } from "../../../components/PlayerNameList";
import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";
import { useActionReady } from "../hooks/useActionReady";

import { LoneWolfAction } from "./LoneWolfAction";

export function AlphaWolfAction({
    room_id,
    player_ids,
    startingWolfPlayerIds,
    player_id,
    actionName,
    state,
    isActionReadyOverride = false,
}: {
    player_ids: string[];
    startingWolfPlayerIds: string[];
    player_id: string;
    room_id: string;
    actionName?: "doppelganger_second_target";
    state: WerewolfGameState;
    isActionReadyOverride?: boolean;
}): JSX.Element | null {
    const performAction = usePerformAction();
    const isActionReady = useActionReady("alpha_wolf", state) || isActionReadyOverride;
    if (!isActionReady) {
        return <p>Waiting...</p>;
    }
    return (
        <>
            <PlayerNameList player_ids={startingWolfPlayerIds} title="Your fellow wolves" />
            {startingWolfPlayerIds.length === 1 && (
                <LoneWolfAction state={state} hasBottomCard={true} room_id={room_id} />
            )}
            {(startingWolfPlayerIds.length === 1 && state.game.night_actions.lone_wolf_target) ||
            startingWolfPlayerIds.length > 1 ? (
                <>
                    <p>Choose a player to give the bottom card to</p>
                    <SelectPlayer
                        player_ids={player_ids}
                        disabledPlayerIds={[player_id, ...startingWolfPlayerIds]}
                        onConfirm={(player_ids) =>
                            performAction({
                                room_id,
                                action: {
                                    actionName: actionName || "alpha_wolf_target",
                                    game_type: "werewolf",
                                    target: player_ids[0],
                                },
                            })
                        }
                    ></SelectPlayer>
                </>
            ) : null}
        </>
    );
}
