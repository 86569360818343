import React from "react";
import { SuspenseWithPerf } from "reactfire";
import styled from "styled-components";

import { Header } from "../components/Header";
import { CreateGame } from "../sections/CreateGame";
import { GameLobby } from "../sections/GameLobby";

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 769px) {
        flex-direction: column;
    }
`;

export function Home(): JSX.Element | null {
    return (
        <>
            <SuspenseWithPerf fallback={null} traceId={"load-home-screen"}>
                <Header />
                <HomeWrapper>
                    <CreateGame game_type="werewolf" title="One night ultimate werewolf" />
                    <CreateGame game_type="sandbox" title="Sandbox" />
                    <GameLobby />
                </HomeWrapper>
            </SuspenseWithPerf>
        </>
    );
}
