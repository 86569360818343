import * as React from "react";

import { Card } from "./components/cards/Card";
import { DayPhaseTimer } from "./components/DayPhaseTimer";
import ReactJson from "react-json-view";
import { Results } from "./components/Results";
import { RoleName } from "./components/RoleName";
import { RolesAndPlayers } from "./components/RolesAndPlayers";
import { SecretInfo } from "./components/SecretInfo";
import { SkipToVoteAction } from "./actions/SkipToVoteAction";
import { SpellEffectDisplay } from "./components/SpellEffectDisplay";
import { VoteAction } from "./actions/VoteAction";
import { WerewolfGameState } from "@dailygameslink/werewolf";
import { WerewolfHostControls } from "./components/WerewolfHostControls";
import { getRole } from "./roles";
import { getRolesInGame } from "./utils/roles";
import { getStartingWolfPlayerIds } from "./utils/getStartingWolfPlayerIds";
import { isActionComplete } from "./utils/isActionComplete";
import { isPlayerSpectator } from "./utils/isPlayerSpectator";
import styled from "styled-components";
import { useRoom } from "../../utils/useRoom";
import { Chats } from "./components/Chats";

const Title = styled.h1`
    text-align: center;
`;

const PhaseTitle = styled.h2`
    text-align: center;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const DayWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const DayActionWrapper = styled.div`
    text-align: center;
    margin-top: calc(var(--grid) * 4);
    padding: var(--grid);
    border-radius: var(--border-radius);
    min-width: var(--center-width);
`;

export function Game({
    state,
    room_id,
    player_id,
    player_ids,
}: {
    state: WerewolfGameState;
    room_id: string;
    player_id: string;
    player_ids: string[];
}): JSX.Element | null {
    const actionProps = {
        player_id,
        player_ids,
        room_id,
    };
    const role = state.game.starting_roles ? state.game.starting_roles[player_id] : null;
    const room = useRoom(room_id);
    const startingWolfPlayerIds = getStartingWolfPlayerIds(state);
    const hasBottomCard = !!getRolesInGame(state)?.some((role) => getRole(role).createsBottomCard);

    if (startingWolfPlayerIds === null) {
        // We haven't yet assigned starting roles.
        return null;
    }

    const heading = (
        <>
            {!isPlayerSpectator(state, player_id) ? (
                <Title>
                    You were a <RoleName role={role} />!
                </Title>
            ) : (
                <Title>
                    You are a Spectator{" "}
                    <span role="img" aria-label="Do spectator's wear masks?">
                        🎭
                    </span>
                </Title>
            )}

            {role && <Card role={role} reveal={500} />}
        </>
    );

    const controls = (
        <>
            {window.location.hash === "#debug" && (
                <ReactJson theme="solarized" collapsed src={state} />
            )}
            {player_id === room.host_player_id && (
                <WerewolfHostControls room_id={room_id} state={state} />
            )}
        </>
    );

    if (state.game.phase === "night_action") {
        const hasCompletedAction = role !== null && isActionComplete(role, state);
        const Action = role ? getRole(role).actionComponent : null;
        const action = Action ? (
            <Action
                {...actionProps}
                startingWolfPlayerIds={startingWolfPlayerIds}
                hasBottomCard={hasBottomCard}
                state={state}
            />
        ) : null;
        return (
            <>
                {controls}
                {heading}
                <PhaseTitle>
                    <span role="img" aria-label="Moon">
                        🌙
                    </span>{" "}
                    Night time!
                </PhaseTitle>
                <CenterWrapper>
                    {hasCompletedAction && "You're done. Waiting for other players..."}
                    {!hasCompletedAction && action}
                </CenterWrapper>
            </>
        );
    } else if (state.game.phase === "day") {
        const DayAction = role ? getRole(role).dayActionComponent : null;
        const dayAction = DayAction ? (
            <DayAction
                {...actionProps}
                startingWolfPlayerIds={startingWolfPlayerIds}
                hasBottomCard={hasBottomCard}
                state={state}
            />
        ) : null;
        return (
            <>
                {controls}
                {heading}
                <PhaseTitle>
                    <span role="img" aria-label="Sun">
                        ☀️
                    </span>{" "}
                    Day time!
                </PhaseTitle>
                <CenterWrapper>
                    <DayPhaseTimer state={state} player_id={player_id} room_id={room_id} />
                    <SpellEffectDisplay state={state} player_id={player_id} />
                    <DayWrapper>
                        {role && <SecretInfo state={state} player_id={player_id} />}
                        {role && getRole(role).hasDayActions(state) && (
                            <DayActionWrapper>{dayAction}</DayActionWrapper>
                        )}
                        <Chats player_id={player_id} state={state} game_id={room.game_id} />
                    </DayWrapper>
                    <RolesAndPlayers state={state} room_id={room_id} />
                    {role && (
                        <SkipToVoteAction
                            voteSkips={state.game.skip_to_vote}
                            player_id={player_id}
                            player_ids={player_ids}
                            room_id={room_id}
                        />
                    )}
                </CenterWrapper>
            </>
        );
    } else if (state.game.phase === "voting") {
        return (
            <>
                {controls}
                <PhaseTitle>🗳 Voting time! No more talking!</PhaseTitle>
                <CenterWrapper>
                    {role && (
                        <VoteAction
                            player_id={player_id}
                            player_ids={player_ids}
                            room_id={room_id}
                        ></VoteAction>
                    )}
                    {!role && (
                        <p>
                            You&apos;re a Spectator{" "}
                            <span role="img" aria-label="Do spectator's wear masks?">
                                🎭
                            </span>
                            , no voting for you. Waiting for the votes...
                        </p>
                    )}
                </CenterWrapper>
            </>
        );
    } else if (state.game.phase === "results") {
        return (
            <>
                {controls}
                <PhaseTitle>Results</PhaseTitle>
                <CenterWrapper>
                    <Results
                        state={state}
                        player_id={player_id}
                        player_ids={player_ids}
                        room_id={room_id}
                    />
                </CenterWrapper>
            </>
        );
    }

    return (
        <>
            {controls}

            <PhaseTitle>{`⚠️ state.game.phase`}</PhaseTitle>
        </>
    );
}
