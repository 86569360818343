import { RoleImpl, WitchTarget } from "@dailygameslink/werewolf";

import { WitchAction } from "../actions/WitchAction";
import { makeCardSwap } from "./utils";

export const Witch: RoleImpl = {
    role: "witch",
    order: 610,
    name: "Witch",
    actionComponent: WitchAction,
    startingTeam: "villager",
    getCurrentTeam: () => "wolf",
    isWolf: () => false,
    isMinion: () => false,
    // Witch only needs to wait for roles that can swap out middle cards, which is the doppelganger, alpha wolf and mystic wolf
    actionDependencies: ["doppelganger", "alpha_wolf", "werewolf", "mystic_wolf"],
    doAction: (_player_id, target, _nightAction, cardLayout) => {
        const newCardLayout = { ...cardLayout };
        const swap = makeCardSwap(newCardLayout);

        const witchTarget = target as WitchTarget;
        if (witchTarget && witchTarget[0] && witchTarget[1]) {
            swap(witchTarget[0], witchTarget[1]);
        }

        return newCardLayout;
    },
    allNightActions: ["witch_target"],
    areNightActionsComplete: function (state) {
        const nightAction = state.game.night_actions["witch_target"];
        return (
            nightAction === null || // Null represents a nobody target
            (nightAction !== undefined && nightAction[1] !== "undecided")
        );
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
