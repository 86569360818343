import { WerewolfGameState, CardLayout } from "@dailygameslink/werewolf";
import React from "react";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

import { PlayerName } from "../../../../components/PlayerName";
import { usePlayerId } from "../../../../utils/usePlayerId";
import { getRole } from "../../roles";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { getRoleTeam } from "../../utils/getRoleTeam";
import { getRolesInGame } from "../../utils/roles";
import { CongratulatePlayer } from "../CongratulatePlayer";
import { Congratulations } from "../Congratulations";

import { Card } from "./Card";

const CardGroupTitle = styled.h4`
    text-align: center;
`;

const PlayerCardWrapper = styled.div`
    display: flex;
    max-width: calc(var(--center-width) * 1.5);
    justify-content: space-around;
    flex-wrap: wrap;
`;

const TopCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    max-width: calc(var(--center-width) * 1.5);
`;

const TopCardWithMargin = styled.div`
    margin: 0 var(--grid);
`;

const CardWrapper = styled.div`
    margin: 0 calc(var(--grid));
    margin-bottom: calc(var(--grid) * 2);
    cursor: pointer;
`;

export function TableLayout({
    room_id,
    state,
}: {
    room_id: string;
    state: WerewolfGameState;
}): JSX.Element | null {
    const playerCards: JSX.Element[] = [];
    const cardLayout = getCurrentCardLayout(state);
    const player_id = usePlayerId();

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                No card layout
            </p>
        );
    }
    const topCardSet = new Set<keyof CardLayout>(["left", "middle", "right", "bottom"]);

    const playerCardEntries = Object.entries(cardLayout).filter(
        ([position, _role]) => !topCardSet.has(position)
    );
    playerCardEntries.sort().forEach(([position, role], i) => {
        playerCards.push(
            <Modal
                closeIcon
                trigger={
                    <CardWrapper>
                        <Card
                            role={role}
                            team={getRoleTeam(state, role)}
                            reveal={i * 100 + 200}
                            key={i}
                            label={<PlayerName player_id={position} />}
                        />
                    </CardWrapper>
                }
                header="Congratulations!"
                content={
                    <CongratulatePlayer room_id={room_id} player_id={player_id} target={position} />
                }
                actions={[{ key: "done", content: "Ok!", positive: true }]}
            ></Modal>
        );
    });
    return (
        <>
            <CardGroupTitle>Center cards</CardGroupTitle>
            <TopCardWrapper>
                <TopCardWithMargin>
                    <Card
                        role={cardLayout.left}
                        team={getRoleTeam(state, cardLayout.left)}
                        reveal={(playerCardEntries.length + 1) * 100 + 200}
                    />
                </TopCardWithMargin>
                <TopCardWithMargin>
                    <Card
                        role={cardLayout.middle}
                        team={getRoleTeam(state, cardLayout.middle)}
                        reveal={(playerCardEntries.length + 2) * 100 + 200}
                    />
                </TopCardWithMargin>
                <TopCardWithMargin>
                    <Card
                        role={cardLayout.right}
                        team={getRoleTeam(state, cardLayout.right)}
                        reveal={(playerCardEntries.length + 3) * 100 + 200}
                    />
                </TopCardWithMargin>
            </TopCardWrapper>
            <TopCardWrapper>
                {getRolesInGame(state)?.some((role) => getRole(role).createsBottomCard) && (
                    <Card
                        role={cardLayout.bottom}
                        team={getRoleTeam(state, cardLayout.bottom)}
                        reveal={(playerCardEntries.length + 1) * 100 + 500}
                    />
                )}
            </TopCardWrapper>
            <hr></hr>
            <CardGroupTitle>Player cards</CardGroupTitle>
            <p>
                Click on a player to congratulate them{" "}
                <span role="img" aria-label="Stars">
                    ✨
                </span>
            </p>
            <Congratulations state={state} />
            <PlayerCardWrapper>{playerCards}</PlayerCardWrapper>
        </>
    );
}
