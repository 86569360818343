import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { PlayerName } from "./PlayerName";

const PlayerListItem = styled.li<{ width?: number; color?: PlayerColor }>`
    width: ${(props) => (props.width ? `${props.width}px` : "var(--center-width)")};
    overflow: hidden;
    white-space: nowrap;
    padding: var(--grid) var(--grid) var(--grid) calc(var(--grid) * 3);
    border-radius: var(--border-radius);
    margin-bottom: var(--grid);
    background-color: #06253c;
    color: #d4deec;
    border: ${(props) =>
        ({
            blue: "1px solid #577f9a",
            red: "2px dotted rgb(255, 95, 87)",
            yellow: "2px dashed rgb(199,151,0)",
        }[props.color || "blue"])};
    display: flex;
    justify-content: space-between;
`;

const NameOverflow = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export type PlayerColor = "red" | "yellow" | "blue";

export function DraggablePlayerName({
    draggable,
    player_id,
    index,
    width,
    pre,
    post,
    color,
}: {
    draggable?: boolean;
    player_id: string;
    index: number;
    width?: number;
    pre?(player_id: string): JSX.Element;
    post?(player_id: string): JSX.Element;
    color?(player_id: string): PlayerColor;
}): JSX.Element | null {
    return (
        <Draggable
            isDragDisabled={!draggable}
            draggableId={player_id}
            index={index}
            key={player_id}
        >
            {(provided) => (
                <PlayerListItem
                    width={width}
                    key={player_id}
                    ref={provided.innerRef}
                    color={color && color(player_id)}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                >
                    <NameOverflow>
                        {pre && pre(player_id)}
                        <PlayerName player_id={player_id} />
                    </NameOverflow>
                    {post && post(player_id)}
                </PlayerListItem>
            )}
        </Draggable>
    );
}
