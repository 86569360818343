import * as React from "react";
import { Game } from "@dailygameslink/shared-types";
import { WerewolfGameState } from "@dailygameslink/werewolf";
import firebase from "firebase";
import { useState, useEffect, useRef } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import styled from "styled-components";

const ChatWrapper = styled.div`
    text-align: left;
`;

const ChatUl = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    bottom: 0;
    height: 8rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`;

const ChatLi = styled.li<{ you: boolean }>`
    background: ${(props) => (props.you ? "transparent" : "rgba(255, 255, 255, 0.1)")};
    margin-bottom: 4px;
`;

export function PlayerChat({
    between,
    state,
    game_id,
    player_id,
}: {
    between: string[];
    state: WerewolfGameState;
    game_id: string;
    player_id: string;
}) {
    const [message, setMessage] = useState("");
    const chatId = between.sort().join(":");
    const chatLog = state.game.chats[chatId];
    const firestore = useFirestore();
    const gameRef = useFirestore().collection("games").where("game_id", "==", game_id).limit(1);
    const scrollToBottomEl = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const el = scrollToBottomEl.current;
        if (!el || !el.parentNode) {
            return;
        }
        (el.parentNode as HTMLUListElement).scrollTop = el.offsetTop;
    }, [scrollToBottomEl, chatLog]);
    const gameDocuments: (Game<WerewolfGameState> & {
        id: string;
    })[] = useFirestoreCollectionData(gameRef, { idField: "id" });
    const gameDocument = gameDocuments[0];
    if (!gameDocument) {
        return null;
    }
    return (
        <ChatWrapper>
            <h3>📬 You can privately chat with another player</h3>
            <ChatUl>
                {(chatLog || []).map((msg) => (
                    <ChatLi you={msg.from === player_id}>
                        {msg.from === player_id ? "You" : "Them"}: {msg.message}
                    </ChatLi>
                ))}
                <div ref={scrollToBottomEl}></div>
            </ChatUl>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    firestore
                        .collection("games")
                        .doc(gameDocument.id)
                        .update({
                            [`game_state.game.chats.${chatId}`]: firebase.firestore.FieldValue.arrayUnion(
                                {
                                    from: player_id,
                                    message,
                                    timestamp: Date.now(),
                                }
                            ),
                        });
                    setMessage("");
                }}
            >
                <input
                    type="text"
                    placeholder="Press enter to send"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></input>
                <button>Send</button>
            </form>
        </ChatWrapper>
    );
}
