import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";

export function VoteAction({
    player_id,
    player_ids,
    room_id,
}: {
    player_id: string;
    player_ids: string[];
    room_id: string;
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <>
            <SelectPlayer
                player_ids={player_ids}
                disabledPlayerIds={[player_id]}
                onConfirm={(player_ids) =>
                    performAction({
                        room_id,
                        action: {
                            player_id,
                            actionName: "vote",
                            game_type: "werewolf",
                            target: player_ids[0],
                        },
                    })
                }
            ></SelectPlayer>
        </>
    );
}
