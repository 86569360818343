import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getStartingWolfPlayerIds } from "../../utils/getStartingWolfPlayerIds";

import { LoneWolfSecretInfo } from "./LoneWolfSecretInfo";

export function WerewolfSecretInfo({ state }: { state: WerewolfGameState }): JSX.Element | null {
    const startingWolves = getStartingWolfPlayerIds(state);
    if (startingWolves === null) {
        return (
            <p>
                (
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: starting wolves are unknown)
            </p>
        );
    }

    // Sort ids: doesn't need to be alphabetical, just consistent, so that doppelganger isn't always at the bottom
    startingWolves.sort((a, b) => (a > b ? -1 : 1));

    const wolfNames = (
        <>
            The wolves were:
            {startingWolves.map((player_id) => (
                <p key={player_id}>
                    <PlayerName player_id={player_id} />
                </p>
            ))}
        </>
    );

    const secretInfo =
        startingWolves.length === 0 ? (
            <p>There are no wolves</p> //this is for when the minion is using the wolf secret info
        ) : (
            <>
                {(getStartingWolfPlayerIds(state)?.length || 0) > 1 && wolfNames}
                {getStartingWolfPlayerIds(state)?.length === 1 && (
                    <LoneWolfSecretInfo state={state} />
                )}
            </>
        );

    return secretInfo;
}
