import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getDoppelgangerRole } from "../../utils/getDoppelgangerRole";

export function MasonSecretInfo({ state }: { state: WerewolfGameState }): JSX.Element | null {
    if (!state.game.starting_roles) {
        return null;
    }
    const startingMasons = Object.entries(state.game.starting_roles)
        .filter(([_player_id, role]) => {
            return (
                role === "mason" ||
                (role === "doppelganger" && getDoppelgangerRole(state) === "mason")
            );
        })
        .map(([player_id, _role]) => player_id);

    // Sort ids: doesn't need to be alphabetical, just consistent, so that doppelganger isn't always at the bottom
    startingMasons.sort();

    return startingMasons.length > 1 ? (
        <>
            The masons were:
            {startingMasons.map((player_id) => (
                <p key={player_id}>
                    <PlayerName player_id={player_id} />
                </p>
            ))}
        </>
    ) : (
        <p>You were the only mason</p>
    );
}
