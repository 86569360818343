import { useFunctions } from "reactfire";

export type CreatePlayer = (player_name: string, player_id?: string) => Promise<string | void>;

export function useCreatePlayer(): CreatePlayer {
    const createPlayer = useFunctions().httpsCallable("createplayer");

    return async (player_name, player_id) => {
        await createPlayer({
            player_name,
            player_id,
        });

        return player_id;
    };
}
