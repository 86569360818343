import { Button } from "../../../../components/Button";
import React from "react";
import styled from "styled-components";
import { SandboxGameState, Action } from "@dailygameslink/sandbox";
import { constructPlayMat } from "../../playmat/constructPlayMat";

const ActionWrapper = styled.div`
    margin: 0 var(--grid);
    button {
        margin: 0 var(--grid);
    }
`;

export function Actions({
    room_id,
    player_id,
    state,
    appendAction,
}: {
    room_id: string;
    player_id: string;
    state: SandboxGameState;
    appendAction: (action: Action) => void;
}) {
    const playMat = constructPlayMat(state);
    const pilePosition = playMat.getPlayerInfo(player_id).selected;
    if (!pilePosition) {
        return null;
    }
    const selectedPile = playMat.getPile(pilePosition[0], pilePosition[1]);
    const topCard = selectedPile[selectedPile.length - 1];
    return (
        <ActionWrapper>
            <h2>Card actions</h2>
            <Button
                disabled={!topCard}
                onClick={() => {
                    appendAction({
                        type: "set-card",
                        params: {
                            id: topCard.id,
                            isFaceDown: !topCard.isFaceDown,
                        },
                    });
                }}
            >
                Flip top card
            </Button>
            <Button
                disabled={!topCard}
                onClick={() => {
                    appendAction({
                        type: "shuffle-pile",
                        params: {
                            position: JSON.stringify(pilePosition),
                            seed: Math.floor(Math.random() * 10000),
                        },
                    });
                }}
            >
                Shuffle pile
            </Button>
        </ActionWrapper>
    );
}
