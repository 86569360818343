import React from "react";
import { SandboxGameState, Action } from "@dailygameslink/sandbox";

import { PlayMat, constructPlayMat } from "../playmat/constructPlayMat";
import { Card } from "./Card";
import { Board } from "./Board";
import { Pile } from "./Pile";
import { Hand } from "./Hand";
import { CARD_DIMENSIONS, HAND_POSITION, HAND_DIMENSIONS, BOARD_SIZE } from "./constants";
import { getPlayerContext } from "../parsing/getPlayerContext";
import { PlayerContext } from "../parsing/parser";

const PADDING = [CARD_DIMENSIONS[0] / 2, CARD_DIMENSIONS[1] / 2, 0];

const OPPONENT_HAND_WIDTH = CARD_DIMENSIONS[0] * 2;

export type PilePosition = { index: [number, number]; position: [number, number, number] };

export function getCardPosition(x: number, y: number, z: number, playMat: PlayMat) {
    const offsetX = playMat.width / 2;
    const offsetY = playMat.height / 2;

    return [
        (x - offsetX) * (CARD_DIMENSIONS[0] + PADDING[0]),
        (y - offsetY) * (CARD_DIMENSIONS[1] + PADDING[1]) + HAND_DIMENSIONS[1],
        z * (CARD_DIMENSIONS[2] + PADDING[2]),
    ];
}

function getPilePositions(
    playMat: PlayMat,
    options?: {
        filter?: {
            editableOnly: true;
            playerContext: PlayerContext;
        };
    }
) {
    const pilePositions: PilePosition[] = [];
    for (let y = 0; y < playMat.height; y++) {
        for (let x = 0; x < playMat.width; x++) {
            if (options?.filter?.editableOnly) {
                if (!playMat.getIsPileEditable(x, y, options.filter.playerContext)) {
                    continue;
                }
            }
            const pilePosition = getCardPosition(x, y, 0, playMat);
            pilePositions.push({
                index: [x, y],
                position: [pilePosition[0], pilePosition[1], pilePosition[2]],
            });
        }
    }

    return pilePositions;
}

export function getClosestPile(
    x: number,
    y: number,
    playMat: PlayMat,
    options?: {
        filter?: {
            editableOnly: true;
            playerContext: PlayerContext;
        };
    }
) {
    const pilePositions = getPilePositions(playMat, options);
    return pilePositions
        .map(({ position: [pileX, pileY], index }) => ({
            index,
            distance: Math.sqrt(Math.pow(x - pileX, 2) + Math.pow(y - pileY, 2)),
        }))
        .reduce((a, b) => (a.distance < b.distance ? a : b));
}

export function PlaymatPieces({
    room_id,
    player_id,
    player_ids,
    playerName,
    state,
    appendAction,
}: {
    room_id: string;
    player_id: string;
    player_ids: string[];
    playerName?: string;
    state: SandboxGameState;
    appendAction: (action: Action) => void;
}) {
    const playMat = constructPlayMat(state);
    const pilePositions = getPilePositions(playMat);
    const allPlayersInfo = playMat.getAllPlayerInfos();
    const playerContext = getPlayerContext(player_id, player_ids, playerName);

    const grid = [];
    for (let x = 0; x < playMat.width; x++) {
        for (let y = 0; y < playMat.height; y++) {
            const cards = playMat.getPile(x, y);
            const pilePosition = getCardPosition(x, y, 0, playMat);
            const isSelected = !!allPlayersInfo.find(
                (playerInfo) => playerInfo.selected?.join(",") === [x, y].join(",")
            );

            const isPileEditable = playMat.getIsPileEditable(
                x,
                y,
                getPlayerContext(player_id, player_ids, playerName)
            );

            grid.push(
                <Pile
                    isSelected={isSelected}
                    isPileEditable={isPileEditable}
                    key={pilePosition.join(",")}
                    x={pilePosition[0]}
                    y={pilePosition[1]}
                    onClick={() => {
                        !isSelected &&
                            appendAction({
                                type: "set-player-info",
                                params: {
                                    player_id,
                                    selected: [x, y],
                                },
                            });
                    }}
                />
            );
            cards.forEach((card, k) => {
                const cardPosition = getCardPosition(x, y, k + 1, playMat);

                grid.push(
                    <Card
                        contentFront={card.cardContentFront}
                        frontTexture={card.frontTexture}
                        frontColor={card.frontColor}
                        backColor={card.backColor}
                        key={card.id}
                        id={card.id}
                        x={cardPosition[0]}
                        y={cardPosition[1]}
                        z={cardPosition[2]}
                        isFaceDown={card.isFaceDown}
                        draggable={k === cards.length - 1 && isPileEditable}
                        playMat={playMat}
                        onHandDrop={(id) => {
                            appendAction({
                                type: "set-card",
                                params: {
                                    id,
                                    position: {
                                        type: "player-hand",
                                        player_id,
                                    },
                                },
                            });
                        }}
                        onCardDrop={(id, x, y) => {
                            if (pilePositions.length === 0) {
                                return;
                            }

                            const closestPile = getClosestPile(x, y, playMat, {
                                filter: { editableOnly: true, playerContext },
                            });

                            appendAction({
                                type: "set-card",
                                params: {
                                    id,
                                    position: {
                                        type: "pile",
                                        index: closestPile.index,
                                    },
                                },
                            });
                        }}
                        onDoubleClick={
                            k === cards.length - 1
                                ? (id, x, y) => {
                                      appendAction({
                                          type: "set-card",
                                          params: {
                                              id,
                                              isFaceDown: !card.isFaceDown,
                                          },
                                      });
                                  }
                                : () => {}
                        }
                    />
                );
            });
        }
    }
    player_ids.sort().forEach((currentPlayerId, currentPlayerIndex) => {
        const hand = playMat.getHand(currentPlayerId);

        const isThisPlayersHand = player_id === currentPlayerId;

        console.log("hand", hand);
        hand.forEach((card, cardIndex) => {
            const cardPosition = isThisPlayersHand
                ? [
                      (cardIndex - playMat.getHand(player_id).length / 2) *
                          CARD_DIMENSIONS[0] *
                          1.25,
                      HAND_POSITION[1] + CARD_DIMENSIONS[1],
                      CARD_DIMENSIONS[2] + HAND_POSITION[2],
                  ]
                : [
                      BOARD_SIZE / 2 -
                          OPPONENT_HAND_WIDTH * 2 +
                          (cardIndex / hand.length) * OPPONENT_HAND_WIDTH,
                      getCardPosition(0, currentPlayerIndex, 0, playMat)[1],
                      CARD_DIMENSIONS[2] * cardIndex + HAND_POSITION[2],
                  ];

            grid.push(
                <Card
                    contentFront={card.cardContentFront}
                    frontTexture={card.frontTexture}
                    frontColor={card.frontColor}
                    backColor={card.backColor}
                    key={card.id}
                    id={card.id}
                    x={cardPosition[0]}
                    y={cardPosition[1]}
                    z={cardPosition[2]}
                    isFaceDown={!isThisPlayersHand}
                    draggable={isThisPlayersHand}
                    playMat={playMat}
                    onHandDrop={(id) => {
                        appendAction({
                            type: "set-card",
                            params: {
                                id,
                                position: {
                                    type: "player-hand",
                                    player_id,
                                },
                            },
                        });
                    }}
                    onCardDrop={(id, x, y) => {
                        if (pilePositions.length === 0) {
                            return;
                        }

                        const closestPile = getClosestPile(x, y, playMat, {
                            filter: { editableOnly: true, playerContext },
                        });

                        appendAction({
                            type: "set-card",
                            params: {
                                id,
                                position: {
                                    type: "pile",
                                    index: closestPile.index,
                                },
                            },
                        });
                    }}
                />
            );
        });
    });

    return (
        <>
            <Board />
            {grid}
            <Hand />
        </>
    );
}
