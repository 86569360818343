import { WerewolfGameState } from "@dailygameslink/werewolf";
import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "../components/Button";
import { isSetupValid } from "../utils/isSetupValid";
import { useGame } from "../utils/useGame";
import { usePerformAction } from "../utils/usePerformAction";
import { usePlayerId } from "../utils/usePlayerId";
import { useRoom } from "../utils/useRoom";

const Wrapper = styled.div`
    margin-top: calc(var(--grid) * 3);
    display: flex;
    justify-content: center;
`;

export function HostControls({ room_id }: { room_id: string }): JSX.Element | null {
    const room = useRoom(room_id);
    const game = useGame<WerewolfGameState>(room.game_id);
    const player_id = usePlayerId();
    const performAction = usePerformAction();

    const [isCreating, setIsCreating] = useState(false);

    if (player_id !== room.host_player_id) {
        return <Wrapper>Waiting for host.</Wrapper>;
    }

    const { valid: validSetup, invalidReason: invalidSetupReason } = isSetupValid(room, game);

    return (
        <Wrapper>
            <Button
                disabled={isCreating || !validSetup}
                onClick={async () => {
                    setIsCreating(true);
                    try {
                        await performAction({
                            room_id,
                            action: {
                                game_type: "werewolf",
                                actionName: "game_start",
                            },
                        });
                    } catch (e) {
                        alert("There was a problem starting the game");
                        console.error(e);
                    }

                    setIsCreating(false);
                }}
            >
                {isCreating
                    ? "Starting game"
                    : "Start game" + (invalidSetupReason ? ` (${invalidSetupReason})` : "")}
            </Button>
        </Wrapper>
    );
}
