import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";

export function EavesdropperAction({
    room_id,
    player_id,
    player_ids,
    actionName,
}: {
    player_ids: string[];
    player_id: string;
    room_id: string;
    actionName?: "doppelganger_second_target";
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <>
            <p>Choose a player&apos;s card to look at</p>
            <SelectPlayer
                player_ids={player_ids}
                disabledPlayerIds={[player_id]}
                onConfirm={(card_positions) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: actionName || "eavesdropper_target",
                            game_type: "werewolf",
                            target: card_positions[0],
                        },
                    })
                }
            ></SelectPlayer>
        </>
    );
}
