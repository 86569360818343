import { WerewolfGameState, NightAction, WitchTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function WitchSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: WitchTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, actionOverride || "witch_target");
    const target =
        targetOverride === undefined ? state.game.night_actions.witch_target : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    const middleCardTarget = target && target[0];

    if (!target || !target[0] || !middleCardTarget) {
        return <p>You chose to not perform your action</p>;
    }

    return (
        <>
            <p>
                You looked at the {middleCardTarget} card from the middle, which was a{" "}
                {<RoleName role={cardLayout[middleCardTarget]} />}{" "}
            </p>
            <p>
                You swapped that card with <PlayerName player_id={target[1]} />, giving it to them
            </p>
        </>
    );
}
