import React from "react";
import { HAND_POSITION, HAND_DIMENSIONS } from "./constants";

export function Hand() {
    return (
        <group position={HAND_POSITION as any}>
            <mesh receiveShadow>
                <boxBufferGeometry
                    args={[HAND_DIMENSIONS[0], HAND_DIMENSIONS[1], HAND_DIMENSIONS[2]]}
                />
                <meshPhysicalMaterial color={"grey"} />
            </mesh>
        </group>
    );
}
