import { useHistory } from "react-router-dom";
import { useFunctions } from "reactfire";

import { usePlayerId } from "./usePlayerId";

export type JoinRoom = (room_id: string) => Promise<void>;

export function useJoinRoom(): JoinRoom {
    const joinRoom = useFunctions().httpsCallable("joinroom");
    const history = useHistory();
    const player_id = usePlayerId();

    return async (room_id: string) => {
        await joinRoom({ room_id, player_id });
        history.push(`/room/${room_id}`);
    };
}
