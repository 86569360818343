import { Room } from "@dailygameslink/shared-types";
import React from "react";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import styled from "styled-components";

const GameLobbyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: var(--center-width);
    font-family: "Press Start 2P";
    font-size: 0.8rem;

    background: #dddddd;
    padding: 1em;
    width: 30rem;
`;

const GameRoomHeader = styled.div`
    display: flex;
    color: #000;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 16px;
    margin: 0;
    margin-top: 1em;
`;

const GameRooms = styled.div`
    display: flex;
    align-items: left;
    flex-direction: column;
    list-style: none;
    max-height: 400px;
    overflow: scroll;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.4);
    poadding: 2em;
`;

const GameRoom = styled(Link)`
    display: flex;
    color: #000;
    line-height: 1.2rem;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 16px;
    margin: 0 0 1px 0;

    :hover {
        background-color: rgba(120, 120, 255, 0.8);
        color: #fff;
    }
`;

const RoomID = styled.div`
    display: inline-block;
    flex: 1;
    max-width: 50%;
    text-align: left;
`;

const Players = styled.div`
    display: inline-block;
    flex: 2;
    max-width: 50%;
    text-align: center;
`;

const twelveHoursAgo = Date.now() - 1000 * 60 * 60 * 12;

export function GameLobby(): JSX.Element | null {
    const gameRoomsRef = useFirestore()
        .collection("rooms")
        .where("lobby_timestamp", ">", twelveHoursAgo);
    const gameRooms = useFirestoreCollectionData(gameRoomsRef, { startWithValue: [] }) as Room[];
    return (
        <GameLobbyWrapper>
            <GameRoomHeader>
                <RoomID>Room ID</RoomID>
                <Players># Players</Players>
            </GameRoomHeader>
            <GameRooms>
                {gameRooms.map((room) => (
                    <GameRoom key={room.room_id} to={`/room/${room.room_id}`}>
                        <RoomID>{room.room_id}</RoomID>
                        <Players>{room.player_ids.length}</Players>
                    </GameRoom>
                ))}
            </GameRooms>
        </GameLobbyWrapper>
    );
}
