import React, { useCallback, useEffect, useRef } from "react";
import { useFrame, useThree, PerspectiveCameraProps } from "react-three-fiber";
import { useGesture } from "react-use-gesture";
import { useSpring, config } from "@react-spring/core";
import { a } from "@react-spring/three";
import { PerspectiveCamera } from "three";

function clamp(x: number, lower: number, upper: number) {
    return Math.max(Math.min(x, upper), lower);
}

function Scroll(props: { domTarget: Window }) {
    const [{ y }, set] = useSpring(() => ({ y: 0, config: config.stiff }));
    useEffect(() => {
        props &&
            props.domTarget &&
            props.domTarget.addEventListener("keypress", (e) => {
                if (e.charCode === "s".charCodeAt(0)) {
                    set({ y: y.get() - 10 });
                }
                if (e.charCode === "w".charCodeAt(0)) {
                    set({ y: y.get() + 10 });
                }
            });
    });
    return [y];
}

export function Camera(props: PerspectiveCameraProps) {
    const ref = useRef<PerspectiveCamera>();
    const [y] = Scroll({ domTarget: window });

    const { setDefaultCamera } = useThree();
    // Make the camera known to the system
    useEffect(() => {
        const current = ref.current;
        if (!current) {
            return;
        }
        void setDefaultCamera(current);
    }, []);
    // Update it every frame
    useFrame(() => {
        const current = ref.current;
        if (!current) {
            return;
        }
        // current.lookAt(0, y.get(), 0);
        current.updateMatrixWorld();
    });
    return <a.perspectiveCamera ref={ref} {...props} position={[0, 0, 100]} position-y={y} />;
}
