import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";
import { RoleName } from "../components/RoleName";
import { SelectCard } from "../components/SelectCard";
import { useActionReady } from "../hooks/useActionReady";
import { getCurrentCardLayout } from "../utils/getCurrentCardLayout";

export function WitchAction({
    state,
    room_id,
    player_ids,
    hasBottomCard,
    actionName,
    isActionReadyOverride = false,
}: {
    state: WerewolfGameState;
    player_ids: string[];
    room_id: string;
    hasBottomCard: boolean;
    actionName?: "doppelganger_second_target";
    isActionReadyOverride?: boolean;
}): JSX.Element | null {
    const performAction = usePerformAction();
    const isActionReady = useActionReady("witch", state) || isActionReadyOverride;
    if (!isActionReady) {
        return <p>Waiting...</p>;
    }

    const target = state.game.night_actions[actionName || "witch_target"];
    const cardLayout = getCurrentCardLayout(state, actionName || "witch_target");
    const selectedCard = target && target[0];
    const selectedCardRole = selectedCard && cardLayout ? cardLayout[selectedCard] : null;
    const inspectedCardResult = (
        <p>
            The card in position {selectedCard} was <RoleName role={selectedCardRole} />
        </p>
    );
    return (
        <>
            <p>You may choose a card from the middle to check.</p>
            <p>If you do, you must give it to another player.</p>
            {!selectedCard && (
                <SelectCard
                    allowNoCard
                    canSelectTwo={false}
                    hasBottomCard={hasBottomCard}
                    onConfirm={(card_positions) => {
                        if (card_positions.length === 0) {
                            return performAction({
                                room_id,
                                action: {
                                    actionName: actionName || "witch_target",
                                    game_type: "werewolf",
                                    target: null,
                                },
                            });
                        }
                        const cardSelectedFromMiddle = card_positions[0];
                        return performAction({
                            room_id,
                            action: {
                                actionName: actionName || "witch_target",
                                game_type: "werewolf",
                                target: [cardSelectedFromMiddle, "undecided"],
                            },
                        });
                    }}
                ></SelectCard>
            )}
            {selectedCard && (
                <>
                    {inspectedCardResult}
                    <SelectPlayer
                        player_ids={player_ids}
                        disabledPlayerIds={[]}
                        onConfirm={(player_ids) =>
                            performAction({
                                room_id,
                                action: {
                                    actionName: actionName || "witch_target",
                                    game_type: "werewolf",
                                    target: [selectedCard, player_ids[0]],
                                },
                            })
                        }
                    ></SelectPlayer>
                </>
            )}
        </>
    );
}
