import { DetectiveDayAction } from "../actions/DetectiveDayAction";
import { RoleImpl } from "@dailygameslink/werewolf";

export const Detective: RoleImpl = {
    role: "detective",
    name: "Detective",
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: [],
    areNightActionsComplete: () => true,
    voteEffect: () => "default",
    hasDayActions: () => true,
    dayActionComponent: DetectiveDayAction,
    opensChats: () => [],
};
