import { SandboxGameState } from "@dailygameslink/sandbox";

export const loveLetter: SandboxGameState = {
    game: {
        actions: [
            {
                params: {
                    isFaceDown: true,
                    id: "guard-1",
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "1: Guard (5)",
                    cardDetails: "Guess a player's card",
                },
                type: "set-card",
            },
            {
                params: {
                    cardContentFront: "1: Guard (5)",
                    cardDetails: "Guess a player's card",
                    position: { type: "pile", index: [0, 0] },
                    isFaceDown: true,
                    id: "guard-2",
                },
                type: "set-card",
            },
            {
                params: {
                    cardContentFront: "1: Guard (5)",
                    cardDetails: "Guess a player's card",
                    id: "guard-3",
                    position: { type: "pile", index: [0, 0] },
                    isFaceDown: true,
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    isFaceDown: true,
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "1: Guard (5)",
                    cardDetails: "Guess a player's card",
                    id: "guard-4",
                },
            },
            {
                params: {
                    cardContentFront: "1: Guard (5)",
                    cardDetails: "Guess a player's card",
                    isFaceDown: true,
                    position: { type: "pile", index: [0, 0] },
                    id: "guard-5",
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "2: Priest (2)",
                    cardDetails: "Look at another player's card",
                    id: "priest-1",
                    isFaceDown: true,
                },
            },
            {
                type: "set-card",
                params: {
                    cardContentFront: "2: Priest (2)",
                    cardDetails: "Look at another player's card",
                    position: { type: "pile", index: [0, 0] },
                    isFaceDown: true,
                    id: "priest-2",
                },
            },
            {
                type: "set-card",
                params: {
                    id: "baron-1",
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "3: Baron (2)",
                    cardDetails: "Compare cards with another player: Highest wins.",
                    isFaceDown: true,
                },
            },
            {
                params: {
                    cardContentFront: "3: Baron (2)",
                    cardDetails: "Compare cards with another player: Highest wins.",
                    isFaceDown: true,
                    id: "baron-2",
                    position: { type: "pile", index: [0, 0] },
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    id: "handmaid-1",
                    isFaceDown: true,
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "4: Handmaid (2)",
                    cardDetails: "Protected for one round",
                },
            },
            {
                params: {
                    id: "handmaid-2",
                    isFaceDown: true,
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "4: Handmaid (2)",
                    cardDetails: "Protected for one round",
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    isFaceDown: true,
                    id: "prince-1",
                    cardContentFront: "5: Prince (2)",
                    cardDetails: "Force a player to discard",
                    position: { type: "pile", index: [0, 0] },
                },
            },
            {
                params: {
                    isFaceDown: true,
                    position: { type: "pile", index: [0, 0] },
                    id: "prince-2",
                    cardContentFront: "5: Prince (2)",
                    cardDetails: "Force a player to discard",
                },
                type: "set-card",
            },
            {
                type: "set-card",
                params: {
                    position: { type: "pile", index: [0, 0] },
                    id: "king-2",
                    isFaceDown: true,
                    cardContentFront: "6: King (1)",
                    cardDetails: "Swap with another player",
                },
            },
            {
                type: "set-card",
                params: {
                    isFaceDown: true,
                    cardContentFront: "7: Countess (1)",
                    cardDetails: "Must be played if you have a King or Princess",
                    position: { type: "pile", index: [0, 0] },
                    id: "countess-2",
                },
            },
            {
                params: {
                    id: "princess-2",
                    isFaceDown: true,
                    position: { type: "pile", index: [0, 0] },
                    cardContentFront: "8: ✨ Princess ✨ (1)",
                    cardDetails: "Lose if you discard",
                },
                type: "set-card",
            },
            {
                params: {
                    isFaceDown: true,
                    cardContentFront: "Placeholder",
                    id: "placeholder",
                    position: { type: "pile", index: [5, 7] },
                },
                type: "set-card",
            },
            {
                params: {
                    selected: [0, 1],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
                type: "set-player-info",
            },
            {
                type: "set-player-info",
                params: {
                    selected: [1, 1],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
            },
            {
                params: {
                    selected: [2, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
                type: "set-player-info",
            },
            {
                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [3, 1],
                },

                type: "set-player-info",
            },
            {
                type: "set-player-info",
                params: {
                    selected: [4, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
            },
            {
                params: {
                    selected: [4, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
                type: "set-player-info",
            },
            {
                type: "set-player-info",

                params: {
                    selected: [5, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
            },
            {
                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [0, 0],
                },

                type: "set-player-info",
            },
            {
                type: "set-player-info",

                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [1, 0],
                },
            },
            {
                type: "set-card",

                params: {
                    position: { type: "pile", index: [1, 0] },
                    id: "princess-2",
                },
            },
            {
                type: "set-player-info",

                params: {
                    selected: [1, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },
            },
            {
                type: "set-player-info",

                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [1, 0],
                },
            },
            {
                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [0, 0],
                },
                type: "set-player-info",
            },
            {
                params: {
                    position: { type: "pile", index: [0, 0] },
                    id: "princess-2",
                },
                type: "set-card",
            },
            {
                params: {
                    selected: [0, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },

                type: "set-player-info",
            },
            {
                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [0, 1],
                },
                type: "set-player-info",
            },
            {
                params: {
                    id: "princess-2",
                    position: { type: "pile", index: [0, 1] },
                },

                type: "set-card",
            },
            {
                type: "set-player-info",

                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [0, 1],
                },
            },
            {
                type: "set-player-info",
                params: {
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                    selected: [0, 1],
                },
            },
            {
                params: {
                    selected: [0, 0],
                    player_id: "IfLnPI1AKoeKOJGQ6d9HXFap9l12",
                },

                type: "set-player-info",
            },
            {
                params: {
                    id: "princess-2",
                    position: { type: "pile", index: [0, 0] },
                },
                type: "set-card",
            },
        ],
    },
    setup: {},
};
