import { Role, WerewolfGameState, WinCondition } from "@dailygameslink/werewolf";
import { getRole } from "../roles";
import { getCurrentCardLayout } from "./getCurrentCardLayout";

import { getKilledPlayers } from "./getKilledPlayers";
import { getPlayerTeam } from "./getPlayerTeam";
import { getRoleCurrentlyWithPlayer } from "./getRoleCurrentlyWithPlayer";

function isRoleKillableByVillagers(role: Role, state: WerewolfGameState) {
    return (getRole(role).getCurrentTeam(state) === 'wolf') && !getRole(role).isMinion(state);
}

export function getWinningPlayers(
    state: WerewolfGameState,
    player_ids: string[]
): [string[], WinCondition] | null | undefined {
    const killedPlayers = getKilledPlayers(state);
    const rolesKilled = killedPlayers.map((player_id) =>
        getRoleCurrentlyWithPlayer(state, player_id)
    );

    if (rolesKilled.some((role) => role === null)) {
        return undefined;
    }

    // Tanner victory: tanners win if they are killed
    if (rolesKilled.includes("tanner")) {
        return [
            killedPlayers.filter((player_id) => getPlayerTeam(state, player_id) === "tanner"),
            "tanner_victory",
        ];
    }

    const cardLayout = getCurrentCardLayout(state);
    const werewolfInGame = cardLayout && Object.keys(cardLayout).some(position => (
        isRoleKillableByVillagers(cardLayout[position], state) &&
        (position !== 'left' && position !== 'middle' && position !== 'right' && position !== 'bottom')
    ));

    if (werewolfInGame) {
        if (
            rolesKilled.some((role) => role && isRoleKillableByVillagers(role, state))
        ) {
            return [
                player_ids.filter((player_id) => getPlayerTeam(state, player_id) === "villager"),
                "villager_victory",
            ];
        }
        return [
            player_ids.filter((player_id) => getPlayerTeam(state, player_id) === "wolf"),
            "wolf_victory",
        ];
    }

    // Minion or villager only game: nobody dies
    if (killedPlayers.length === 0) {
        return [
            player_ids.filter((player_id) => getPlayerTeam(state, player_id) === "villager"),
            "villager_victory_no_kill",
        ];
    } else {
        // The villagers have lost as somebody has died (not a wolf since there are no wolves in the game)
        // The minion still only wins if they killed a non-minion. This does mean there are cases where nobody wins.
        const killedVillagers = killedPlayers.filter(
            (player_id) => getPlayerTeam(state, player_id) !== "wolf"
        );
        // The rule is:
        // > If no players are Werewolves, the Minion wins as long as one other player (not the Minion) dies.
        // We read it as:
        // > If no players are Werewolves, the Minion(s) win as long as one other player (not the Minion(s)) die.
        const minions = player_ids.filter((player_id) => getPlayerTeam(state, player_id) === "wolf");
        if (killedVillagers.length > 0 && minions.length > 0) {
            return [
                minions,
                "lone_minion_victory"
            ];
        } else if (minions.length === 0) {
            return [
                [], "no_victory"
            ]
        }
    }

    return [
        [],
        "no_victory"
    ]
}
