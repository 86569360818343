import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { useThree } from "react-three-fiber";
import { useGesture } from "react-use-gesture";
import { useSpring, animated, config } from "@react-spring/three";
import { getClosestPile } from "./PlaymatPieces";
import { PlayMat } from "../playmat/constructPlayMat";
import { useCardFrontTexture } from "./useCardFrontTexture";
import { CARD_DIMENSIONS, HAND_POSITION, handTopY } from "./constants";

export function Card({
    id,
    x,
    y,
    z,
    draggable,
    onCardDrop = () => {},
    onHandDrop = () => {},
    onDoubleClick = () => {},
    playMat,
    frontColor = "orange",
    backColor = "black",
    contentFront,
    frontTexture,
    isFaceDown,
}: {
    id: string;
    x: number;
    y: number;
    z: number;
    draggable?: boolean;
    onCardDrop?: (id: string, x: number, y: number) => void;
    onHandDrop?: (id: string) => void;
    onDoubleClick?: (id: string, x: number, y: number) => void;
    activeDragHeight?: number;
    playMat: PlayMat;
    frontColor?: string;
    backColor?: string;
    contentFront?: string;
    frontTexture?: string;
    isFaceDown?: boolean;
}) {
    // This reference will give us direct access to the mesh
    const mesh = useRef();
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false);
    const targetPosition = [x, y, z];
    const scale = [1, 1, 1];

    const { size, viewport, camera } = useThree();
    const aspect = size.width / viewport.width;
    const [{ position }, set] = useSpring(() => ({
        position: targetPosition,
        config: { ...config.stiff },
    }));

    useEffect(() => {
        set({
            position: targetPosition,
        });
    }, [targetPosition[0], targetPosition[1], targetPosition[2]]);

    const isDragging = useRef(false);

    const bind = useGesture({
        onDrag: (args) => {
            const { active, movement, memo = position.get() } = args;
            if (!draggable) {
                return;
            }
            const dragPosition = [
                targetPosition[0] + movement[0] / aspect,
                targetPosition[1] - movement[1] / aspect,
            ];
            const closestPile = getClosestPile(dragPosition[0], dragPosition[1], playMat);
            const closestPileHeight = playMat.getPile(closestPile.index[0], closestPile.index[1])
                .length;

            let draggingZ = 0;
            if (active) {
                draggingZ = (closestPileHeight + 5) * CARD_DIMENSIONS[2];
            }
            if (dragPosition[1] < handTopY) {
                draggingZ = HAND_POSITION[2] + 5 * CARD_DIMENSIONS[2];
            }

            set({
                position: [dragPosition[0], dragPosition[1], draggingZ],
            });
            if (active) {
                isDragging.current = true;
            }
            return memo;
        },
        onDragStart: () => {
            set({
                position: targetPosition,
            });
        },
        onDragEnd: () => {
            if (!draggable) {
                return;
            }

            if (position.get()[1] < handTopY) {
                onHandDrop(id);
                return;
            }

            if (isDragging.current) {
                onCardDrop(id, position.get()[0], position.get()[1]);
            }
            set({
                position: targetPosition,
            });
            isDragging.current = false;
        },
    });
    // Rotate mesh every frame, this is outside of React without overhead
    // useFrame(() => {
    //     const current = mesh.current as any;
    //     if (!current) {
    //         return;
    //     }
    //     current.rotation.y = current.rotation.y += 0.1;
    // });

    const texture = useCardFrontTexture(frontColor, CARD_DIMENSIONS[0], CARD_DIMENSIONS[1], {
        text: contentFront,
        texture: frontTexture,
    });

    const { rotation } = useSpring({
        rotation: [0, isFaceDown ? Math.PI : 0, 0],
    });

    const [lastClick, setLastClick] = useState(Date.now());

    return (
        <>
            <animated.mesh
                castShadow
                receiveShadow
                position={position as any} //Seems to be a bug in the ts types
                rotation={rotation as any}
                ref={mesh}
                scale={[scale[0], scale[1], scale[2]]}
                onClick={(e) => {
                    const now = Date.now();
                    if (now - lastClick < 300) {
                        onDoubleClick(id, x, y);
                    }
                    setLastClick(Date.now());
                }}
                onPointerOver={(e) => setHover(true)}
                onPointerOut={(e) => setHover(false)}
                {...bind()}
            >
                <boxBufferGeometry
                    args={[CARD_DIMENSIONS[0], CARD_DIMENSIONS[1], CARD_DIMENSIONS[2]]}
                />
                {/* Sides */}
                <meshPhysicalMaterial attachArray="material" color={frontColor} />
                <meshPhysicalMaterial attachArray="material" color={frontColor} />
                <meshPhysicalMaterial attachArray="material" color={frontColor} />
                <meshPhysicalMaterial attachArray="material" color={frontColor} />

                {/* Front face */}
                <meshBasicMaterial
                    attachArray="material"
                    color={texture ? "undefined" : frontColor}
                    map={texture}
                />
                {/* Back face */}
                <meshPhysicalMaterial attachArray="material" color={backColor} />
            </animated.mesh>
        </>
    );
}
