import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getStartingWolfPlayerIds } from "../../utils/getStartingWolfPlayerIds";

export function MinionSecretInfo({ state }: { state: WerewolfGameState }): JSX.Element | null {
    const startingWolves = getStartingWolfPlayerIds(state);
    if (startingWolves === null) {
        return (
            <p>
                (
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: starting wolves are unknown)
            </p>
        );
    }

    // Sort ids: doesn't need to be alphabetical, just consistent, so that doppelganger isn't always at the bottom
    startingWolves.sort();

    if (startingWolves.length === 0) {
        return <p>You saw no wolves – you were a lone minion. A lone minion wins only if another villager dies.</p>;
    }

    return (
        <>
            The wolves are:
            {startingWolves.map((player_id) => (
                <p key={player_id}>
                    <PlayerName player_id={player_id} />
                </p>
            ))}
        </>
    );
}
