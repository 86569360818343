import { Room } from "@dailygameslink/shared-types";
import React from "react";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import styled from "styled-components";

import { CreateRoom } from "./CreateRoom";

const GameLobbyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: var(--center-width);
    font-family: "Press Start 2P";
    font-size: 0.8rem;

    background: #dddddd;
    padding: 1em;
    width: 30rem;
    margin-bottom: calc(var(--grid) * 3);
`;

const GameHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
`;

const GameLogo = styled.img`
    flex: 0;
    width: 100px;
    height: 100px;
`;

const GameTitle = styled.div`
    font-size: 1.8rem;
    line-height: 2rem;
    padding: 0.2em;
    color: #000;
    font-family: "Press Start 2P";
    text-transform: uppercase;
    word-wrap: normal;
`;

export function CreateGame({
    game_type,
    title,
}: {
    game_type: "werewolf" | "sandbox";
    title: string;
}) {
    return (
        <GameLobbyWrapper>
            <GameHeader>
                <GameLogo
                    src={
                        { werewolf: "/werewolf/werewolf.png", sandbox: "/sandbox/sandbox.png" }[
                            game_type
                        ]
                    }
                />
                <GameTitle>{title}</GameTitle>
            </GameHeader>
            <CreateRoom game_type={game_type} />
        </GameLobbyWrapper>
    );
}
