import { Role, Team, WerewolfGameState, Target, NightAction } from "@dailygameslink/werewolf";
import { getRole } from "../roles";

/**
 * Todo: this can probably be refactored out
 * @param state
 * @param role
 * @param hideTeamChanges
 * @param targetOverride
 * @param nightActionOverride
 */
export function getRoleTeam(
    state: WerewolfGameState,
    role: Role,
    hideTeamChanges?: boolean,
    targetOverride?: Target,
    nightActionOverride?: NightAction
): Team | null {
    if (hideTeamChanges) {
        return getRole(role).startingTeam;
    }
    return getRole(role).getCurrentTeam(state, targetOverride, nightActionOverride);
}
