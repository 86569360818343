import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { WerewolfGameState } from "@dailygameslink/werewolf";
import { usePerformAction } from "../../../utils/usePerformAction";

export function HexWolfDayAction({
    room_id,
    state,
    startingWolfPlayerIds,
    player_ids,
    player_id,
}: {
    hasBottomCard: boolean;
    room_id: string;
    state: WerewolfGameState;
    startingWolfPlayerIds: string[];
    player_id: string;
    player_ids: string[];
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <div>
            <h3>Cast a spell</h3>
            <p>Choose a player to silence</p>
            <SelectPlayer
                player_ids={player_ids}
                disabledPlayerIds={[]}
                canChangeSelection={30000}
                onConfirm={(player_ids) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: "silence_spell",
                            game_type: "werewolf",
                            target: player_ids[0],
                            player_id,
                        },
                    })
                }
            ></SelectPlayer>
        </div>
    );
}
