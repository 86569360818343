import { DoppelgangerAction } from "../actions/DoppelgangerAction";
import { DoppelgangerDayAction } from "../actions/DoppelgangerDayAction";
import { RoleImpl, TelepathTarget } from "@dailygameslink/werewolf";
import { doesDoppelgangerHaveSecondAction } from "../utils/doppelganger";
import { getDoppelgangerRole } from "../utils/getDoppelgangerRole";
import { getRole } from ".";
import { getPlayerWithStartingRole } from "../utils/getPlayerWithStartingRole";

export const Doppelganger: RoleImpl = {
    role: "doppelganger",
    order: -700,
    name: "Doppelgänger",
    actionComponent: DoppelgangerAction,
    startingTeam: "villager",
    getCurrentTeam: (state) => {
        const doppelgangerRole = getDoppelgangerRole(state);
        if (!doppelgangerRole) {
            return "villager"; // If the doppelganger is taken from the middle it will be neutral, which we consider a villager team.
        }
        // Shouldn't recurse more than once as doppelganger can't target a doppelganger
        return getRole(doppelgangerRole).getCurrentTeam(
            state,
            state.game.night_actions.doppelganger_second_target,
            "doppelganger_second_target"
        );
    },
    isWolf: (state) => {
        const doppelgangerRole = getDoppelgangerRole(state);
        return !!(
            doppelgangerRole &&
            getRole(doppelgangerRole).isWolf(
                state,
                state.game.night_actions.doppelganger_second_target,
                "doppelganger_second_target"
            )
        );
    },
    isMinion: (state) => {
        const doppelgangerRole = getDoppelgangerRole(state);
        return !!(
            doppelgangerRole &&
            getRole(doppelgangerRole).isMinion(
                state,
                state.game.night_actions.doppelganger_second_target,
                "doppelganger_second_target"
            )
        );
    },
    changesTeams: true,
    hasTarget: true,
    doAction: (player_id, target, nightAction, cardLayout, state) => {
        if (nightAction === "doppelganger_target") {
            return cardLayout;
        }
        const doppelgangerRole = getDoppelgangerRole(state);
        if (!doppelgangerRole) {
            return cardLayout;
        }
        const doAction = getRole(doppelgangerRole).doAction;
        if (!doAction) {
            return cardLayout;
        }
        return doAction(player_id, target, nightAction, cardLayout, state);
    },
    voteEffect: (state) => {
        const doppelgangerRole = getDoppelgangerRole(state);
        if (!doppelgangerRole) {
            return "default";
        }
        const voteEffectFn = getRole(doppelgangerRole).voteEffect;
        if (!voteEffectFn) {
            return "default";
        }
        return voteEffectFn(state);
    },
    allNightActions: ["doppelganger_target", "doppelganger_second_target"],
    areNightActionsComplete: function (state) {
        if (!state.game.night_actions["doppelganger_target"]) {
            return false;
        }
        if (!doesDoppelgangerHaveSecondAction(state)) {
            return true;
        }
        const nightAction = state.game.night_actions["doppelganger_second_target"];
        return (
            nightAction === null || // Null represents a nobody target
            (nightAction !== undefined && nightAction[1] !== "undecided")
        );
    },
    dayActionComponent: DoppelgangerDayAction,
    hasDayActions: (state) => {
        const doppelgangerRole = getDoppelgangerRole(state);
        if (!doppelgangerRole) {
            return false;
        }
        return getRole(doppelgangerRole).hasDayActions(state);
    },
    opensChats: (state) => {
        if (getDoppelgangerRole(state) !== "telepath") {
            return [];
        }
        const doppelgangerTelepathSecondTarget = state.game.night_actions
            .doppelganger_second_target as TelepathTarget;
        const doppelgangerTelepath = getPlayerWithStartingRole(state, "doppelganger");
        return doppelgangerTelepath && doppelgangerTelepathSecondTarget
            ? [[doppelgangerTelepath, doppelgangerTelepathSecondTarget]]
            : [];
    },
};
