import * as React from "react";
import { useState } from "react";
import { useAuth } from "reactfire";
import { Dropdown, Modal, Input } from "semantic-ui-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useChangePlayerName } from "../utils/useChangePlayerName";
import { usePlayerId } from "../utils/usePlayerId";
import { usePlayerName } from "../utils/usePlayerName";

import { PlayerName } from "./PlayerName";
import { PreferencesComponent } from "./Preferences";
import { Preferences } from "@dailygameslink/shared-types";
import { useChangePreferences } from "../utils/useChangePreferences";
import { usePreferences } from "../utils/usePreferences";

export const AccountName = styled.div`
    text-align: center;
`;

const Wrapper = styled.div`
    display: flex;
    > * {
        margin-left: 1em;
    }
`;

const PreferencesWrapper = styled.div`
    margin: var(--grid);
    color: black;
`;

export function Account(): JSX.Element | null {
    const player_id = usePlayerId();
    const changeName = useChangePlayerName();
    const changePreferences = useChangePreferences();
    const playerName = usePlayerName(player_id);
    const preferences = usePreferences(player_id);
    const [changedPlayerName, setChangedPlayerName] = useState(playerName);
    const [changedPreferences, setPreferences] = useState(preferences);
    const auth = useAuth();
    if (!player_id) {
        return null;
    }

    return (
        <Wrapper>
            <AccountName>
                <PlayerName player_id={player_id} /> /
            </AccountName>
            <Dropdown text="options" direction="left">
                <Dropdown.Menu>
                    <Modal
                        closeIcon
                        trigger={<Dropdown.Item text="Preferences"></Dropdown.Item>}
                        header="Preferences"
                        content={
                            <PreferencesWrapper>
                            <h3>Change name</h3>
                            <Input
                                value={changedPlayerName}
                                onChange={(e) => setChangedPlayerName(e.target.value)}
                            />
                            <PreferencesComponent initialPreferences={preferences} onChange={(preferences: Preferences) => setPreferences(preferences)} />
                            </PreferencesWrapper>
                        }
                        actions={[{ key: "done", content: "Save", positive: true }]}
                        onActionClick={function () {
                            changedPlayerName && changeName(player_id, changedPlayerName);
                            changedPreferences && changePreferences(player_id, changedPreferences);
                        }}
                    ></Modal>
                    <Dropdown.Item as={Link} to="/stats" text="Statistics"></Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        text="Log Out"
                        onClick={async () => {
                            await auth.signOut();
                        }}
                        href="#"
                    ></Dropdown.Item>
                    <Dropdown.Item as={Link} to="/changelog" text="Changelog"></Dropdown.Item>
                    <Dropdown.Item
                        text="Give Feedback"
                        target="_blank"
                        href="https://forms.gle/kWATaSbY4CHjfzst5"
                    ></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Wrapper>
    );
}
