import React, { useEffect, useState } from "react";
import Confetti from "react-dom-confetti";

export function ConfettiText({ children }: { children: JSX.Element }): JSX.Element {
    const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "9px",
        perspective: "312px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };

    const [explode, setExplode] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setExplode(true);
        }, 500);
    });

    return (
        <>
            <Confetti active={explode} config={config}></Confetti>
            {children}
        </>
    );
}
