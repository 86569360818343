import { CardPosition } from "@dailygameslink/werewolf";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

import { Button } from "../../../components/Button";

const ButtonGroup = styled.div`
    display: flex;
    button {
        margin: 0 var(--grid);
    }
    margin-bottom: var(--grid);
    justify-content: center;
`;

export function SelectCard({
    canSelectTwo,
    hasBottomCard,
    onConfirm,
    allowNoCard = false,
    isConfirmed = false,
}: {
    canSelectTwo: boolean;
    hasBottomCard: boolean;
    allowNoCard?: boolean;
    isConfirmed?: boolean;
    onConfirm(cardPosition: CardPosition[]): Promise<void>;
}): JSX.Element | null {
    const [selected, setSelected] = useState<CardPosition[]>([]);
    const [confirmState, setConfirmState] = useState<"unconfirmed" | "confirming" | "confirmed">(
        "unconfirmed"
    );

    isConfirmed && confirmState !== "confirmed" && setConfirmState("confirmed");

    function onPress(cardPosition: CardPosition | null) {
        if (cardPosition === null) {
            setSelected([]);
            return;
        }
        if (!canSelectTwo) {
            setSelected([cardPosition]);
            return;
        }
        const wasSelected = selected.includes(cardPosition);
        if (wasSelected) {
            setSelected(selected.filter((card) => card !== cardPosition));
        } else {
            setSelected([...selected, cardPosition]);
        }
    }

    function isDisabled(cardPosition: CardPosition) {
        const isSelected = selected.includes(cardPosition);
        if (canSelectTwo) {
            return selected.length === 2 && !isSelected;
        } else {
            return isSelected;
        }
    }

    function isSelected(cardPosition: CardPosition | null) {
        if (cardPosition === null) {
            return selected.length === 0;
        }
        return selected.includes(cardPosition);
    }

    const isConfirmEnabled =
        ((canSelectTwo && selected.length === 2) ||
            (!canSelectTwo && selected.length === 1) ||
            (allowNoCard && selected.length === 0)) &&
        confirmState === "unconfirmed";

    return (
        <div>
            <ButtonGroup>
                <Button
                    selected={isSelected("left")}
                    disabled={isDisabled("left")}
                    onClick={() => onPress("left")}
                >
                    <span role="img" aria-label="Card">
                        🃏
                    </span>{" "}
                    Left
                </Button>
                <Button
                    selected={isSelected("middle")}
                    disabled={isDisabled("middle")}
                    onClick={() => onPress("middle")}
                >
                    <span role="img" aria-label="Card">
                        🃏
                    </span>{" "}
                    Middle
                </Button>
                <Button
                    selected={isSelected("right")}
                    disabled={isDisabled("right")}
                    onClick={() => onPress("right")}
                >
                    <span role="img" aria-label="Card">
                        🃏
                    </span>{" "}
                    Right
                </Button>
            </ButtonGroup>
            {hasBottomCard && (
                <ButtonGroup>
                    <Button
                        selected={isSelected("bottom")}
                        disabled={isDisabled("bottom")}
                        onClick={() => onPress("bottom")}
                    >
                        <span role="img" aria-label="Card">
                            🃏
                        </span>{" "}
                        Bottom
                    </Button>
                </ButtonGroup>
            )}
            {allowNoCard && (
                <ButtonGroup onClick={() => onPress(null)}>
                    <Button selected={isSelected(null)}>
                        <span role="img" aria-label="Skip">
                            🚫
                        </span>{" "}
                        Skip
                    </Button>
                </ButtonGroup>
            )}
            <ButtonGroup>
                <Button
                    disabled={!isConfirmEnabled}
                    onClick={async () => {
                        setConfirmState("confirming");
                        try {
                            await onConfirm(selected);
                        } catch (e) {
                            alert("There was a problem confirming the card, please try again");
                            console.error(e);
                            setConfirmState("unconfirmed");
                        } finally {
                            setConfirmState("confirmed");
                        }
                    }}
                >
                    {
                        {
                            unconfirmed: "Confirm",
                            confirming: "Confirming",
                            confirmed: "Confirmed",
                        }[confirmState]
                    }
                </Button>
            </ButtonGroup>
        </div>
    );
}
