import jexl from "jexl";

export type PlayerContext = {
    name: string;
    number: number;
};

type Attributes = {
    [key: string]: string;
};

export type PileContext = {
    attributes: Attributes;
};

export function parsePileExpression({
    expression,
    context,
}: {
    expression: string;
    context: {
        pile: PileContext;
        player: PlayerContext;
    };
}) {
    return jexl.evalSync(expression, context) as any;
}
