import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "../components/Button";
import { useCreateRoom } from "../utils/useCreateRoom";

const CreateButton = styled(Button)`
    padding: 30px;
    cursor: pointer;

    :hover {
        background-color: rgba(120, 120, 255, 0.8);
        color: #fff;
    }
    padding: 30px;
    cursor: pointer;
    font-family: "Press Start 2P";
    text-transform: uppercase;
`;

export function CreateRoom({ game_type }: { game_type: string }): JSX.Element | null {
    const createRoom = useCreateRoom({ game_type });

    const [isCreatingRoom, setIsCreatingRoom] = useState(false);

    return (
        <CreateButton
            disabled={isCreatingRoom}
            onClick={async () => {
                setIsCreatingRoom(true);
                try {
                    await createRoom();
                } catch (error) {
                    console.error(error);
                }
                setIsCreatingRoom(false);
            }}
        >
            Create a new game
        </CreateButton>
    );
}
