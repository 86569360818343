import { ParanormalInvestigatorAction } from "../actions/ParanormalInvestigatorAction";
import { NightAction, RoleImpl, Target, WerewolfGameState } from "@dailygameslink/werewolf";
import { getCurrentCardLayout } from "../utils/getCurrentCardLayout";
import { getRole } from ".";

function getLastInspectedRole(
    state: WerewolfGameState,
    targetOverride?: Target,
    nightActionOverride?: NightAction
) {
    const paranormalInvestigatorTarget =
        targetOverride || state.game.night_actions.paranormal_investigator_target;
    if (!paranormalInvestigatorTarget) {
        return null;
    }

    if (paranormalInvestigatorTarget === null) {
        return null;
    }

    const lastCheckedPlayerId = paranormalInvestigatorTarget[1] || paranormalInvestigatorTarget[0];

    const cardLayoutBeforeParanormalInvestigator = getCurrentCardLayout(
        state,
        nightActionOverride || "paranormal_investigator_target"
    );
    if (!cardLayoutBeforeParanormalInvestigator) {
        return null;
    }
    const lastCardInspected = cardLayoutBeforeParanormalInvestigator[lastCheckedPlayerId];

    return getRole(lastCardInspected);
}

export const ParanormalInvestigator: RoleImpl = {
    role: "paranormal_investigator",
    order: 520,
    name: "Paranormal investigator",
    actionComponent: ParanormalInvestigatorAction,
    startingTeam: "villager",
    getCurrentTeam: (state, targetOverride, nightActionOverride) => {
        const lastInspectedRole = getLastInspectedRole(state, targetOverride, nightActionOverride);
        return lastInspectedRole ? lastInspectedRole.startingTeam : "villager";
    },
    // The PI will be on the wolf team, but not actually wolf
    isWolf: () => false,
    isMinion: (state, targetOverride, nightActionOverride) => {
        const lastInspectedRole = getLastInspectedRole(state, targetOverride, nightActionOverride);
        return lastInspectedRole ? !!lastInspectedRole.isStartingMinion : false;
    },
    changesTeams: true,
    hasTarget: true,
    // PI needs to wait for all roles that could swap players positions that come before it.
    actionDependencies: ["doppelganger", "werewolf", "alpha_wolf", "mystic_wolf"],
    allNightActions: ["paranormal_investigator_target"],
    areNightActionsComplete: function (state) {
        const nightAction = state.game.night_actions["paranormal_investigator_target"];
        return (
            nightAction === null || // Null represents a nobody target
            (nightAction !== undefined && nightAction[1] !== "undecided")
        );
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
