import firebase from "firebase";
import React, { useState } from "react";
import { useAuth } from "reactfire";
import { Input } from "semantic-ui-react";
import styled from "styled-components";

import { Button } from "../components/Button";
import { useCreatePlayer } from "../utils/useCreatePlayer";

const Container = styled.div`
    min-width: var(--center-width);
    display: flex;
    justify-content: center;
`;

const ButtonWrapper = styled.div`
    margin: calc(var(--grid) * 2) 0 0 0;
`;

const randomNames = ["Zero Cool", "Acid burn", "Cereal Killer", "Lord Nikon", "The Phantom Phreak"];

export function CreatePlayer({ onPlayerCreated }: { onPlayerCreated(): void }) {
    const [playerName, setPlayerName] = useState("");
    const createPlayer = useCreatePlayer();

    const [isCreating, setIsCreating] = useState(false);
    const auth = useAuth();

    return (
        <>
            <p>You can change this later in the Options menu</p>
            <p>Log in with Google to save your profile and game history.</p>
            <Container>
                <Input
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    placeholder="Enter a player name"
                />
            </Container>
            <ButtonWrapper>
                <Button
                    disabled={isCreating}
                    onClick={async () => {
                        setIsCreating(true);
                        try {
                            const result = await auth.signInAnonymously();
                            await createPlayer(
                                playerName ||
                                    randomNames[Math.floor(Math.random() * randomNames.length)],
                                result.user?.uid
                            );
                            onPlayerCreated();
                        } catch (e) {
                            alert(
                                "There was some problem creating the player. Check the console for details. Try again later?"
                            );
                            console.error(e);
                        }
                        setIsCreating(false);
                    }}
                >
                    {isCreating ? "Logging in" : "Log in anonymously"}
                </Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button
                    disabled={isCreating}
                    onClick={async () => {
                        setIsCreating(true);
                        try {
                            const provider = new firebase.auth.GoogleAuthProvider();
                            const result = await auth.signInWithPopup(provider);
                            await createPlayer(
                                playerName ||
                                    result.user?.displayName ||
                                    randomNames[Math.floor(Math.random() * randomNames.length)],
                                result.user?.uid
                            ); //no-op if the user already existed, then it's just a log in
                            onPlayerCreated();
                        } catch (e) {
                            alert(
                                "There was some problem creating the player. Check the console for details. Try again later?"
                            );
                            console.error(e);
                        }
                        setIsCreating(false);
                    }}
                >
                    {isCreating ? "Logging in" : "Log in with Google"}
                </Button>
            </ButtonWrapper>
        </>
    );
}
