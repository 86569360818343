import { WerewolfGameState } from "@dailygameslink/werewolf";

export function isPlayerSpectator(
    state: WerewolfGameState,
    player_id: string | null
): boolean | null {
    if (!player_id) {
        return null;
    }
    const role = state.game.starting_roles ? state.game.starting_roles[player_id] : null;

    return !role;
}
