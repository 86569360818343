import { Preferences } from "@dailygameslink/shared-types";
import { useFirestore } from "reactfire";

export type ChangePreferences = (player_id: string, preferences: Preferences) => Promise<void>;

export function useChangePreferences(): ChangePreferences {
    const firestore = useFirestore();

    return async (player_id, preferences) => {
        return firestore.collection('players')
        .doc(player_id).set({
            preferences
        });
    };
}
