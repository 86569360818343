import { PlayerContext } from "./parser";
import { usePlayerName } from "../../../utils/usePlayerName";

export function getPlayerContext(
    player_id: string,
    player_ids: string[],
    playerName?: string
): PlayerContext {
    const number = player_ids.sort().indexOf(player_id);

    return { name: playerName || "⚠️ Unknown", number };
}
