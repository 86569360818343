import { WerewolfGameState } from "@dailygameslink/werewolf";
import { getRole } from "../roles";
import { getCurrentCardLayout } from "./getCurrentCardLayout";
import { getProtectedPlayers } from "./getProtectedPlayers";

export function getKilledPlayers(state: WerewolfGameState): string[] {
    const votes = state.game.votes;
    const cardLayout = getCurrentCardLayout(state);
    if (!cardLayout) {
        return [];
    }
    const tally: { [player_id: string]: number } = {};
    Object.entries(votes).forEach(([_player_id, vote]) => {
        tally[vote] = tally[vote] ? tally[vote] + 1 : 1;
    });

    const protectedPlayers = getProtectedPlayers(state);

    const unprotectedVotes = Object.fromEntries(
        Object.entries(tally).filter(
            ([player_id, _voteCount]) => !protectedPlayers.includes(player_id)
        )
    );
    const maxVotes = Math.max(...Object.values(unprotectedVotes));
    let votedKilledPlayers = Object.entries(unprotectedVotes)
        .filter(([_player_id, voteCount]) => voteCount === maxVotes)
        .filter(([_player_id, voteCount]) => voteCount > 1) // You need at least one vote to die by the group
        .map(([player_id, _voteCount]) => player_id);

    const killedPlayersSet = new Set(votedKilledPlayers);

    let newPlayerDeath = true;
    while (newPlayerDeath) {
        newPlayerDeath = false;
        killedPlayersSet.forEach((killedPlayer) => {
            if (getRole(cardLayout[killedPlayer]).voteEffect(state) === "hunts") {
                const playerVote = votes[killedPlayer];
                if (!killedPlayersSet.has(playerVote)) {
                    newPlayerDeath = true;
                }
                killedPlayersSet.add(votes[killedPlayer]);
            }
        });
    }

    return Array.from(killedPlayersSet);
}
