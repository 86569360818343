import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SuspenseWithPerf } from "reactfire";
import styled from "styled-components";

import { Header } from "../components/Header";
import { CreatePlayer } from "../sections/CreatePlayer";

type PlayerCreationParams = {
    continue: string;
};

const CreatePlayerWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: var(--center-width);
`;

export function PlayerCreation(): JSX.Element | null {
    const history = useHistory();
    const location = useLocation<PlayerCreationParams>();
    const cont = new URLSearchParams(location.search).get("continue") || "/";

    return (
        <>
            <SuspenseWithPerf fallback={null} traceId={"load-home-screen"}>
                <Header />
                <CreatePlayerWrapper>
                    <h1>Create player</h1>
                    <h2>Player name</h2>
                    <CreatePlayer
                        onPlayerCreated={() => {
                            history.replace(cont);
                        }}
                    />
                </CreatePlayerWrapper>
            </SuspenseWithPerf>
        </>
    );
}
