import { RoleImpl } from "@dailygameslink/werewolf";

export const Hunter: RoleImpl = {
    role: "hunter",
    name: "Hunter",
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    voteEffect: () => "hunts",
    hasTarget: false,
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: [],
    areNightActionsComplete: () => true,
    hasDayActions: () => false,
    opensChats: () => [],
};
