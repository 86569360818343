import * as React from "react";
import { CARD_DIMENSIONS } from "./constants";

function playerToColor(player_id: string) {
    var hash = 1;
    for (var i = 0; i < player_id.length; i++) {
        hash = player_id.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
}

export function Pile({
    x,
    y,
    isSelected,
    isPileEditable,
    onClick = () => {},
}: {
    x: number;
    y: number;
    isSelected?: boolean;
    isPileEditable?: boolean;
    onClick?: () => void;
}) {
    return (
        <>
            <mesh
                receiveShadow
                position={[x, y, 0]}
                scale={isSelected ? [1.1, 1.1, 1.1] : [1, 1, 1]}
                onClick={() => {
                    onClick();
                }}
            >
                <boxBufferGeometry
                    args={[CARD_DIMENSIONS[0], CARD_DIMENSIONS[1], CARD_DIMENSIONS[2]]}
                />
                {isPileEditable && <meshPhysicalMaterial color={isSelected ? "red" : "grey"} />}
            </mesh>
        </>
    );
}
