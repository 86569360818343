import { WerewolfGameState, AssignedRoles } from "@dailygameslink/werewolf";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import styled from "styled-components";

import { DragListenerContext } from "../../../components/DragContext";
import { PlayerNameList } from "../../../components/PlayerNameList";
import { usePerformAction } from "../../../utils/usePerformAction";
import { getNewAssignedRoles } from "../utils/getNewAssignedRoles";
import { isPlayerSpectator } from "../utils/isPlayerSpectator";

import { RoleList } from "./RoleList";
import { usePlayerId } from "../../../utils/usePlayerId";

const TwoColumn = styled.div`
    display: flex;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: var(--center-width);
    margin: calc(var(--grid) * 2) var(--grid);
`;

export function RolesAndPlayers({
    state,
    room_id,
}: {
    room_id: string;
    state: WerewolfGameState;
}): JSX.Element | null {
    const { setDragEndListener } = useContext(DragListenerContext);
    const performAction = usePerformAction();
    const player_id = usePlayerId();

    const [assignedRoles, setAssignedRoles] = useState<AssignedRoles>(state.game.assigned_roles);

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }
            const newAssignedRoles = getNewAssignedRoles(assignedRoles, {
                player_id: result.draggableId,
                destinationRole: result.destination.droppableId,
                destinationIndex: result.destination.index,
            });

            performAction({
                room_id,
                action: {
                    game_type: "werewolf",
                    actionName: "assign_role",
                    player_id: result.draggableId,
                    destinationRole: result.destination.droppableId,
                    destinationIndex: result.destination.index,
                },
            });

            setAssignedRoles(newAssignedRoles);
        },
        [assignedRoles, performAction, room_id, setAssignedRoles]
    );

    useEffect(() => {
        setAssignedRoles(state.game.assigned_roles);
    }, [state.game.assigned_roles]);

    useEffect(() => {
        setDragEndListener(onDragEnd);
    }, [assignedRoles]);

    const isSpectator = isPlayerSpectator(state, player_id);
    return (
        <TwoColumn>
            <Column>
                <RoleList wide droppable roles={state.setup.roles} assignedRoles={assignedRoles} />
            </Column>
            <Column>
                <h3>Players</h3>
                <PlayerNameList
                    width={116}
                    draggable={!isSpectator}
                    sort={false}
                    player_ids={assignedRoles.player_name_list}
                />
                {/*TODO: 120 - 2 * 2 = 116, which is CARD_WIDTH - 2 * BORDER_SIZE */}
            </Column>
        </TwoColumn>
    );
}
