import { WerewolfGameState, Role } from "@dailygameslink/werewolf";
import { getRole } from "../roles";

export function getDoppelgangerRole(state: WerewolfGameState): Role | null {
    const doppelgangerTarget = state.game.night_actions.doppelganger_target;
    if (!doppelgangerTarget || !state.game.starting_roles) {
        return null;
    }
    return state.game.starting_roles[doppelgangerTarget];
}

export function doesDoppelgangerHaveSecondAction(state: WerewolfGameState): boolean {
    const doppelgangerRole = getDoppelgangerRole(state);
    if (!doppelgangerRole) {
        return false;
    }
    return !!getRole(doppelgangerRole).hasTarget;
}
