import {
    WerewolfGameState,
    NightAction,
    ParanormalInvestigatorTarget,
} from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function ParanormalInvestigatorSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: ParanormalInvestigatorTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(
        state,
        actionOverride || "paranormal_investigator_target"
    );
    const target =
        targetOverride === undefined
            ? state.game.night_actions.paranormal_investigator_target
            : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return <p>You chose to not perform your action</p>;
    }

    return (
        <>
            <p>
                You first checked {<PlayerName player_id={target[0]} />} who was a{" "}
                <RoleName role={cardLayout[target[0]]} />
            </p>
            {target[1] && (
                <p>
                    You then checked {<PlayerName player_id={target[1]} />} who was a{" "}
                    <RoleName role={cardLayout[target[1]]} />
                </p>
            )}
            {!target[1] && <p>You then stopped.</p>}
        </>
    );
}
