import { WerewolfGameState } from "@dailygameslink/werewolf";

export type SpellEffect = "silence" | "none";

export function getSpellEffect(
    state: WerewolfGameState,
    player_id: string,
): SpellEffect {
    const isSilenced = Object.values(state.game.day_actions).some(dayAction => (
        dayAction.silence_spell === player_id
    ));

    if (isSilenced) {
        return "silence";
    }

    return "none";
}
