import { WerewolfGameState } from "@dailygameslink/werewolf";
import * as React from "react";

import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function InsomniacSecretInfo({
    state,
    player_id,
}: {
    state: WerewolfGameState;
    player_id: string;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state);
    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }

    return (
        <>
            <p>
                You woke up at the end of the night and saw that you had a{" "}
                <RoleName role={cardLayout[player_id]} /> card.
            </p>
        </>
    );
}
