import React from "react";
import {
    DragDropContext,
    DropResult,
    OnBeforeDragStartResponder,
    OnDragEndResponder,
    OnDragStartResponder,
    ResponderProvided,
} from "react-beautiful-dnd";

let dragEndListener: OnDragEndResponder | null = null;
let dragStartListener: OnDragStartResponder | null = null;
let beforeDragStartListener: OnBeforeDragStartResponder | null = null;

function setDragEndListener(f: OnDragEndResponder) {
    dragEndListener = f;
}

function setDragStartListener(f: OnDragStartResponder) {
    dragStartListener = f;
}

function setBeforeDragStartListener(f: OnBeforeDragStartResponder) {
    beforeDragStartListener = f;
}

export const DragListenerContext = React.createContext({
    setDragEndListener,
    setDragStartListener,
    setBeforeDragStartListener,
});

export function DndContext({ children }: { children: JSX.Element }): JSX.Element | null {
    return (
        <DragDropContext
            onDragEnd={(result, provided) => {
                dragEndListener && dragEndListener(result, provided);
            }}
            onDragStart={(result, provided) => {
                dragStartListener && dragStartListener(result, provided);
            }}
            onBeforeDragStart={(initial) => {
                beforeDragStartListener && beforeDragStartListener(initial);
            }}
        >
            {children}
        </DragDropContext>
    );
}
