import { Role, WerewolfGameState } from "@dailygameslink/werewolf";

import { Button } from "../../../components/Button";
import { Modal } from "semantic-ui-react";
import { PlayerNameList } from "../../../components/PlayerNameList";
import React from "react";
import { getPlayerWithStartingRole } from "../utils/getPlayerWithStartingRole";
import { isActionComplete } from "../utils/isActionComplete";
import styled from "styled-components";
import { usePerformAction } from "../../../utils/usePerformAction";

const HostControls = styled.div`
    display: flex;
    justify-content: flex-end;
`;

function getWaitingForPlayerIds(state: WerewolfGameState) {
    if (!state || !state.game.starting_roles) {
        return [];
    }

    const waitingForRoles = Object.values<Role>(state.game.starting_roles).filter(
        (role) => !isActionComplete(role, state)
    );
    const playerIds: string[] = [];
    waitingForRoles
        .map((role) => getPlayerWithStartingRole(state, role))
        .forEach((p) => p && playerIds.push(p));

    return playerIds;
}

export function WerewolfHostControls({
    state,
    room_id,
}: {
    state: WerewolfGameState;
    room_id: string;
}): JSX.Element {
    const performAction = usePerformAction();
    return (
        <HostControls>
            {state.game.phase === "voting" && (
                <Button
                    onClick={() =>
                        performAction({
                            room_id,
                            action: { actionName: "skip_to_results", game_type: "werewolf" },
                        })
                    }
                >
                    Skip to results
                </Button>
            )}
            {state.game.phase === "night_action" && (
                <Modal
                    closeIcon
                    trigger={<Button>Check waiting players</Button>}
                    header="Waiting for players:"
                    content={<PlayerNameList player_ids={getWaitingForPlayerIds(state)} />}
                    actions={[{ key: "done", content: "Close", positive: true }]}
                ></Modal>
            )}
        </HostControls>
    );
}
