import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import { DraggablePlayerName, PlayerColor } from "./DraggablePlayerName";

const List = styled.ul`
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    text-align: center;
    padding: 0;
`;

const PlayerListContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export function PlayerNameList({
    player_ids,
    sort = true,
    draggable,
    width,
    title,
    pre,
    post,
    color,
}: {
    title?: string;
    sort?: boolean;
    player_ids: string[];
    draggable?: boolean;
    width?: number;
    pre?(player_id: string): JSX.Element;
    post?(player_id: string): JSX.Element;
    color?(player_id: string): PlayerColor;
}): JSX.Element | null {
    return (
        <>
            {title && <p>{title}</p>}
            <Droppable droppableId="player_name_list">
                {(provided) => (
                    <PlayerListContainer ref={provided.innerRef} {...provided.droppableProps}>
                        <List>
                            {(sort ? player_ids.sort() : player_ids).map((player_id, i) => (
                                <DraggablePlayerName
                                    key={`player-${player_id}`}
                                    player_id={player_id}
                                    draggable={draggable}
                                    index={i}
                                    width={width}
                                    pre={pre}
                                    post={post}
                                    color={color}
                                />
                            ))}
                            {provided.placeholder}
                        </List>
                    </PlayerListContainer>
                )}
            </Droppable>
        </>
    );
}
