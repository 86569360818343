import { useEffect } from "react";

import { useJoinRoom } from "../utils/useJoinRoom";

export function AutoJoinRoom({ room_id }: { room_id: string }): JSX.Element | null {
    const joinRoom = useJoinRoom();
    useEffect(() => {
        joinRoom(room_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
