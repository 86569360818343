import { WerewolfGameState } from "@dailygameslink/werewolf";
import { SandboxGameState } from "@dailygameslink/sandbox";
import * as React from "react";
import { SuspenseWithPerf, useFirestore } from "reactfire";

import { useGame } from "../utils/useGame";
import { usePerformAction } from "../utils/usePerformAction";
import { usePlayerId } from "../utils/usePlayerId";
import { useRoom } from "../utils/useRoom";

import { Setup as WerewolfSetup } from "./werewolf/Setup";
import { Setup as SandboxSetup } from "./sandbox/Setup";

export function Setup({ room_id }: { room_id: string }): JSX.Element | null {
    const room = useRoom(room_id);
    const game = useGame(room.game_id);
    const firestore = useFirestore();
    const performAction = usePerformAction();
    const player_id = usePlayerId();

    if (game.game_type === "werewolf") {
        return (
            <SuspenseWithPerf fallback={null} traceId={"werewolf-setup"}>
                <WerewolfSetup
                    isHost={player_id === room.host_player_id}
                    player_ids={room.player_ids}
                    state={game.game_state as WerewolfGameState}
                    onSetupChange={(setup) => {
                        performAction({
                            room_id,
                            action: {
                                game_type: "werewolf",
                                actionName: "game_setup",
                                setup,
                            },
                        });
                    }}
                />
            </SuspenseWithPerf>
        );
    } else if (game.game_type === "sandbox") {
        async function updateState(state: SandboxGameState) {
            const game = await firestore
                .collection("games")
                .where("game_id", "==", room.game_id)
                .limit(1)
                .get();
            await game.docs[0].ref.update({
                game_state: state,
            });
        }

        return (
            <SuspenseWithPerf fallback={null} traceId={"sandbox-setup"}>
                <SandboxSetup
                    isHost={player_id === room.host_player_id}
                    player_ids={room.player_ids}
                    state={game.game_state as SandboxGameState}
                    onSetupChange={updateState}
                />
            </SuspenseWithPerf>
        );
    }

    return null;
}
