import * as React from "react";
import { SuspenseWithPerf } from "reactfire";

import { useGame } from "../utils/useGame";
import { useRoom } from "../utils/useRoom";

import { Rules as WerewolfRules } from "./werewolf/Rules";

export function Rules({ room_id }: { room_id: string }): JSX.Element | null {
    const room = useRoom(room_id);
    const game = useGame(room.game_id);

    if (game.game_type === "werewolf") {
        return (
            <SuspenseWithPerf fallback={null} traceId={"werewolf-setup"}>
                <WerewolfRules />
            </SuspenseWithPerf>
        );
    }

    return null;
}
