import { AlphaWolfTarget, NightAction, RoleImpl } from "@dailygameslink/werewolf";

import { AlphaWolfAction } from "../actions/AlphaWolfAction";
import { getGameHasLoneWolfAction } from "../utils/getGameHasLoneWolfAction";
import { makeCardSwap } from "./utils";

export const AlphaWolf: RoleImpl = {
    role: "alpha_wolf",
    order: 210,
    name: "Alpha wolf",
    actionComponent: AlphaWolfAction,
    startingTeam: "wolf",
    getCurrentTeam: () => "wolf",
    isStartingWolf: true,
    isWolf: () => true,
    isMinion: () => false,
    createsBottomCard: true,
    hasTarget: true,
    // Players who see the other wolves must wait for the doppelganger, in case the doppelganger becomes a wolf
    actionDependencies: ["doppelganger"],
    doAction: (_player_id, target, nightAction, cardLayout) => {
        if (nightAction === "lone_wolf_target") {
            return cardLayout;
        }
        const newCardLayout = { ...cardLayout };
        const swap = makeCardSwap(newCardLayout);

        const alphaWolfTarget = target as AlphaWolfTarget;
        if (alphaWolfTarget) {
            swap("bottom", alphaWolfTarget);
        }

        return newCardLayout;
    },
    allNightActions: ["alpha_wolf_target", "lone_wolf_target"],
    areNightActionsComplete: function (state) {
        const nightActions: NightAction[] = getGameHasLoneWolfAction(state)
            ? ["alpha_wolf_target", "lone_wolf_target"]
            : ["alpha_wolf_target"];

        return !!(
            nightActions &&
            nightActions.every((action) => state.game.night_actions[action] !== undefined)
        );
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
