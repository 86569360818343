import { WerewolfGameState } from "@dailygameslink/werewolf";

export function getGameEndTimestamp(state: WerewolfGameState) {
    const start = state.game.start_timestamp;
    if (!start) {
        return null;
    }
    const rolesInGame = Object.values(state.setup.roles).reduce((a, b) => a + b);
    const msToWait = (state.setup.timer || rolesInGame) * 1000 * 60;
    return start + msToWait;
}
