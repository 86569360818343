import { WerewolfGameState, Role } from "@dailygameslink/werewolf";

export function getPlayerWithStartingRole(
    state: WerewolfGameState,
    roleToFind: Role
): string | null {
    return (
        (Object.entries(state.game.starting_roles || {}).find(([_player_id, role]) => {
            return role === roleToFind;
        }) || [])[0] || null
    );
}
