import * as React from "react";
import { Link } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

import { useRoom } from "../utils/useRoom";

import { Account } from "./Account";
import { PlayerNameList } from "./PlayerNameList";

export const Nav = styled.nav`
    background: #032941;
    padding: var(--grid) calc(var(--grid) * 2);
    font-size: 1rem;
    box-shadow: 0px 3px 5px 0px #0000006e;
    justify-content: space-between;
    display: flex;
    margin-bottom: calc(var(--grid) * 2);
    min-height: calc(var(--grid) * 4);
    font-family: "Press Start 2P";
    text-transform: uppercase;
    font-weight: 400;
`;

const Logo = styled.div`
    background-image: url(/favicon.gif);
    background-size: 1.25rem 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
`;

const NavLink = styled(Link)`
    padding: 4px;
`;

const Right = styled.div`
    display: flex;
`;

export function Header({ room_id }: { room_id?: string }): JSX.Element | null {
    const room = useRoom(room_id);
    return (
        <Nav>
            <div>
                <Logo /> <NavLink to="/">Daily games link</NavLink>
                {room_id && (
                    <Modal
                        closeIcon
                        trigger={<NavLink to="#">{` / ${room_id}`}</NavLink>}
                        header="Room info"
                        content={
                            <>
                                <PlayerNameList player_ids={room.player_ids} />
                            </>
                        }
                    ></Modal>
                )}
            </div>
            <div></div>
            <Right>
                <Account />
            </Right>
        </Nav>
    );
}
