import { CardLayout } from "@dailygameslink/werewolf";
import { gameHasRole } from "../../utils/roles";

export function makeCardSwap(cardLayout: CardLayout) {
    /**
     * Mutates
     */
    return function swap(card1: keyof CardLayout, card2: keyof CardLayout) {
        const tmp = cardLayout[card1];
        cardLayout[card1] = cardLayout[card2];
        cardLayout[card2] = tmp;

        return cardLayout;
    };
}
