import {
    Action,
    Card,
    PartialPileMetadata,
    PartialPlayerInfo,
    SandboxGameState,
} from "@dailygameslink/sandbox";
import { seededShuffle } from "./seededShuffle";
import { PlayerContext, parsePileExpression } from "../parsing/parser";
import { getPileContext } from "../parsing/getPileContext";

export class PlayMat {
    public width = 0;
    public height = 0;
    private piles = new Map<string, Card[]>();
    private hands = new Map<string, Card[]>();
    private pileMetadata = new Map<string, PartialPileMetadata>();
    public playerInfo = new Map<string, PartialPlayerInfo>();

    constructor() {}

    public findCard(cardId: string) {
        let cardToReturn: Card | undefined;
        this.piles.forEach((pile) => {
            const foundCard = pile.find((card) => card.id === cardId);
            if (foundCard) {
                cardToReturn = foundCard;
            }
        });
        this.hands.forEach((hand) => {
            const foundCard = hand.find((card) => card.id === cardId);
            if (foundCard) {
                cardToReturn = foundCard;
            }
        });

        return cardToReturn;
    }

    public removeCard(cardId: string) {
        for (const [key, pile] of this.piles) {
            this.piles.set(
                key,
                pile.filter((card) => card.id !== cardId)
            );
        }
        for (const [key, hand] of this.hands) {
            this.hands.set(
                key,
                hand.filter((card) => card.id !== cardId)
            );
        }
    }

    public addCard(card: Card) {
        if (card.position.type === "pile") {
            const [i, j] = card.position.index || [0, 0];
            const pile = this.getPile(i, j);
            pile.push(card);
            this.height = Math.max(this.height, i + 1);
            this.width = Math.max(this.width, j + 1);
            return;
        }
        const hand = this.getHand(card.position.player_id);
        if (hand) {
            hand.push(card);
        }
    }

    public getPile(i: number, j: number) {
        let pile = this.piles.get(`${i},${j}`);
        if (!pile) {
            pile = [];
            this.piles.set(`${i},${j}`, pile);
        }
        return pile;
    }

    public getHand(player_id: string) {
        let hand = this.hands.get(player_id);
        if (!hand) {
            hand = [];
            this.hands.set(player_id, hand);
        }
        return hand;
    }

    public getPileMetadata(i: number, j: number) {
        return (
            this.pileMetadata.get(`${i},${j}`) || {
                position: [i, j],
            }
        );
    }

    public getIsPileEditable(i: number, j: number, playerContext: PlayerContext) {
        const pileMetadata = this.getPileMetadata(i, j);
        const pileEditableExpression = pileMetadata.editable;
        return pileEditableExpression
            ? parsePileExpression({
                  expression: pileEditableExpression,
                  context: {
                      player: playerContext,
                      pile: getPileContext(pileMetadata),
                  },
              })
            : true;
    }

    public setPileMetadata(params: PartialPileMetadata) {
        const [i, j] = params.position;
        this.pileMetadata.set(`${i},${j}`, {
            ...this.getPileMetadata(i, j),
            ...params,
            attributes: { ...this.getPileMetadata(i, j).attributes, ...(params.attributes || {}) },
        });
    }

    public getPlayerInfo(player_id: string) {
        return (
            this.playerInfo.get(player_id) || {
                player_id,
            }
        );
    }

    public setPlayerInfo(params: PartialPlayerInfo) {
        const { player_id } = params;
        this.playerInfo.set(player_id, { ...this.getPlayerInfo(player_id), ...params });
    }

    public getAllPlayerInfos() {
        return [...this.playerInfo.keys()].map((player_id) => this.getPlayerInfo(player_id));
    }

    public serialize() {
        return JSON.stringify({
            width: this.width,
            height: this.height,
            piles: Array.from(this.piles.entries()),
            pileMetadata: Array.from(this.pileMetadata.entries()),
            playerInfo: Array.from(this.playerInfo.entries()),
        });
    }

    public deserialize(serialized: string) {
        const obj = JSON.parse(serialized);
        this.width = obj.width;
        this.height = obj.height;
        this.piles = new Map(obj.piles);
        this.pileMetadata = new Map(obj.pileMetadata);
        this.playerInfo = new Map(obj.playerInfo);
    }
}

function deepEqual(x: any, y: any) {
    if (x === y) {
        return true;
    } else if (typeof x == "object" && x != null && typeof y == "object" && y != null) {
        if (Object.keys(x).length != Object.keys(y).length) return false;

        for (var prop in x) {
            if (y.hasOwnProperty(prop)) {
                if (!deepEqual(x[prop], y[prop])) return false;
            } else return false;
        }

        return true;
    } else return false;
}

export function constructPlayMat(state: SandboxGameState) {
    const playMat = new PlayMat();
    let actionsToExecute = state.game.actions;
    let skipActionsUpTo: Action | undefined;
    if (state.game.collapsedSnapshot) {
        playMat.deserialize(state.game.collapsedSnapshot.serializedPlaymatState);
        const upToAction = state.game.collapsedSnapshot.upToAction;
        const containsUpToAction = actionsToExecute.find((action) =>
            deepEqual(action, skipActionsUpTo)
        );
        if (containsUpToAction) {
            skipActionsUpTo = upToAction;
        } else {
            console.error(
                "In sandbox state: Actions did not contain the collapsedSnapshot.upToAction"
            );
        }
    }

    if (skipActionsUpTo) {
        while (actionsToExecute.length > 0) {
            actionsToExecute.shift();
            if (deepEqual(actionsToExecute[0], skipActionsUpTo)) {
                break;
            }
        }
    }
    for (let action of actionsToExecute) {
        if (action.type === "set-card") {
            const card = playMat.findCard(action.params.id);
            if (!card) {
                playMat.addCard({
                    ...action.params,
                    id: action.params.id,
                    position: action.params.position || { type: "pile", index: [0, 0] },
                });
                continue;
            }

            const newCard: Card = {
                ...card,
                ...action.params,
            };

            if (JSON.stringify(newCard.position) !== JSON.stringify(card.position)) {
                playMat.removeCard(card.id);
                playMat.addCard(newCard);
                continue;
            }
            const newCardProperties: Partial<Card> = { ...newCard };
            delete newCardProperties.id;
            delete newCardProperties.position;
            Object.assign(card, newCardProperties);
        } else if (action.type === "shuffle-pile") {
            const position: [number, number] = JSON.parse(action.params.position);
            const pile = playMat.getPile(...position);
            seededShuffle(pile, action.params.seed);
        } else if (action.type === "edit-pile") {
            playMat.setPileMetadata(action.params);
        } else if (action.type === "set-player-info") {
            playMat.setPlayerInfo(action.params);
        }
    }

    return playMat;
}
