import React from "react";
import { BOARD_SIZE } from "./constants";

export function Board() {
    return (
        <mesh receiveShadow position={[0, 0, 0]}>
            <planeBufferGeometry args={[BOARD_SIZE, BOARD_SIZE]} />
            <meshPhysicalMaterial color={"white"} />
        </mesh>
    );
}
