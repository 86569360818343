import { RoleImpl } from "@dailygameslink/werewolf";

export const Mason: RoleImpl = {
    role: "mason",
    order: 400,
    name: "Mason",
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    allNightActions: [],
    isWolf: () => false,
    isMinion: () => false,
    areNightActionsComplete: () => true,
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
