import { WerewolfGameState } from "@dailygameslink/werewolf";
import { getRole } from "../roles";

export function getStartingWolfPlayerIds(state: WerewolfGameState): string[] | null {
    if (!state.game.starting_roles) {
        return null;
    }
    const startingWolves = Object.entries(state.game.starting_roles)
        .filter(([_player_id, role]) => {
            return getRole(role).isWolf(state);
        })
        .map(([player_id, _role]) => player_id);

    return startingWolves;
}
