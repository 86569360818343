import styled from "styled-components";

export const Button = styled.button<{
    selected?: boolean;
    wide?: boolean;
}>`
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15;
    margin: 0;
    border: ${(props) => (props.selected ? "2px solid #a1de52" : "none")};
    padding: var(--grid);
    border-radius: var(--border-radius);
    min-width: ${(props) => (props.wide ? "var(--center-width)" : "100px")};
    background: ${(props) => (props.selected ? "#01253c" : "auto")};
    color: ${(props) => (props.selected ? "currentcolor" : "auto")};
    box-sizing: border-box;

    &[disabled] {
        color: currentcolor;
        background: ${(props) => (props.selected ? "#01253c" : "auto")};
    }
`;
