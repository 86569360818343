import * as React from "react";
import styled from "styled-components";

export const Title = styled.div`
    color: white;
    display: flex;
    justify-content: center;
`;

export function RoomTitle({ room_id }: { room_id: string }): JSX.Element | null {
    return (
        <Title>
            <h1>Room {room_id}</h1>
        </Title>
    );
}
