import { Role, WerewolfGameState } from "@dailygameslink/werewolf";

import { getRole } from "../roles";
import { isActionComplete } from "../utils/isActionComplete";
import { useState } from "react";

export function useActionReady(role: Role, state: WerewolfGameState): boolean {
    const fakeDelay = Math.round(5000 + Math.random() * 2000);
    const [fakeDelayComplete, setFakeDelayComplete] = useState(false);

    setTimeout(() => {
        setFakeDelayComplete(true);
    }, fakeDelay);

    const rolesToWaitFor: Role[] = getRole(role).actionDependencies || [];

    if (rolesToWaitFor.length === 0) {
        return true;
    }

    // If there was any role to wait for, wait for at least the fake delay to complete to not hint whether that role is in the game.
    return fakeDelayComplete && rolesToWaitFor.every((role) => isActionComplete(role, state));
}
