import { CanvasTexture, TextureLoader } from "three";
import { useMemo } from "react";

import ambassador from "./assets/ambassador.png";
import assassin from "./assets/assassin.png";
import captain from "./assets/captain.png";
import contessa from "./assets/contessa.png";
import duke from "./assets/duke.png";

const TEXTURES = {
    ambassador,
    assassin,
    captain,
    contessa,
    duke,
};

export function useCardFrontTexture(
    color: string,
    width: number,
    height: number,
    { text, texture }: { text?: string; texture?: string }
) {
    return useMemo(() => {
        if (texture) {
            const validTexture = (TEXTURES as any)[texture] as unknown;
            if (typeof validTexture === "string") {
                return new TextureLoader().load(validTexture);
            }
        }
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = (width / height) * 512;
        canvas.height = (height / width) * 512;
        if (context && text) {
            context.fillStyle = color;
            context.fillRect(0, 0, canvas.width, canvas.height);

            context.font = "100px sans-serif";
            context.textAlign = "center";
            context.fillStyle = "black";
            text && context.fillText(text, canvas.width / 2, canvas.height / 2, canvas.width * 0.9);
        }
        const canvasTexture = new CanvasTexture(canvas);
        canvasTexture.premultiplyAlpha = false;
        canvasTexture.needsUpdate = true;
        return canvasTexture;
    }, [color, width, height, text, texture]);
}
