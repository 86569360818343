import React, { useEffect, useState } from "react";
import { SpellEffect, getSpellEffect } from "../utils/getSpellEffects";

import { WerewolfGameState } from "@dailygameslink/werewolf";
import { usePreferences } from "../../../utils/usePreferences";

export function SpellEffectDisplay({ state, player_id }: { state: WerewolfGameState; player_id: string }): JSX.Element | null {
    const newSpellEffect = getSpellEffect(state, player_id);
    const preferences = usePreferences(player_id);
    useEffect(() => {
        if (newSpellEffect === 'silence') {
            const spellSoundEl = document.getElementById('spell_sound');
            !preferences?.soundEffectsDisabled && spellSoundEl && (spellSoundEl as any).play();
            document.body.setAttribute('spell', 'silence');
        } else {
            document.body.removeAttribute('spell');
        }

        return () => {
            document.body.removeAttribute('spell');
        }
    }, [newSpellEffect])
    
    return (
        <>
            {newSpellEffect === 'silence' && <h2>🔮 You have been silenced! No talking.</h2>}
        </>
    );
}
