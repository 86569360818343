import * as React from "react";

import { usePerformAction } from "../../../utils/usePerformAction";
import { SelectCard } from "../components/SelectCard";

export function ApprenticeSeerAction({
    room_id,
    hasBottomCard,
    actionName,
}: {
    room_id: string;
    hasBottomCard: boolean;
    actionName?: "doppelganger_second_target";
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <>
            <p>Choose a card from the middle to check</p>
            <SelectCard
                hasBottomCard={hasBottomCard}
                canSelectTwo={false}
                onConfirm={(card_positions) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: actionName || "apprentice_seer_target",
                            game_type: "werewolf",
                            target: card_positions[0],
                        },
                    })
                }
            />
        </>
    );
}
