import * as React from "react";
import styled from "styled-components";

import { Button } from "../../../components/Button";
import { usePerformAction } from "../../../utils/usePerformAction";

const ButtonWrapper = styled.div`
    margin-top: calc(var(--grid) * 2);
`;

export function SkipToVoteAction({
    player_id,
    player_ids,
    room_id,
    voteSkips,
}: {
    player_id: string;
    player_ids: string[];
    room_id: string;
    voteSkips: { [player_id: string]: boolean };
}): JSX.Element | null {
    const isSkipping = voteSkips[player_id];
    const performAction = usePerformAction();
    const waitingForCount = player_ids.length - Object.values(voteSkips).filter(Boolean).length;
    return (
        <ButtonWrapper>
            <Button
                selected={isSkipping}
                onClick={() => {
                    performAction({
                        room_id,
                        action: {
                            actionName: "skip_to_vote",
                            game_type: "werewolf",
                            skip_to_vote: !isSkipping,
                            player_id,
                        },
                    });
                }}
            >
                Skip to vote {isSkipping ? ` (Waiting for ${waitingForCount} players)` : ""}
            </Button>
        </ButtonWrapper>
    );
}
