import { Role, RoleImpl } from "@dailygameslink/werewolf";

import { AlphaWolf } from "./alphaWolf";
import { ApprenticeSeer } from "./apprenticeSeer";
import { Bodyguard } from "./bodyguard";
import { Detective } from "./detective";
import { Doppelganger } from "./doppelganger";
import { Drunk } from "./drunk";
import { Eavesdropper } from "./eavesdropper";
import { HexWolf } from "./hexWolf";
import { Hunter } from "./hunter";
import { Insomniac } from "./insomniac";
import { Mason } from "./mason";
import { Minion } from "./minion";
import { MysticWolf } from "./mysticWolf";
import { ParanormalInvestigator } from "./paranormalInvestigator";
import { Robber } from "./robber";
import { Seer } from "./seer";
import { Tanner } from "./tanner";
import { Troublemaker } from "./troublemaker";
import { Villager } from "./villager";
import { Werewolf } from "./werewolf";
import { Witch } from "./witch";
import { Telepath } from "./telepath";

export const roles: { [r in Role]: RoleImpl } = {
    alpha_wolf: AlphaWolf,
    detective: Detective,
    hex_wolf: HexWolf,
    apprentice_seer: ApprenticeSeer,
    bodyguard: Bodyguard,
    hunter: Hunter,
    drunk: Drunk,
    doppelganger: Doppelganger,
    eavesdropper: Eavesdropper,
    insomniac: Insomniac,
    mason: Mason,
    minion: Minion,
    mystic_wolf: MysticWolf,
    paranormal_investigator: ParanormalInvestigator,
    robber: Robber,
    seer: Seer,
    tanner: Tanner,
    troublemaker: Troublemaker,
    villager: Villager,
    werewolf: Werewolf,
    witch: Witch,
    telepath: Telepath,
};

export function getRole(role: Role) {
    return roles[role];
}
