import React, { useEffect, useState } from "react";
import { Role, Team } from "@dailygameslink/werewolf";

import { Card as GenericCard } from "../../../../components/Card";
import { RoleDescription } from "../RoleDescription";
import { RoleName } from "../RoleName";
import alphaWolfImage from "./images/onuw_-_w_-_alpha_wolf_-_small.png";
import bodyguardImage from "./images/onuw_-_v_-_bodyguard_-_small.png";
import cardBackImage from "./images/card-back.jpg";
import doppelgangerImage from "./images/onuw_-_n_-_doppelganger_-_small.png";
import doppelgangerVillagerImage from "./images/onuw_-_v_-_doppelganger_-_small.png";
import doppelgangerWolfImage from "./images/onuw_-_w_-_doppelganger_-_small.png";
import drunkImage from "./images/onuw_-_v_-_drunk_-_small.png";
import eavesdropperImage from "./images/onuw_-_v_-_eavesdropper_-_large.png"; // we don't have a small eavesdropper yet
import hunterImage from "./images/onuw_-_v_-_hunter_-_small.png";
import insomniacImage from "./images/onuw_-_v_-_insomniac_-_small.png";
import masonImage from "./images/onuw_-_v_-_mason_one_-_small.png";
import minionImage from "./images/onuw_-_w_-_minion_-_small.png";
import mysticWolfImage from "./images/onuw_-_w_-_mystic_wolf_-_small.png";
import paranormalInvestigatorImage from "./images/onuw_-_n_-_paranormal_investigator_-_small.png";
import paranormalInvestigatorVillagerImage from "./images/onuw_-_v_-_paranormal_investigator_-_small.png";
import paranormalInvestigatorWolfImage from "./images/onuw_-_w_-_paranormal_investigator_-_small.png";
import robberImage from "./images/onuw_-_v_-_robber_-_small.png";
import seerImage from "./images/onuw_-_v_-_seer_-_small.png";
import styled from "styled-components";
import troublemakerImage from "./images/onuw_-_v_-_troublemaker_-_small.png";
import villagerImage from "./images/onuw_-_v_-_villager_cow_-_small.png";
import werewolfImage from "./images/onuw_-_w_-_werewolf_-_small.png";
import witchImage from "./images/onuw_-_v_-_witch_-_small.png";

const roleImages: { [role in Role]: string | null } = {
    alpha_wolf: alphaWolfImage,
    apprentice_seer: null,
    hex_wolf: null,
    detective: null,
    bodyguard: bodyguardImage,
    doppelganger: doppelgangerImage,
    drunk: drunkImage,
    eavesdropper: eavesdropperImage,
    hunter: hunterImage,
    insomniac: insomniacImage,
    minion: minionImage,
    mystic_wolf: mysticWolfImage,
    paranormal_investigator: paranormalInvestigatorImage,
    robber: robberImage,
    seer: seerImage,
    tanner: null,
    telepath: null,
    troublemaker: troublemakerImage,
    villager: villagerImage,
    werewolf: werewolfImage,
    witch: witchImage,
    mason: masonImage,
};

const roleTeamImages: {
    wolf: {
        [role in "paranormal_investigator" | "doppelganger"]: string;
    };
    villager: {
        [role in "paranormal_investigator" | "doppelganger"]: string;
    };
} = {
    wolf: {
        paranormal_investigator: paranormalInvestigatorWolfImage,
        doppelganger: doppelgangerWolfImage,
    },
    villager: {
        paranormal_investigator: paranormalInvestigatorVillagerImage,
        doppelganger: doppelgangerVillagerImage,
    },
};

const width = 120;
const largeWidth = 200;

const CardContainer = styled.div<{ large: boolean }>`
    width: ${(props) => (props.large ? largeWidth : width)}px;
    height: ${(props) => (props.large ? largeWidth : width) * 1.38}px;
    position: relative;
    margin: auto;
    border: 2px solid white;
    border-radius: 5px;
`;

const CardWrapper = styled.div<{ faceDown: boolean }>`
    text-align: center;
    align-items: center;
    flex-direction: column;
    transition: transform 1s;
    transform-style: preserve-3d;
    ${(props) => (props.faceDown ? "transform: rotateY(180deg)" : "")};
`;

const CardFace = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
    backface-visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardFront = styled(CardFace)<{ role: Role; team?: Team | null }>`
    background: #032941;
    ${({ team, role }) => {
        if (role === "paranormal_investigator" || role === "doppelganger") {
            if (team === "wolf" || team === "villager") {
                return `background-image: url(${roleTeamImages[team][role]})`;
            }
        }
        if (roleImages[role]) {
            return `background-image: url(${roleImages[role]})`;
        }

        return "";
    }};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const CardBack = styled(CardFace)`
    background-image: url(${cardBackImage});
    transform: rotateY(180deg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const NameWrapper = styled.div`
    margin-top: var(--grid);
    text-align: center;
`;

function getCardFrontImage(role: Role, team?: Team | null) {
    if (role === "paranormal_investigator" || role === "doppelganger") {
        if (team === "wolf" || team === "villager") {
            return roleTeamImages[team][role];
        }
    }
    if (roleImages[role]) {
        return roleImages[role];
    }
}

export function Card({
    large = false,
    reveal = 0,
    faceDown = false,
    role,
    team,
    label = null,
}: {
    role: Role;
    large?: boolean;
    reveal?: number;
    team?: Team | null;
    faceDown?: boolean;
    label?: JSX.Element | string | null;
}): JSX.Element | null {
    const cardWidth = large ? largeWidth : width;
    const cardHeight = width * 1.38;
    const cardFrontContent = !roleImages[role] && <RoleName role={role} />;

    return (
        <GenericCard
            cardBackImage={cardBackImage}
            cardFrontImage={getCardFrontImage(role, team) || undefined}
            reveal={reveal}
            faceDown={faceDown}
            label={label}
            width={cardWidth}
            height={cardHeight}
            tooltipWrapper={({ children }: { children: JSX.Element }) => (
                <RoleDescription role={role}>{children}</RoleDescription>
            )}
            cardFrontContent={cardFrontContent || undefined}
        />
    );
}
