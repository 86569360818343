import { EavesdropperAction } from "../actions/EavesdropperAction";
import { RoleImpl } from "@dailygameslink/werewolf";

export const Eavesdropper: RoleImpl = {
    role: "eavesdropper",
    order: 511,
    name: "Eavesdropper",
    actionComponent: EavesdropperAction,
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    hasTarget: true,
    isWolf: () => false,
    isMinion: () => false,
    allNightActions: ["eavesdropper_target"],
    areNightActionsComplete: function (state) {
        return state.game.night_actions["eavesdropper_target"] !== undefined;
    },
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
