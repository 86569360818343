import { WerewolfGameState, NightAction, RobberTarget } from "@dailygameslink/werewolf";
import * as React from "react";

import { PlayerName } from "../../../../components/PlayerName";
import { getCurrentCardLayout } from "../../utils/getCurrentCardLayout";
import { RoleName } from "../RoleName";

export function RobberSecretInfo({
    state,
    targetOverride,
    actionOverride,
}: {
    state: WerewolfGameState;
    targetOverride?: RobberTarget;
    actionOverride?: NightAction;
}): JSX.Element | null {
    const cardLayout = getCurrentCardLayout(state, actionOverride || "robber_target");
    const target =
        targetOverride === undefined ? state.game.night_actions.robber_target : targetOverride;

    if (!cardLayout) {
        return (
            <p>
                <span role="img" aria-label="Warning">
                    ⚠️
                </span>{" "}
                Error: no cardLayout
            </p>
        );
    }
    if (!target) {
        return <p>You chose not to perform your action</p>;
    }

    return (
        <>
            <p>
                You swapped your card with {<PlayerName player_id={target} />}, who was a{" "}
                <RoleName role={cardLayout[target]} />.
            </p>
            <p>That became your card.</p>
        </>
    );
}
