import * as React from "react";
import styled from "styled-components";

import { useRestartGame } from "../utils/useRestartGame";

import { Button } from "./Button";

const HostControls = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export function HostGameControls({
    room_id,
}: {
    room_id: string;
    player_id: string;
}): JSX.Element | null {
    const restartGame = useRestartGame({ room_id });
    return (
        <HostControls>
            <Button
                onClick={() => {
                    restartGame();
                }}
            >
                Restart game
            </Button>
        </HostControls>
    );
}
