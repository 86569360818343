import { useFunctions } from "reactfire";

export type UseRestartGameOptions = {
    room_id: string;
};

export type RestartGame = () => Promise<void>;

export function useRestartGame({ room_id }: UseRestartGameOptions): RestartGame {
    const restartGame = useFunctions().httpsCallable("restartgame");

    return async () => {
        await restartGame({ room_id });
    };
}
