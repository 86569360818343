import { Action } from "@dailygameslink/werewolf";
import { useFunctions } from "reactfire";

import { usePlayerId } from "./usePlayerId";

export type PerformActionOptions = { room_id: string; action: Action };

export type PerformAction = (options: PerformActionOptions) => Promise<void>;

export function usePerformAction(): PerformAction {
    const performAction = useFunctions().httpsCallable("performaction");
    const player_id = usePlayerId();
    return async ({ room_id, action }) => {
        await performAction({
            room_id,
            player_id,
            action,
        });
    };
}
