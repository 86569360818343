import { Player } from "@dailygameslink/shared-types";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export function usePlayerName(player_id: string | null | undefined): string | null {
    const playerRef = useFirestore()
        .collection("players")
        .where("player_id", "==", player_id || null)
        .limit(1);
    const player = (useFirestoreCollectionData(playerRef) as Player[])[0];

    if (!player) {
        return null;
    }
    return player.player_name;
}
