import { AssignedRoles } from "@dailygameslink/werewolf";

export function getNewAssignedRoles(
    assignedRoles: AssignedRoles,
    {
        player_id,
        destinationRole,
        destinationIndex,
    }: {
        player_id: string;
        destinationRole: string;
        destinationIndex: number;
    }
): AssignedRoles {
    const newAssignedRoles = { ...assignedRoles };
    Object.keys(assignedRoles).forEach((assignedRole) => {
        newAssignedRoles[assignedRole] = newAssignedRoles[assignedRole].filter(
            (p) => p !== player_id
        );
    });

    const destinationList = [
        // We use newAssignedRoles in case we're just rearranging the current list
        ...(newAssignedRoles[destinationRole] || []),
    ];

    const newDestination = [
        ...destinationList.slice(0, destinationIndex),
        player_id,
        ...destinationList.slice(destinationIndex),
    ];

    newAssignedRoles[destinationRole] = newDestination;

    return newAssignedRoles;
}
