import * as React from "react";

import { Role, WerewolfGameState } from "@dailygameslink/werewolf";
import { roles } from "../roles";
import { PlayerChat } from "./PlayerChat";
import styled from "styled-components";

const ChatWrapper = styled.div`
    text-align: center;
    margin-top: calc(var(--grid) * 4);
    background: #01253c;
    border: 1px solid #577f9a;
    padding: var(--grid);
    border-radius: var(--border-radius);
    min-width: var(--center-width);
`;

const PlayerChatWrapper = styled.div`
    margin-bottom: calc(var(--grid) * 2);
    &:last-child() {
        margin-bottom: 0;
    }
`;

export function Chats({
    state,
    player_id,
    game_id,
}: {
    state: WerewolfGameState;
    player_id: string;
    game_id: string;
}): JSX.Element | null {
    const allChats: string[][] = [];
    Object.values(roles).forEach((role) => {
        allChats.push(...role.opensChats(state));
    });

    const chatsParticipatingIn = allChats.filter((participants) =>
        participants.includes(player_id)
    );

    if (chatsParticipatingIn.length === 0) {
        return null;
    }

    return (
        <ChatWrapper>
            {chatsParticipatingIn.map((participants) => (
                <PlayerChatWrapper>
                    <PlayerChat
                        player_id={player_id}
                        game_id={game_id}
                        key={participants.sort().join(":")}
                        state={state}
                        between={participants}
                    />
                </PlayerChatWrapper>
            ))}
        </ChatWrapper>
    );
}
