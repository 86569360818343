import { RoleImpl } from "@dailygameslink/werewolf";

export const Tanner: RoleImpl = {
    role: "tanner",
    name: "Tanner",
    startingTeam: "tanner",
    getCurrentTeam: () => "tanner",
    isWolf: () => false,
    isMinion: () => false,
    areNightActionsComplete: () => true,
    allNightActions: [],
    voteEffect: () => "default",
    hasDayActions: () => false,
    opensChats: () => [],
};
