import * as React from "react";

import { WerewolfGameState, WinCondition } from "@dailygameslink/werewolf";

import { Card } from "./cards/Card";
import { ConfettiText } from "./ConfettiText";
import { PlayerColor } from "../../../components/DraggablePlayerName";
import { PlayerNameList } from "../../../components/PlayerNameList";
import { TableLayout } from "./cards/TableLayout";
import { getKilledPlayers } from "../utils/getKilledPlayers";
import { getPlayerTeam } from "../utils/getPlayerTeam";
import { getProtectedPlayers } from "../utils/getProtectedPlayers";
import { getRoleCurrentlyWithPlayer } from "../utils/getRoleCurrentlyWithPlayer";
import { getRoleTeam } from "../utils/getRoleTeam";
import { getWinningPlayers } from "../utils/getWinningTeam";
import { isPlayerSpectator } from "../utils/isPlayerSpectator";
import styled from "styled-components";
import { tallyVotes } from "../utils/tallyVotes";

const CenteredResults = styled.div`
    text-align: center;
`;

export function Results({
    state,
    player_id,
    player_ids,
    room_id,
}: {
    state: WerewolfGameState;
    player_id: string;
    player_ids: string[];
    room_id: string;
}): JSX.Element | null {
    const gameResults = getWinningPlayers(state, player_ids);
    if (!gameResults) {
        return null;
    }
    const [winningPlayers, winCondition] = gameResults;
    const didWin = winningPlayers?.includes(player_id);
    const currentRole = getRoleCurrentlyWithPlayer(state, player_id);

    const winConditionMessage: { [winCondition in WinCondition]: string } = {
        tanner_victory: "A tanner was killed and won",
        wolf_victory: "No wolves were killed",
        villager_victory: "The villagers killed a wolf",
        villager_victory_no_kill:
            "There were no wolves, and the villagers successfully killed nobody",
        no_victory: "Nobody won – there were no wolves, but the villagers killed a villager.",
        lone_minion_victory: "The lone minion won because the villagers killed a villager",
    };

    const killedPlayers = getKilledPlayers(state);
    const protectedPlayers = getProtectedPlayers(state);

    let victoryText = didWin ? (
        <ConfettiText>
            <h1>You won!</h1>
        </ConfettiText>
    ) : (
        <h1>You lost</h1>
    );

    if (isPlayerSpectator(state, player_id)) {
        const win = getWinningPlayers(state, player_ids);
        if (win) {
            const [, winCondition] = win;
            if (winCondition === "wolf_victory") {
                victoryText = <h1>The wolf team won</h1>;
            } else if (winCondition === "no_victory") {
                victoryText = <h1>Nobody won</h1>;
            } else if (winCondition === "tanner_victory") {
                victoryText = <h1>Tanner(s) won</h1>;
            } else if (
                winCondition === "villager_victory" ||
                winCondition === "villager_victory_no_kill"
            ) {
                victoryText = <h1>Villagers won</h1>;
            }
        }
    }

    return (
        <>
            {currentRole && (
                <Card
                    large
                    reveal={500}
                    role={currentRole}
                    team={getRoleTeam(state, currentRole)}
                />
            )}
            {victoryText}
            <CenteredResults>
                <p>{winConditionMessage[winCondition]}</p>
                <div>
                    <PlayerNameList
                        title="The votes were"
                        player_ids={Object.entries(tallyVotes(state.game.votes))
                            .sort(([_a, votesA], [_v, votesB]) => votesB - votesA)
                            .map(([player_id, _votes]) => player_id)}
                        post={(player_id) => (
                            <>
                                ({tallyVotes(state.game.votes)[player_id]}){" "}
                                <>{killedPlayers.includes(player_id) && "☠️"}</>
                                <>{protectedPlayers.includes(player_id) && "🛡"}</>
                            </>
                        )}
                        color={(player_id) =>
                            ({
                                villager: "blue",
                                wolf: "red",
                                tanner: "yellow",
                            }[getPlayerTeam(state, player_id) || "villager"] as PlayerColor)
                        }
                    />
                </div>
            </CenteredResults>
            <TableLayout room_id={room_id} state={state} />
        </>
    );
}
