import { RoleImpl } from "@dailygameslink/werewolf";

export const Bodyguard: RoleImpl = {
    role: "bodyguard",
    name: "Bodyguard",
    startingTeam: "villager",
    getCurrentTeam: () => "villager",
    hasTarget: false,
    isMinion: () => false,
    voteEffect: () => "protects",
    isWolf: () => false,
    allNightActions: [],
    areNightActionsComplete: () => true,
    hasDayActions: () => false,
    opensChats: () => [],
};
