import * as React from "react";

import { SelectPlayer } from "../../../components/SelectPlayer";
import { usePerformAction } from "../../../utils/usePerformAction";

export function TroublemakerAction({
    room_id,
    player_ids,
    player_id,
    actionName,
}: {
    player_ids: string[];
    player_id: string;
    room_id: string;
    actionName?: "doppelganger_second_target";
}): JSX.Element | null {
    const performAction = usePerformAction();
    return (
        <>
            <p>Choose two players and swap their cards</p>
            <SelectPlayer
                allowNobody
                player_ids={player_ids}
                disabledPlayerIds={[player_id]}
                playersToSelect={2}
                onConfirm={(player_ids) =>
                    performAction({
                        room_id,
                        action: {
                            actionName: actionName || "troublemaker_target",
                            game_type: "werewolf",
                            target: player_ids.length === 0 ? null : [player_ids[0], player_ids[1]],
                        },
                    })
                }
            ></SelectPlayer>
        </>
    );
}
